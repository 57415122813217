import { NetworkStatus, useApolloClient } from '@apollo/client';
import {
  Box,
  Paper,
  TableCell,
  TableRow,
  SelectChangeEvent,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import {
  Button,
  useDialog,
  formatUTCDate,
  HeadCell,
  StatelessSearchableTableHeader,
  StatelessTable,
  TableSortOrder,
  TableFilters,
  SelectOption,
  Select,
} from '@fdha/web-ui-library';
import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import {
  FilterOp,
  PatientUser,
  SortByOrder,
  SurveysExportType,
  SurveyType,
} from '@fdha/graphql-api-admin';
import { useCachedFilters, useFeatureFlag } from '@fdha/common-hooks';

import AssignCoachDialog from '../../../../components/AssignCoachDialog/AssignCoachDialog';
import Avatar from '../../../../components/Avatar/Avatar';
import ProgressStatusChips from '../../../../components/ProgressStatusChips/ProgressStatusChips';
import {
  useDebouncedValue,
  useGetPatientsList,
  useTable,
  useRequestDownload,
  useGetUserType,
} from '../../../../hooks';
import ExportScopeDialog from '../../../../components/ExportScopeDialog/ExportScopeDialog';

import AddPatientGroup from './AddPatientGroup';

const getHeadCells = (showSiteFeatures: boolean): HeadCell<PatientUser>[] => {
  return [
    { id: 'name', label: 'Patient name', sortable: true, searchable: true },
    {
      id: 'subject_id',
      label: 'Subject ID',
      sortable: true,
      searchable: true,
    },
    {
      id: 'study_start',
      label: 'Study Start',
      sortable: true,
      searchable: false,
    },
    {
      id: showSiteFeatures ? 'site:name' : 'associated_site',
      label: 'Associated Site',
      sortable: true,
      searchable: true,
    },
    {
      id: 'progress:survey:done',
      label: 'Survey status',
      sortable: false,
      searchable: false,
    },
    {
      id: 'groups',
      label: 'Group',
      sortable: true,
      searchable: false,
    },
    {
      id: 'coach:name',
      label: 'Coach',
      sortable: true,
      searchable: true,
    },
  ];
};

interface SelectFilterOptions {
  showOnlyActivePatients: boolean;
  showOnlyMyPatients: boolean;
  includeTestPatients: boolean;
}

enum FilterType {
  showOnlyActivePatients = 'showOnlyActivePatients',
  showOnlyMyPatients = 'showOnlyMyPatients',
  includeTestPatients = 'includeTestPatients',
}

const CTPatientsTab = () => {
  const client = useApolloClient();
  const { isAdmin, isCsr, isLoading: loadingUserType } = useGetUserType();
  const requestDownload = useRequestDownload();

  const { isFeatureEnabled, isLoading: loadingFeatureFlag } = useFeatureFlag();
  const showSiteFeatures = isFeatureEnabled('show_site_features');
  const headCells = getHeadCells(showSiteFeatures);

  const ctFilterOptions: SelectOption[] = [
    {
      label: 'Show only active patients',
      value: FilterType.showOnlyActivePatients,
    },
    ...(isAdmin
      ? [
          {
            label: 'Show only my patients',
            value: FilterType.showOnlyMyPatients,
          },
        ]
      : []),
    { label: 'Include test patients', value: FilterType.includeTestPatients },
  ];

  useEffect(() => {
    return () => {
      // Clears patients list from cache to not mix up CT with DH data
      client.cache.evict({
        fieldName: 'allPatients',
      });
    };
  }, [client.cache]);

  const { filters: filterOptions, setFilters: setSelectFilterOptions } =
    useCachedFilters<SelectFilterOptions>({
      key: 'ctPatientsSelect',
      initialValues: {
        showOnlyActivePatients: true,
        showOnlyMyPatients: !isAdmin,
        includeTestPatients: false,
      },
      skip: loadingUserType,
    });

  const { filters: tableFilters, setFilters: setTableFilters } =
    useCachedFilters<TableFilters<PatientUser>>({
      key: 'ctPatients',
      initialValues: {
        searchField: 'subject_id',
        searchQuery: '',
        sortField: 'name',
        sortOrder: TableSortOrder.ASC,
      },
    });

  const { openDialog, closeDialog } = useDialog();
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'ctPatients',
  });

  const searchQueryDebounced = useDebouncedValue(tableFilters.searchQuery);
  const filters = [
    { field: 'is_ct', op: FilterOp.Equal, value: 'true' },
    {
      field: tableFilters.searchField,
      op: FilterOp.Includes,
      value: searchQueryDebounced,
    },
  ];

  const sortBy = {
    field: tableFilters.sortField.toString(),
    order:
      tableFilters.sortOrder === 'asc' ? SortByOrder.Asc : SortByOrder.Desc,
  };

  const {
    nodes: patients,
    pageInfo,
    totalNumberFound,
    fetchMore,
    loading,
    networkStatus,
  } = useGetPatientsList(
    !filterOptions.showOnlyMyPatients,
    isAdmin === undefined,
    rowsPerPage,
    !filterOptions.showOnlyActivePatients,
    sortBy,
    filters,
    filterOptions.includeTestPatients
  );

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const handleAssignCoach = (row: PatientUser) => {
    openDialog({
      title: `Assign coach to ${row.name} `,
      content: <AssignCoachDialog patientId={row.id} />,
    });
  };

  const handleAddPatientGroup = (patientId: string) => {
    openDialog({
      title: '',
      content: <AddPatientGroup patientId={patientId} onFinish={closeDialog} />,
    });
  };

  const handleExportData = async () => {
    if (isAdmin) {
      openDialog({
        title: 'Export data for:',
        content: <ExportScopeDialog type="ct" />,
      });
    } else {
      await requestDownload({
        type: SurveysExportType.CtPatients,
        surveyType: SurveyType.Normal,
      });
      await requestDownload({
        type: SurveysExportType.CtPatients,
        surveyType: SurveyType.Bhb,
      });
    }
  };

  const handleFilterChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as FilterType[];

    setSelectFilterOptions({
      showOnlyMyPatients: value.includes(FilterType.showOnlyMyPatients),
      showOnlyActivePatients: value.includes(FilterType.showOnlyActivePatients),
      includeTestPatients: value.includes(FilterType.includeTestPatients),
    });
  };

  const renderRow = (row: PatientUser) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="PATIENT_NAME_CELL">
          <Avatar type="imageText" name={row.name} picture={row.picture} />
        </TableCell>
        <TableCell data-testid="SUBJECT_ID_CELL">{row.subject_id}</TableCell>
        <TableCell data-testid="STUDY_START_CELL">
          {formatUTCDate(row.study_start)}
        </TableCell>
        <TableCell data-testid="ASSOCIATED_SITE_CELL">
          {showSiteFeatures ? row.site?.name : row.associated_site}
        </TableCell>
        <TableCell data-testid="SURVEY_CELL">
          <ProgressStatusChips progressStatus={row.progress?.survey} />
        </TableCell>
        <TableCell data-testid="GROUP_CELL">
          {row.groups.length ? (
            row.groups
          ) : (
            <Button
              variant="contained"
              color="secondary"
              startEvaIcon={{ name: 'plus-outline' }}
              onClick={() => handleAddPatientGroup(row.id)}
              data-testid="ADD_GROUP_CELL_BUTTON"
            >
              Add
            </Button>
          )}
        </TableCell>
        <TableCell data-testid="PATIENT_COACH_CELL">
          {row.coach ? (
            row.coach?.name
          ) : (
            <Button
              variant="contained"
              color="secondary"
              startEvaIcon={{ name: 'plus-outline' }}
              onClick={() => handleAssignCoach(row)}
            >
              Assign
            </Button>
          )}
        </TableCell>
        <TableCell padding="checkbox">
          <Link
            to={`/patient/${row.id}`}
            state={{ backRoute: `/ct` }}
            data-testid="PATIENT_PROFILE_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="baseline"
        columnGap={2}
      >
        <StatelessSearchableTableHeader<PatientUser>
          headCells={headCells}
          searchField={tableFilters.searchField}
          searchQuery={tableFilters.searchQuery}
          onSearchQueryChange={(query) =>
            setTableFilters({ searchQuery: query })
          }
          onSearchFieldChange={(field) => {
            setTableFilters({ searchField: field });
          }}
        />

        <Select
          sx={{
            marginBottom: 2,
            flex: 1,
          }}
          testId={'CT_FILTERS_SELECT'}
          options={ctFilterOptions}
          value={Object.keys(filterOptions).filter(
            (item) => filterOptions[item as keyof SelectFilterOptions]
          )}
          onChange={handleFilterChange}
          selectType="checkbox"
          multiple={true}
          placeholder="View Options"
          showPlaceholderOnly
        />
        {!isCsr && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            startEvaIcon={{ name: 'download-outline' }}
            onClick={handleExportData}
            data-testId="EXPORT_BUTTON"
          >
            Export
          </Button>
        )}
      </Box>
      <Paper data-testid="CT_PATIENTS_TABLE">
        <StatelessTable<PatientUser>
          actions="right"
          sortOrder={tableFilters.sortOrder}
          sortField={tableFilters.sortField}
          headCells={headCells}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          renderRow={renderRow}
          rows={patients || []}
          isLoading={loading || loadingFeatureFlag}
          totalRowCount={totalNumberFound}
          withPagination
          onSortChange={(field, order) =>
            setTableFilters({ sortField: field, sortOrder: order })
          }
        />
      </Paper>
    </>
  );
};

export default CTPatientsTab;
