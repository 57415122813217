import { Box } from '@mui/material';
import React from 'react';
import { Waypoint } from 'react-waypoint';

import Loader from '../Loader/Loader';

interface LoadMoreProps {
  isLoadingMore: boolean;
  listLength: number;
  onLoadMore: () => void;
}

export const LoadMore: React.FC<LoadMoreProps> = ({
  isLoadingMore,
  onLoadMore,
  listLength,
}) => {
  const renderLoading = () => {
    return (
      <Box padding={1} width="100%">
        <Loader size={20} />
      </Box>
    );
  };

  return (
    <>
      <Waypoint onEnter={onLoadMore} key={listLength} />
      {isLoadingMore && renderLoading()}
    </>
  );
};
