import React from 'react';
import { Box } from '@mui/material';
import InputMask from 'react-input-mask';

import TextField, { TextFieldProps } from '../TextField/TextField';

export type Mask = 'date' | 'time' | 'monthYear' | 'phoneNumber' | 'number';

export type MaskedTextFieldCommonProps = TextFieldProps & {
  value?: string;
};

export type MaskedTextFieldProps =
  | (MaskedTextFieldCommonProps & {
      mask: Exclude<Mask, 'number'>;
    })
  | (MaskedTextFieldCommonProps & { mask: 'number'; maxLength?: number });

export const MaskedTextField = (props: MaskedTextFieldProps) => {
  const { mask, onChange, onBlur, value, disabled, ...textFieldProps } = props;

  let maxLength = 1;
  if ('maxLength' in props && props.maxLength) {
    maxLength = props.maxLength;
  }

  const getMask = () => {
    switch (mask) {
      case 'date':
        return '99/99/9999';
      case 'monthYear':
        return '99/9999';
      case 'time':
        return '99:99';
      case 'phoneNumber':
        return '999-999-9999';
      case 'number':
        return '9'.repeat(maxLength);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <InputMask
        mask={getMask()}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={value}
        maskChar={null}
        maskPlaceholder={null}
        disabled={disabled}
      >
        <TextField disabled={disabled} {...textFieldProps} />
      </InputMask>
    </Box>
  );
};
