import React from 'react';
import {
  DatePicker,
  DatePickerProps,
  addDateValidation,
  formatDate,
} from '@fdha/web-ui-library';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { EditableCard, EditableCardProps } from './EditableCard';

type DatePickerValue = DatePickerProps['value'];
export interface EditableCardDateSchema {
  date?: DatePickerValue;
}

export interface EditableCardDateProps extends EditableCardProps {
  initialValue: DatePickerValue;
  onSave: (value: DatePickerValue) => Promise<void>;
  datePickerProps: Omit<DatePickerProps, 'onChange' | 'value'>;
}

const validationSchema = Yup.object().shape({
  date: addDateValidation(undefined, undefined, false, true),
});

export const EditableCardDate: React.FC<EditableCardDateProps> = ({
  initialValue,
  onSave,
  datePickerProps,
  ...editableCardProps
}) => {
  const initialValues: EditableCardDateSchema = {
    date: initialValue,
  };

  const handleSubmit = async (values: EditableCardDateSchema) => {
    await onSave(values.date);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors, handleBlur }) => {
        return (
          <EditableCard
            label={
              initialValue
                ? formatDate(initialValue, 'monthDayYear')
                : undefined
            }
            {...editableCardProps}
          >
            <DatePicker
              {...datePickerProps}
              name="date"
              value={values.date}
              onChange={(value) => setFieldValue('date', value)}
              error={!!errors.date}
              helperText={errors.date}
              onBlur={handleBlur}
            />
          </EditableCard>
        );
      }}
    </Formik>
  );
};

export default EditableCardDate;
