import React, { ChangeEvent } from 'react';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import SpaceBetweenBox from '../SpaceBetweenBox/SpaceBetweenBox';

import { FlatHeadCell, HeadCell, TableField } from './StatelessTable';

export interface StatelessSearchableTableHeaderProps<T extends object> {
  headCells: HeadCell<T>[];
  disableMargin?: boolean;
  searchField: TableField<T>;
  searchQuery: string;
  onSearchFieldChange: (field: TableField<T>) => void;
  onSearchQueryChange: (value: string) => void;
}

export const StatelessSearchableTableHeader = <T extends object>({
  headCells,
  disableMargin,
  searchField,
  searchQuery,
  onSearchFieldChange,
  onSearchQueryChange,
}: StatelessSearchableTableHeaderProps<T>) => {
  const handleFieldChange = (e: SelectChangeEvent<TableField<T>>) => {
    const value = e.target.value as TableField<T>;

    onSearchFieldChange(value);
  };

  const handleQueryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    onSearchQueryChange(value);
  };

  return (
    <SpaceBetweenBox
      sx={{
        width: '100%',
        marginBottom: disableMargin ? 0 : 2,
      }}
    >
      <Select
        defaultValue={searchField}
        sx={{
          flex: 1,
        }}
        onChange={handleFieldChange}
        data-testid="FILTER_DROPDOWN"
      >
        {(headCells as FlatHeadCell[])
          .filter((item) => item.searchable)
          .map((item) => {
            const id = item.id.toString();
            return (
              <MenuItem key={id} value={id} data-testid="FILTER_ITEM">
                {item.label}
              </MenuItem>
            );
          })}
      </Select>
      <TextField
        placeholder="Search"
        sx={{
          flex: 6,
          marginLeft: 2,
        }}
        onChange={handleQueryChange}
        value={searchQuery}
        autoFocus
        data-testid="SEARCH_INPUT"
      />
    </SpaceBetweenBox>
  );
};
