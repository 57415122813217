export enum DishType {
  Meal = 'meal',
  Snack = 'snack',
}

export enum MealType {
  Breakfast = 'breakfast',
  Dinner = 'dinner',
  Lunch = 'lunch',
  Sides = 'sides',
  Snacks = 'snacks',
}

interface TagItem {
  id: string;
  name: string;
}

export interface CategoryItem {
  categoryId: string;
  categoryName: string;
  tags: TagItem[];
}

export interface MenuOptionSelection {
  dishId: string;
  menuOptionId: string;
  name: string;
  photoUrl?: string | null;
  quantity: number;
  type: DishType;
  mealType?: MealType[];
  tagsByCategory?: CategoryItem[] | null;
}

export interface MenuOptions {
  entreeDishes: MenuOptionSelection[];
  snacks: MenuOptionSelection[];
}

export type SectionType = 'entreeDishes' | 'snacks';

export enum MenuType {
  QuickShip = 'quick_ship',
  Regular = 'regular',
}
