import React, { FC } from 'react';
import { Grid, Paper, useTheme } from '@mui/material';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

export interface WarningProps {
  text?: string;
}

export const Warning: FC<WarningProps> = ({ text }) => {
  const theme = useTheme();

  return text ? (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: theme.palette.warning.lightBg,
          borderColor: theme.palette.warning.border,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 2,
        }}
      >
        <Icon
          name="alert-triangle-outline"
          fill={theme.palette.warning.dark}
          size="large"
        />
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {text}
        </Typography>
      </Paper>
    </Grid>
  ) : null;
};
