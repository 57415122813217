import React, { PropsWithChildren } from 'react';
import {
  Box,
  Link,
  useTheme,
  BoxProps,
  Stack,
  StackProps,
} from '@mui/material';
import Icon from 'react-eva-icons';

import { Typography } from '../Typography/Typography';
import { Button } from '../Button/Button';

interface BasePageProps extends BoxProps {
  ['data-testId']?: string;
}

interface BackButtonProps {
  to?: string;
  component?: React.ElementType;
  handleClick?: () => void;
}

const BasePage: React.FC<BasePageProps> = (props) => {
  return (
    <Box display="flex" width="100%" flexDirection="column" flex={1} {...props}>
      {props.children}
    </Box>
  );
};

const BackButton = ({
  to = '/',
  component = Link,
  handleClick,
}: BackButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      component={component}
      to={to}
      size="large"
      color="primary"
      startIcon={
        <Box height={18}>
          <Icon
            name="arrow-ios-back-outline"
            fill={theme.palette.secondary.contrastText}
          />
        </Box>
      }
      sx={{ alignSelf: 'flex-start', marginBottom: '32px' }}
      data-testid="BACK_BUTTON"
      onClick={handleClick}
      i18nKey="common:button.back"
    >
      Back
    </Button>
  );
};

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant="h5" marginBottom={3}>
      {children}
    </Typography>
  );
};

const Wrapper: React.FC<StackProps> = (props) => {
  const width = '900px';

  return (
    <Stack
      maxWidth={width}
      direction="column"
      height="100%"
      width="100%"
      alignSelf="center"
      {...props}
    >
      {props.children}
    </Stack>
  );
};

export default Object.assign(BasePage, { BackButton, Title, Wrapper });
