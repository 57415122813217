import { Tooltip, Typography, TypographyProps } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';

type CustomTypographyProps = Omit<
  TypographyProps,
  'ref' | 'overflow' | 'whiteSpace' | 'textOverflow'
>;

interface OverflowTextProps extends CustomTypographyProps {
  label: string;
}

export const OverflowText: React.FC<OverflowTextProps> = ({
  label,
  children,
  ...muiProps
}) => {
  const typographyRef = useRef<HTMLSpanElement>(null);

  const [isOverflown, setIsOverflown] = useState(false);

  const compareSize = () => {
    if (typographyRef.current) {
      const compare =
        typographyRef.current.scrollWidth > typographyRef.current.clientWidth;

      setIsOverflown(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  // TODO: Change Typography import from @mui/material to web-ui-library
  // Currently, doing that is causing an error on web-patient tests
  return (
    <Tooltip title={label} disableHoverListener={!isOverflown}>
      <Typography
        ref={typographyRef}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        {...muiProps}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
