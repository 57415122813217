import React, { FC, useMemo } from 'react';
import { Box, Card as MuiCard, styled } from '@mui/material';
import {
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceLine,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from '@mui/material';

import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { Typography } from '../Typography/Typography';

import { YAxisBaselineComponent } from './YAxisBaseline';
import { YAxisLimitsComponent } from './YAxisLimits';

const Card = styled(MuiCard)(({ theme }) => ({
  padding: '16px 16px 16px 0',
  ':hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface GraphBasicProps {
  data: { x: string; y: string; color: string }[];
  showSkeleton?: boolean;
  showCard?: boolean;
}

interface GraphBaselineProps extends GraphBasicProps {
  baseline: number;
  target: number;
}

interface GraphLimitsProps extends GraphBasicProps {
  limits: { bottom: number; upper: number };
}

export type GraphProps = GraphBaselineProps | GraphLimitsProps;

export const Graph: FC<GraphProps> = (props) => {
  const theme = useTheme();

  const { data, showSkeleton, showCard } = props;
  const baseline = 'baseline' in props ? props.baseline : undefined;
  const target = 'target' in props ? props.target : undefined;
  const limits = 'limits' in props ? props.limits : undefined;

  const ticks = useMemo(() => {
    if (limits) {
      return [0.1, 2, 6.5, limits.bottom, limits.upper]
        .sort((a, b) => a - b)
        .map((l) => l.toFixed(1));
    }

    if (baseline && target) {
      const minDataValue = Math.min(
        ...data.map((d) => parseFloat(d.y)),
        target
      );

      const maxDataValue = Math.max(
        ...data.map((d) => parseFloat(d.y)),
        baseline
      );

      const min = minDataValue - minDataValue * 0.5;
      const max = maxDataValue + maxDataValue * 0.5;

      const range = max - min;
      const tickIndex = range / 6;

      const multiples: string[] = [];
      let previousValue = min - tickIndex;

      for (let i = 0; i <= 6; i++) {
        const value = previousValue + tickIndex;
        multiples.push(value.toFixed(0));
        previousValue = value;
      }

      return multiples;
    }

    return [];
  }, [limits, baseline, target, data]);

  const renderGraph = () => (
    <Box
      display="flex"
      flexDirection="row"
      paddingTop={2.5}
      paddingLeft={1.5}
      paddingRight={4}
    >
      {limits ? (
        <YAxisLimitsComponent ticks={ticks} />
      ) : (
        <YAxisBaselineComponent ticks={ticks} />
      )}
      <Box
        sx={{
          overflowX: 'scroll',
          direction: 'rtl',
          overflowY: 'hidden',
        }}
      >
        <ComposedChart
          width={Math.max(400, 83 * data.length)}
          height={200}
          data={data}
        >
          <XAxis dataKey="x" fontSize={12} interval="preserveStartEnd" />
          <YAxis
            dataKey="y"
            domain={[Number(ticks[0]), Number(ticks[ticks.length - 1])]}
            hide={true}
            ticks={ticks}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (!active || !payload) {
                return null;
              }
              const value = payload[0].value;
              const color = payload[0].payload.color;

              return (
                <Box
                  sx={{
                    backgroundColor: color,
                    borderRadius: 20,
                    minWidth: 35,
                    minHeight: 23,
                    paddingInline: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    color={theme.palette.background.paper}
                  >
                    {value}
                  </Typography>
                </Box>
              );
            }}
          />
          {baseline && (
            <ReferenceLine y={baseline} stroke="#B72D3B" width={2} />
          )}
          {target && data.length && (
            <>
              <ReferenceLine y={target} stroke="#3CA130" width={2} />
              <ReferenceArea
                x1={data[0].x}
                x2={data[data.length - 1].x}
                y1={ticks[0]}
                y2={target}
                fill="rgba(0, 73, 51, 0.08)"
              />
            </>
          )}
          {limits && (
            <>
              {Object.values(limits).map((limit, index) => (
                <ReferenceLine
                  key={index}
                  y={limit}
                  stroke="#3CA130"
                  strokeWidth={2}
                />
              ))}
              <ReferenceArea
                y1={limits.bottom}
                y2={limits.upper}
                fill="rgba(0, 73, 51, 0.08)"
              />
            </>
          )}
          <Line
            type="linear"
            dataKey="y"
            stroke={theme.palette.text.hint}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
          <Scatter type="monotone" dataKey="y" fill={theme.palette.text.hint} />
        </ComposedChart>
      </Box>
    </Box>
  );

  return (
    <SkeletonWrapper isLoading={!!showSkeleton}>
      {showCard ? <Card>{renderGraph()}</Card> : renderGraph()}
    </SkeletonWrapper>
  );
};
