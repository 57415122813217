import * as Yup from 'yup';

import { addMinDateValidation, requiredMessage } from './validationSchema';

enum ValidationType {
  Date = 'date',
  NotAllowed = 'notAllowed',
}

interface DateValidation {
  type: ValidationType;
  required: boolean;
  min?: Date;
  max?: Date;
  errorMessage?: string;
}

interface NotAllowedValidation {
  type: ValidationType;
}

type Validation = DateValidation | NotAllowedValidation;

interface CustomField {
  name: string;
  draftValidation?: Validation | null;
  submissionValidation?: Validation | null;
  required?: boolean | null;
}

const getDateValidation = (validation: DateValidation) => {
  const errorMessage =
    'This date is too early to be protocol compliant, please select another date';
  const dateValidation = addMinDateValidation(validation.min, errorMessage);

  return validation.required
    ? dateValidation.required(requiredMessage)
    : dateValidation;
};

const getValidation = (validation?: Validation | null) => {
  switch (validation?.type) {
    case ValidationType.Date:
      return getDateValidation(validation as DateValidation);
    default:
      return;
  }
};

export const getCustomFieldsValidationSchema = (
  isDraft: boolean,
  customFields?: CustomField[]
) => {
  const customFieldsValidationSchema =
    customFields?.map((customField: CustomField) => {
      const validation = isDraft
        ? customField.draftValidation
        : customField.submissionValidation;

      return {
        [customField.name]: getValidation(validation),
      };
    }) ?? [];

  return Yup.object().shape(Object.assign({}, ...customFieldsValidationSchema));
};

export const getCustomFieldsName = (
  onlyRequired: boolean = false,
  customFields?: CustomField[]
) => {
  return (
    customFields?.flatMap((f) =>
      !onlyRequired || f.required ? [f.name] : []
    ) || []
  );
};

export const shouldShowCustomField = (
  name: string,
  customFields?: CustomField[],
  isLoading?: boolean,
  isDraft?: boolean
) => {
  if (isLoading) {
    return false;
  }

  return !!customFields?.some((f) => {
    const validation = isDraft ? f.draftValidation : f.submissionValidation;
    return f.name === name && validation?.type !== ValidationType.NotAllowed;
  });
};
