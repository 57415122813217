import { Alert } from '@fdha/web-ui-library';

export const DaysOnDietAlert = () => {
  return (
    <Alert severity="warning">
      This feature relies on subject's <b>days on diet.</b> Make sure it is
      correct.
    </Alert>
  );
};

export default DaysOnDietAlert;
