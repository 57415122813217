import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignIn, getCurrentUser } from '@aws-amplify/auth';
import { useReactiveVar } from '@apollo/client';
import {
  incorrectPasswordMessage,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { getTranslatedErrorMessage } from '@fdha/common-utils';

import { cognitoUser, setCognitoUser } from '../states/userCognitoState';
import {
  getHintMessage,
  PasswordRule,
  PasswordRuleUtils,
} from '../utils/passwordRuleUtils';

import ChangePasswordForm from './ChangePasswordForm';

export interface ChangePasswordProps {
  v2?: boolean;
  showTosToggle?: boolean;
  showSmsToggle?: boolean;
  handleSmsToggle?: (acceptedSms: boolean) => Promise<void>;
  handleRememberMe?: (value: boolean, userId: string) => void;
}

interface StateProps {
  username: string;
  rememberMe: boolean;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  v2 = false,
  showTosToggle = false,
  showSmsToggle = false,
  handleSmsToggle,
  handleRememberMe,
}) => {
  const userCognito = useReactiveVar(cognitoUser);
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbarV2 } = useSnackbar();
  const matchPasswordMessage = getTranslatedErrorMessage(
    'matchPassword',
    'web'
  );

  const state = location.state as StateProps;
  const usernameFromSignIn = state.username;
  const rememberMe = state.rememberMe;

  const [newPasswordError, setNewPasswordError] = useState('');
  const [newConfirmPasswordError, setNewConfirmPasswordError] = useState('');
  const [passwordHint, setPasswordHint] = useState('');
  const [passwordHintV2, setPasswordHintV2] = useState<
    PasswordRule[] | undefined
  >(undefined);
  const [passwordTipsError, setPasswordTipsError] = useState('');

  const dialog = useDialog();
  const dialogType = {
    confirmed: {
      title: 'New password saved!',
      i18nKeyTitle: 'login:forgotPassword.dialog.confirmed',
      confirmButtonLabel: 'LOG IN',
      i18nKeyConfirmButton: 'login:dialog.confirmButtonLogin',
    },
    error: {
      title: 'Sorry, something went wrong. Please try again.',
      i18nKeyTitle: 'login:forgotPassword.dialog.error',
      confirmButtonLabel: 'OK',
    },
  };

  useEffect(() => {
    let isMounted = true;
    try {
      (async () => {
        if (v2) {
          const hint = await PasswordRuleUtils.getPasswordHintMessageV2();
          if (isMounted) {
            setPasswordHintV2(hint);
          }
        } else {
          const hint = await getHintMessage();
          if (isMounted) {
            setPasswordHint(hint);
          }
        }
      })();
    } catch (error) {
      console.log(error);
    }
  }, [v2]);

  useEffect(() => {
    if (!userCognito) {
      navigate('/', { replace: true });
    }
  }, [userCognito, navigate]);

  const showDialog = (
    type: { title: string; confirmButtonLabel: string; i18nKeyTitle?: string },
    callback?: () => void
  ) => {
    dialog.openDialog({
      title: type.title,
      content: null,
      confirmButtonLabel: type.confirmButtonLabel,
      cancelButtonLabel: '',
      i18nKeyTitle: type?.i18nKeyTitle,
      handleConfirm: async () => {
        dialog.closeDialog();

        if (callback) {
          callback();
        } else {
          navigate('/', { replace: true });
        }
      },
    });
  };

  const handleSubmit = async (values: {
    newPassword: string;
    confirmNewPassword: string;
    acceptedSms?: boolean;
  }) => {
    setPasswordTipsError('');
    setNewConfirmPasswordError('');
    setPasswordHint('');

    const newPasswordValue = values.newPassword.trim();
    const confirmNewPasswordValue = values.confirmNewPassword.trim();

    const rulesErrorList =
      PasswordRuleUtils.getPasswordRulesListError(newPasswordValue);

    if (rulesErrorList) {
      setPasswordTipsError(rulesErrorList);
      return;
    }

    if (newPasswordValue !== confirmNewPasswordValue) {
      setNewConfirmPasswordError(matchPasswordMessage);
      return;
    }

    try {
      const { nextStep } = await confirmSignIn({
        challengeResponse: newPasswordValue,
      });

      let callback;

      if (handleSmsToggle && values.acceptedSms != null) {
        await handleSmsToggle(values.acceptedSms);
      }

      if (handleRememberMe) {
        const currentUser = await getCurrentUser();
        handleRememberMe(rememberMe, currentUser.username);
      }

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
        const destination = nextStep.codeDeliveryDetails?.destination;

        if (!destination) throw new Error('Destination not found');

        setCognitoUser({
          username: usernameFromSignIn,
          challengeParam: {
            CODE_DELIVERY_DESTINATION: destination || '',
          },
        });

        callback = () => {
          navigate('/sms-challenge', {
            state: { username: usernameFromSignIn, password: newPasswordValue },
          });
        };
      } else {
        callback = () => {
          navigate('/', { replace: true });
        };
      }

      if (v2) {
        callback();
      } else {
        showDialog(dialogType.confirmed, callback);
      }
    } catch (e: any) {
      if (v2) {
        if (e.code === 'InvalidPasswordException') {
          showSnackbarV2({
            message: incorrectPasswordMessage,
            severity: 'error',
            i18nKey: 'validations:input.password.incorrect',
          });
        } else {
          showSnackbarV2({
            message: dialogType.error.title,
            severity: 'error',
            i18nKey: dialogType.error.i18nKeyTitle,
          });
        }
      } else {
        if (e.code === 'InvalidPasswordException') {
          setNewPasswordError(incorrectPasswordMessage);
        } else {
          showDialog({
            title: dialogType.error.title,
            confirmButtonLabel: dialogType.error.confirmButtonLabel,
          });
        }
      }
    }
  };

  return (
    <ChangePasswordForm
      hint={passwordHint}
      passwordHint={passwordHintV2}
      errors={{
        newPasswordError: passwordTipsError || newPasswordError,
        confirmNewPasswordError: newConfirmPasswordError,
      }}
      handleSubmit={handleSubmit}
      v2={v2}
      showTosToggle={showTosToggle}
      showSmsToggle={showSmsToggle}
    />
  );
};
