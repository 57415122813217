import { Paper, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import Icon from 'react-eva-icons';
import { useGetFoodProgramUserQuery } from '@fdha/graphql-api-admin';
import { getConcatWeekdaysOnDiet } from '@fdha/common-utils';

import SchedulesFormPreferences from './SchedulesFormPreferences';

const NamePaper = styled(Paper)(({ theme }) => ({
  padding: '16px',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

const IconPaper = styled(Paper)(() => ({
  padding: '8px',
  borderRadius: '7px',
  marginRight: '16px',
  lineHeight: 0,
}));

interface ScheduleProps {
  surveyName: string;
  title?: string;
  isBulkAssign: boolean;
  isDataSurvey?: boolean;
  patientId?: string;
}

const Schedule: React.FC<ScheduleProps> = ({
  surveyName,
  title,
  isDataSurvey,
  isBulkAssign,
  patientId,
}) => {
  const theme = useTheme();

  const { data: foodProgramData } = useGetFoodProgramUserQuery({
    variables: {
      patientId: patientId || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !patientId || isBulkAssign,
  });

  const foodProgramUser = foodProgramData?.foodProgramUserByPatientIdV2;

  const weekDaysOnDiet = foodProgramUser?.userDeliveryMenus?.length
    ? getConcatWeekdaysOnDiet(foodProgramUser?.userDeliveryMenus)
    : [];

  return (
    <>
      <Typography variant="h5">{title || 'Schedule Survey'}</Typography>
      <NamePaper>
        <IconPaper>
          <Icon
            name={isDataSurvey ? 'activity-outline' : 'file-text-outline'}
            fill={theme.palette.primary.main}
            size="large"
          />
        </IconPaper>
        <Typography variant="body1">{surveyName}</Typography>
      </NamePaper>
      <SchedulesFormPreferences>
        <SchedulesFormPreferences.StartDate />
        <SchedulesFormPreferences.EndsAt />
        <SchedulesFormPreferences.Time />
        <SchedulesFormPreferences.FrequencyForm
          weekDaysOnDiet={weekDaysOnDiet}
          isBulkAssign={isBulkAssign}
        />
        <SchedulesFormPreferences.CompleteWithin
          disabled={isDataSurvey}
          title={isDataSurvey ? 'Close after' : undefined}
        />
      </SchedulesFormPreferences>
    </>
  );
};

export default Schedule;
