import { Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import Icon from 'react-eva-icons';

import { useDialog, useSnackbar } from '../../hooks';
import { Avatar } from '../Avatar/Avatar';

export interface UploadPictureProps {
  name?: string;
  picture?: string | null;
  downloadPicture?: (picture: string) => Promise<string | undefined>;
  handleUpload: (imageFile: File) => void;
  dataTestId?: string;
  size?: number;
  v2?: boolean;
}

const AvatarContainer = styled('div')({
  position: 'relative',
  borderRadius: '50%',
  '& #cameraIcon': {
    opacity: 0,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: grey[600],
    '& #cameraIcon': {
      opacity: 1,
    },
    '& #avatar': {
      opacity: 0.5,
    },
  },
});

const Input = styled('input')({
  display: 'none',
});

const Image = styled('img')({
  maxWidth: '500px',
  maxHeight: '420px',
});

const UploadPicture: React.FC<UploadPictureProps> = ({
  v2,
  size = 80,
  name,
  picture,
  dataTestId,
  handleUpload,
  downloadPicture,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [avatarImage, setAvatarImage] = useState<string | undefined | null>(
    picture
  );

  const handleUploadImage = async (imageFile: File, imageUrl: string) => {
    try {
      await handleUpload(imageFile);
      setAvatarImage(imageUrl);
    } catch (error: any) {
      showSnackbar({
        message: 'Error to upload picture',
        severity: 'error',
        i18nKey: 'profile:uploadPictureError',
      });
    } finally {
      closeDialog();
    }
  };

  const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const imageFile = e.target.files[0];
      const imageUrl = URL.createObjectURL(imageFile);
      openDialog({
        title: 'Profile Picture',
        content: (
          <Box display="flex" justifyContent="center">
            <Image src={imageUrl} alt="profile-preview" />
          </Box>
        ),
        confirmButtonLabel: 'Upload',
        i18nKeyConfirmButton: 'common:button.upload',
        cancelButtonLabel: 'Cancel',
        handleConfirm: () => handleUploadImage(imageFile, imageUrl),
        i18nKeyTitle: 'profile:dialogPicture.title',
        v2,
      });
    }
  };

  return (
    <label htmlFor="upload-image" data-testid={dataTestId}>
      <Input
        accept="image/*"
        id="upload-image"
        type="file"
        onChange={handleSelectImage}
      />
      <AvatarContainer>
        <Avatar
          id="avatar"
          picture={avatarImage}
          name={name}
          downloadPicture={downloadPicture}
          size={size}
          sx={{ marginLeft: 0 }}
        />
        <Box
          id="cameraIcon"
          sx={{
            transform: 'translate(-50%, -50%)',
          }}
          position="absolute"
          left="50%"
          top="50%"
          lineHeight={0}
        >
          <Icon name="camera" size="large" />
        </Box>
      </AvatarContainer>
    </label>
  );
};

export default UploadPicture;
