import React, { useEffect } from 'react';
import { Box, Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { NetworkStatus } from '@apollo/client';
import {
  Button,
  StatelessTable,
  HeadCell,
  StatelessSearchableTableHeader,
  TableFilters,
  TableSortOrder,
} from '@fdha/web-ui-library';
import {
  CoachSiteTrial,
  SiteStatus,
  FilterOp,
  SortByOrder,
  useListCoachSiteTrialsQuery,
} from '@fdha/graphql-api-admin';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import { useCachedFilters } from '@fdha/common-hooks';

import { getStatus, assignmentAvailabilityLabels } from '../../../utils';
import { useDebouncedValue, useTable } from '../../../hooks';

const headCells: HeadCell<CoachSiteTrial>[] = [
  { id: 'site:name', label: 'Site', sortable: true, searchable: true },
  {
    id: 'trial:protocol_number',
    label: 'Trial',
    sortable: true,
    searchable: true,
  },
  {
    id: 'siteTrialStatus',
    label: 'Site/Trial Status',
    sortable: false,
    searchable: false,
  },
  {
    id: 'assignmentAvailability',
    label: 'Patient Assignment',
    sortable: false,
    searchable: false,
  },
];

const renderRow = (row: CoachSiteTrial) => {
  return (
    <TableRow hover key={row.id}>
      <TableCell>{row.site?.name}</TableCell>
      <TableCell>{row.trial?.protocol_number}</TableCell>
      <TableCell>
        {getStatus(row.siteTrialStatus === SiteStatus.Active)}
      </TableCell>
      <TableCell>
        {assignmentAvailabilityLabels[row.assignmentAvailability]}
      </TableCell>
      <TableCell padding="checkbox">
        <Link to={`./edit-site-trial/${row.id}`}>
          <Icon
            name="arrow-ios-forward-outline"
            fill={grey[600]}
            size="large"
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

const CoachSiteTrialTab = () => {
  const navigate = useNavigate();
  const params = useParams();
  const coachId = params.coachId || '';

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: `coachSiteTrials_${coachId}`,
  });

  const { filters: tableFilters, setFilters: setTableFilters } =
    useCachedFilters<TableFilters<CoachSiteTrial>>({
      key: `coachSiteTrials_${coachId}`,
      initialValues: {
        searchField: 'site:name',
        searchQuery: '',
        sortField: 'site:name',
        sortOrder: TableSortOrder.ASC,
      },
    });

  const searchQueryDebounced = useDebouncedValue(tableFilters.searchQuery);

  const emptyState = searchQueryDebounced
    ? 'No results found'
    : 'No Site Trials assigned.';

  const filters = [
    {
      field: tableFilters.searchField,
      op: FilterOp.Includes,
      value: searchQueryDebounced,
    },
  ];

  const sortBy = {
    field: tableFilters.sortField.toString(),
    order:
      tableFilters.sortOrder === TableSortOrder.ASC
        ? SortByOrder.Asc
        : SortByOrder.Desc,
  };

  const { data, fetchMore, loading, networkStatus } =
    useListCoachSiteTrialsQuery({
      variables: {
        coachId,
        first: rowsPerPage,
        filterBy: {
          filterBy: filters,
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const nodes = data?.coachSiteTrials?.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.coachSiteTrials?.pageInfo;
  const totalNumberFound = data?.coachSiteTrials?.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  return (
    <>
      <Stack direction="row" alignItems="baseline" spacing={2}>
        <Box flex={1}>
          <StatelessSearchableTableHeader<CoachSiteTrial>
            headCells={headCells}
            searchField={tableFilters.searchField}
            searchQuery={tableFilters.searchQuery}
            onSearchQueryChange={(query) =>
              setTableFilters({ searchQuery: query })
            }
            onSearchFieldChange={(field) =>
              setTableFilters({ searchField: field })
            }
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startEvaIcon={{ name: 'plus-outline' }}
            onClick={() => {
              navigate('./assign-site-trial');
            }}
          >
            Assign Site/Trial
          </Button>
        </Box>
      </Stack>
      <Paper>
        <StatelessTable<CoachSiteTrial>
          actions="right"
          sortOrder={tableFilters.sortOrder}
          sortField={tableFilters.sortField}
          headCells={headCells}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          renderRow={renderRow}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          withPagination
          onSortChange={(field, order) =>
            setTableFilters({ sortField: field, sortOrder: order })
          }
          emptyState={emptyState}
        />
      </Paper>
    </>
  );
};

export default CoachSiteTrialTab;
