import { Paper as MuiPaper, PaperProps, styled } from '@mui/material';
import React, { FC } from 'react';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '16px 24px',
  border: 'none',
  boxShadow: theme.shadows[2],
}));

const ProfileCardPaper: FC<PaperProps> = ({ children, ...props }) => {
  return (
    <Paper data-testid="PROFILE_CARD" {...props}>
      {children}
    </Paper>
  );
};

export default ProfileCardPaper;
