import React, { FC } from 'react';
import { resetPassword } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikErrors } from 'formik';
import { Box } from '@mui/material';
import {
  Button,
  TextField,
  useDialog,
  validEmailMessage,
} from '@fdha/web-ui-library';
import { useTranslation } from '@fdha/common-hooks';

interface ForgotPasswordSchema {
  email: string;
}

const initialValues: ForgotPasswordSchema = {
  email: '',
};

export interface ForgotPasswordProps {
  v2?: boolean;
}

export const ForgotPassword: FC<ForgotPasswordProps> = ({ v2 }) => {
  const dialog = useDialog();
  const navigate = useNavigate();
  const { translate } = useTranslation();

  /********************* V2 updates *********************/
  const placeholder = v2 ? '' : 'Your email...';
  const buttonColor = v2 ? 'primary' : 'secondary';
  const dialogButtonText = v2 ? 'Ok' : 'OK';
  const emailTitle = v2 ? 'Confirm Email' : 'Confirm your email';
  const buttonSize = v2 ? 'large' : 'medium';
  const navigationTarget = v2 ? '/reset-password' : '';
  const buttonLabel = v2 ? 'Confirm ' : 'Reset password';
  const submitButtonMargingTop = v2 ? '16px' : '24px';
  const disableSubmitButton = (values: ForgotPasswordSchema) =>
    !v2 && !values.email.trim();

  const handleNavigate = (email: string, obscuredEmail: string) => {
    if (v2) {
      navigate(navigationTarget, { state: { email, obscuredEmail } });
    } else {
      dialog.openDialogV2({
        title:
          'We just sent you an email with the instructions to reset your password.',
        content: null,
        confirmButtonLabel: dialogButtonText,
        cancelButtonLabel: '',
        handleConfirm: async () => {
          dialog.closeDialog();
          navigate(navigationTarget);
        },
        i18nKeyTitle: 'login:dialog.forgotPassword',
      });
    }
  };
  /****************** End of V2 updates ******************/

  const validate = (values: ForgotPasswordSchema) => {
    let errors: FormikErrors<ForgotPasswordSchema> = {};
    if (validateEmail(values.email)) {
      errors.email = validEmailMessage;
    }
    return errors;
  };

  const validateEmail = (email: string) => {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const handleSubmit = async (
    values: ForgotPasswordSchema,
    setErrors: (errors: FormikErrors<ForgotPasswordSchema>) => void
  ) => {
    try {
      const result = await resetPassword({
        username: values.email,
      });
      const { destination } = result.nextStep.codeDeliveryDetails;
      if (!destination) throw new Error('Destination not found');
      handleNavigate(values.email, destination);
    } catch (error: any) {
      const message =
        error.code === 'UserNotFoundException'
          ? translate(
              'login:forgotPassword.errors.userNotFoundException',
              'No account associated with this email address.'
            )
          : translate(
              'common:snackbar.somethingWentWrong',
              'Sorry, something went wrong. Please try again.'
            );

      setErrors({
        email: message,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
      validate={(values) => validate(values)}
    >
      {({ values, handleChange, isSubmitting, handleBlur, errors }) => (
        <Form style={{ width: '100%' }}>
          <Box display="flex" flexDirection="column">
            <TextField
              title={emailTitle}
              name="email"
              i18nKeyTitle="login:forgotPassword.input.email.label"
              placeholder={placeholder}
              value={values.email}
              onChange={handleChange}
              type="email"
              v2={v2}
              helperText={errors.email}
              error={!!errors.email}
              onBlur={handleBlur}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color={buttonColor}
            sx={{
              width: '100%',
              height: '42px',
              marginTop: submitButtonMargingTop,
            }}
            disabled={disableSubmitButton(values) || isSubmitting}
            size={buttonSize}
            data-testid="CONFIRM_EMAIL_BUTTON"
            i18nKey="common:button.confirm"
          >
            {buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
