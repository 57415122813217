import React from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

import { Alert, Select, WeekDaysPicker } from '..';
import {
  completeWithinOptionsByFrequency,
  defaultCompleteWithinOptionByFrequency,
  ScheduleCompleteWithin,
  WeekDay,
} from '../..';

export enum Frequency {
  Once = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Triweekly = 'Triweekly',
  Monthly = 'Monthly',
  DaysOnDiet = 'DaysOnDiet',
}

export interface FrequencyInputProps {
  completeWithinDays?: ScheduleCompleteWithin;
  frequency?: string | null;
  frequencyOptions: { label: string; value: string | number }[];
  weekDays: WeekDay[] | undefined;
  initialWeekDays: WeekDay[] | undefined;
  setFieldValue: (value: string, initialValues: any) => void;
  weekDaysOnDiet?: WeekDay[];
  isBulkAssign?: boolean;
  v2?: boolean;
}

export const FrequencyInput: FC<FrequencyInputProps> = ({
  completeWithinDays,
  frequency,
  frequencyOptions,
  weekDays,
  initialWeekDays,
  setFieldValue,
  weekDaysOnDiet,
  isBulkAssign,
  v2,
}) => {
  const setDefaultCompleteWithin = (frequencyValue: Frequency) => {
    setFieldValue(
      'complete_within_days',
      defaultCompleteWithinOptionByFrequency[frequencyValue]
    );
  };

  const onChangeFrequency = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as Frequency;
    if (
      value !== Frequency.Weekly &&
      value !== Frequency.Biweekly &&
      value !== Frequency.Triweekly
    ) {
      setFieldValue('weekDays', initialWeekDays);
    }
    setFieldValue('frequency', value);

    const shouldUpdateCompleteWithin =
      !!completeWithinDays &&
      !completeWithinOptionsByFrequency[value]?.includes(completeWithinDays);
    if (shouldUpdateCompleteWithin) {
      setDefaultCompleteWithin(value);
    }
  };

  const getDaysOnDietAlertText = () => {
    if (weekDaysOnDiet?.length) {
      return (
        <>
          Subject's days on diet: {weekDaysOnDiet.length}{' '}
          {weekDaysOnDiet.join(', ').toLowerCase()}.
          <br />
          The survey scheduling relies on <b>days on diet</b>. Make sure it is
          correct.
        </>
      );
    }
    if (isBulkAssign) {
      return (
        <>
          The survey scheduling relies on <b>days on diet</b>. Make sure it is
          correct.
        </>
      );
    }
    return (
      <>
        Attention: <b>Days on diet</b> is not set for this subject.
        <br />
        The survey scheduling will be updated accordingly when applied.
      </>
    );
  };

  return (
    <Box>
      <Select
        name="frequency"
        title="Frequency"
        i18nKeyTitle="activities:fields.frequency.label"
        value={frequency}
        onChange={onChangeFrequency}
        options={frequencyOptions}
        v2={v2}
      />
      {(frequency === Frequency.Weekly ||
        frequency === Frequency.Biweekly ||
        frequency === Frequency.Triweekly) &&
        weekDays && (
          <WeekDaysPicker
            selectedDays={weekDays}
            fullWidth
            onPressDay={(days) => {
              if (!days.length) {
                setFieldValue('frequency', Frequency.Once);
                setFieldValue('weekDays', initialWeekDays);
              } else {
                setFieldValue('weekDays', days);
              }
            }}
            v2={v2}
          />
        )}
      {frequency === Frequency.DaysOnDiet && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          {getDaysOnDietAlertText()}
        </Alert>
      )}
    </Box>
  );
};
