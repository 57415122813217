import { FormControl, FormHelperText, useTheme } from '@mui/material';
import React from 'react';

import { Icon } from '../Icon/Icon';

export interface InputErrorProps {
  error: React.ReactNode;
  v2: boolean;
}

export const InputError: React.FC<InputErrorProps> = ({ error, v2 }) => {
  const theme = useTheme();

  /************ V2 updates *********************/

  const isCustomErrorMsg = typeof error === 'object' && v2;

  const errorStyleDefault = v2
    ? { marginTop: 0.5, marginX: 0 }
    : { marginBottom: 2, marginTop: 1 };

  const errorStyleCustom = { ...errorStyleDefault, marginBottom: 1 };

  const errorStyle = isCustomErrorMsg ? errorStyleCustom : errorStyleDefault;

  const renderErrorMessageContent = () => {
    return (
      <>
        {v2 && (
          <Icon
            name="alert-circle-outline"
            fill={theme.palette.error.main}
            size="large"
            sx={{ marginRight: 0.5 }}
          />
        )}
        {error}
      </>
    );
  };

  /*********************************************/

  return (
    <FormControl variant="outlined" error>
      <FormHelperText
        data-testid="HELPER_TEXT_ERROR"
        sx={{
          display: 'flex',
          alignItems: 'center',
          lineHeight: 'normal',
          whiteSpace: 'break-spaces',
          fontSize: 14,
          color: theme.palette.error.main,
          ...errorStyle,
        }}
      >
        {renderErrorMessageContent()}
      </FormHelperText>
    </FormControl>
  );
};
