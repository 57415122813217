"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetFoodOpsUserPasswordDocument = exports.AddFoodOpsUserDocument = exports.ListDietPlansV2Document = exports.AddDietPlanDocument = exports.AddMenuDocument = exports.PublishContentfulEntryDocument = exports.EnableFeatureFlagDocument = exports.GetFoodProgramUserDocument = exports.GetPatientsByTrialDocument = exports.GetPatientsBySiteDocument = exports.AddCtPresignupDocument = exports.CreateFoodProgramUserDocument = exports.GetPatientBhbAnswersDocument = exports.AnswerDataSurveyBhbDocument = exports.UpdatePatientInformationDocument = exports.AddNotesTemplateDocument = exports.DeleteGroupsDocument = exports.ListSurveyInstancesDocument = exports.AnswerSurveyDocument = exports.SetGroupsToUserDocument = exports.AddGroupDocument = exports.SaveSubjectDraftDocument = exports.UpdateSiteDocument = exports.UpdatePatientTrialInfoDocument = exports.AddSubjectDocument = exports.DeactivateAccountDocument = exports.AddSiteDocument = exports.UpdateSiteStaffUserDocument = exports.SetSiteStaffUserPasswordDocument = exports.AddSiteStaffUserDocument = exports.UnassignBHBSurveyToPatientDocument = exports.AssignBHBSurveyToPatientDocument = exports.UnassignSurveyDocument = exports.AssignSurveyToUsersDocument = exports.AssociateCoachDocument = exports.GetProfileDocumentPatient = exports.GetProfileDocumentAdmin = exports.UpdateCoachUserDocument = exports.UpdatePatientUserDocument = exports.GetProfilePatientDocument = void 0;
exports.GetProfilePatientDocument = "\n    query getProfile {\n  me {\n    id\n    type\n    email\n    created_at\n    phone_number\n    name\n    birthdate\n    picture\n    cancer_type\n    diag_details\n    diag_date\n    diag_date_desc\n    is_learn_active\n    stream_user_id\n    stream_user_token\n    stream_channel_id\n    gapi_refresh_token\n    coach {\n      id\n      email\n      phone_number\n      name\n      birthdate\n      picture\n      description\n    }\n    backup_coach {\n      id\n      email\n      phone_number\n      name\n      birthdate\n      picture\n      description\n    }\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n    is_ct\n    current_treatment\n    oncologist_name\n    facility {\n      name\n      city\n      state\n    }\n    caregiver {\n      primary_caregiver\n      primary_caregiver_desc\n      name\n    }\n    cheerleader {\n      primary_cheerleader\n      primary_cheerleader_desc\n      name\n    }\n    motivation\n    medication\n    other_medical_conditions\n    last_in_app_review\n  }\n}\n    ";
exports.UpdatePatientUserDocument = "\n    mutation updatePatientUser($id: String!, $props: UpdatePatientUserInput!) {\n  updatePatientUser(id: $id, userProps: $props) {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    cancer_type\n    diag_details\n    diag_date\n    diag_date_desc\n    coach_pref\n    learn_pref\n    is_learn_active\n    is_ct\n    subject_id\n    associated_site\n    study_start\n    main_goal\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n    current_treatment\n    oncologist_name\n    facility {\n      name\n      city\n      state\n    }\n    caregiver {\n      primary_caregiver\n      primary_caregiver_desc\n      name\n    }\n    cheerleader {\n      primary_cheerleader\n      primary_cheerleader_desc\n      name\n    }\n    other_medical_conditions\n    motivation\n    medication\n    site_staff_emails\n  }\n}\n    ";
exports.UpdateCoachUserDocument = "\n    mutation updateCoachUser($id: String!, $props: UpdateCoachUserInput!) {\n      updateCoachUser(id: $id, userProps: $props) {\n        id\n        email\n        phone_number\n        name\n        birthdate\n        picture\n        is_active\n        is_admin\n        new_coachings_available\n        description\n      }\n    }";
exports.GetProfileDocumentAdmin = "\n    query getProfile($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  me(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    is_admin\n    new_coachings_available\n    description\n    groups\n    stream_user_id\n    stream_user_token\n    created_at\n    gapi_refresh_token\n    patients {\n      edges {\n        cursor\n        node {\n          id\n          email\n          phone_number\n          name\n          birthdate\n          picture\n          is_active\n          cancer_type\n          diag_details\n          diag_date\n          diag_date_desc\n          coach_pref\n          learn_pref\n          is_learn_active\n          is_in_treatment\n          is_main\n          created_at\n          is_ct\n          groups\n          subject_id\n          associated_site\n          study_start\n          address {\n            formatted\n            street_address\n            locality\n            region\n            postal_code\n            country\n            complement\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalNumberFound\n    }\n  }\n}\n    ";
exports.GetProfileDocumentPatient = "\n    query getProfile {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    is_admin\n    new_coachings_available\n    description\n    groups\n    stream_user_id\n    stream_user_token\n    created_at\n    gapi_refresh_token\n    patients {\n      edges {\n        cursor\n        node {\n          id\n          email\n          phone_number\n          name\n          birthdate\n          picture\n          is_active\n          cancer_type\n          diag_details\n          diag_date\n          diag_date_desc\n          coach_pref\n          learn_pref\n          is_learn_active\n          is_in_treatment\n          is_main\n          created_at\n          is_ct\n          groups\n          subject_id\n          associated_site\n          study_start\n          address {\n            formatted\n            street_address\n            locality\n            region\n            postal_code\n            country\n            complement\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalNumberFound\n    }\n}\n    ";
exports.AssociateCoachDocument = "\n    mutation associateCoach($patientId: String!, $coachId: String!, $is_main: Boolean = true) {\n  associateCoach(patientId: $patientId, coachId: $coachId, is_main: $is_main) {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    cancer_type\n    diag_details\n    diag_date\n    coach_pref\n    learn_pref\n    is_learn_active\n    current_treatment\n    oncologist_name\n    facility {\n      name\n      city\n      state\n    }\n    caregiver {\n      primary_caregiver\n      primary_caregiver_desc\n      name\n    }\n    cheerleader {\n      primary_cheerleader\n      primary_cheerleader_desc\n      name\n    }\n    other_medical_conditions\n    motivation\n    medication\n  }\n}\n    ";
exports.AssignSurveyToUsersDocument = "mutation assignSurveyToUsers(\n  $surveyId: String!\n  $userIds: [String!] = []\n  $groupIds: [String!] = []\n  $schedule: SurveyScheduleInput!\n) {\n  assignSurveyToUsers(\n    surveyId: $surveyId\n    userIds: $userIds\n    groupIds: $groupIds\n    schedule: $schedule\n  )\n}\n";
exports.UnassignSurveyDocument = "mutation unassignSurvey($patientId: String!, $surveyId: String!) {\n    unassignSurvey(patientId: $patientId, surveyId: $surveyId)\n}\n";
exports.AssignBHBSurveyToPatientDocument = "mutation assignBHBSurveyToPatient($patientId: ID!, $schedule: SurveyScheduleInput!) {\n  assignBHBSurveyToPatient(patientId: $patientId, schedule: $schedule) {\n    id\n    user_id\n    type\n    is_active\n    starts_at\n    ends_at\n    frequency\n    weekdays\n    reminder_minutes\n    complete_within_days\n    timezone\n}\n}\n";
exports.UnassignBHBSurveyToPatientDocument = "mutation unassignBHBSurveyToPatient($patientId: ID!) {\n  unassignBHBSurveyToPatient(patientId: $patientId)\n}\n";
exports.AddSiteStaffUserDocument = "\n    mutation addSiteStaffUser($newUser: AddSiteStaffUserInput!) {\n  addSiteStaffUser(newUser: $newUser) {\n    id\n    type\n    email\n    name\n    role\n    picture\n    is_active\n    site {\n      id\n      name\n    }\n  }\n}\n    ";
exports.SetSiteStaffUserPasswordDocument = "\n    mutation setSiteStaffUserPassword($username: String!, $password: String!) {\n  setSiteStaffUserPassword(username: $username, password: $password)\n}\n    ";
exports.UpdateSiteStaffUserDocument = "mutation updateSiteStaffUser($id: String!, $props: UpdateSiteStaffUserInput!) {\n    updateSiteStaffUser(id: $id, userProps: $props) {\n      id\n      email\n      name\n      picture\n      is_active\n      role\n      groups\n      type\n      site {\n        id\n        name\n        abbreviation\n    }\n  }\n}\n";
exports.AddSiteDocument = "mutation addSite($site: AddSiteInput!) {\n    addSite(site: $site) {\n      id\n      name\n      abbreviation\n      siteTrials {\n        site_identification\n        status\n        id\n        trial {\n          id\n          protocol_abbreviation\n          protocol_number\n          status\n          description\n      }\n    }\n  }\n}\n";
exports.DeactivateAccountDocument = "mutation deactivateAccount($id: String!) {\n    deactivateUser(id: $id)\n}\n";
exports.AddSubjectDocument = "\n    mutation addSubject($input: AddSubjectInput!) {\n  addSubject(input: $input) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    language\n    onboardingCallNeeded\n  }\n}\n";
exports.UpdatePatientTrialInfoDocument = "mutation updatePatientTrialInfo($patientId: String!, $patientTrialInfoProps: UpdatePatientTrialInfoInput!) {\n    updatePatientTrialInfo(\n      patientId: $patientId\n      patientTrialInfoProps: $patientTrialInfoProps\n    ) {\n      id\n      type\n      email\n      phone_number\n      name\n      birthdate\n      picture\n      is_active\n      cancer_type\n      diag_details\n      diag_date\n      diag_date_desc\n      coach_pref\n      learn_pref\n      is_learn_active\n      is_in_treatment\n      is_ct\n      subject_id\n      associated_site\n      study_start\n      main_goal\n      address {\n        formatted\n        street_address\n        locality\n        region\n        postal_code\n        country\n        complement\n    }\n    site_staff_emails\n    caregiver {\n        primary_caregiver\n        primary_caregiver_desc\n        primary_caregiver_email\n        name\n    }\n    gender\n    height\n    weight\n    physical_activity_level\n    medicalFoodStartDate\n  }\n}\n";
exports.UpdateSiteDocument = "mutation updateSite($siteId: ID!, $input: UpdateSiteInput!) {\n  updateSite(siteId: $siteId, input: $input) {\n    id\n    name\n    abbreviation\n    siteTrials {\n      site_identification\n      status\n      trial {\n        id\n        protocol_abbreviation\n        protocol_number\n        status\n        description\n      }\n    }\n  }\n}";
exports.SaveSubjectDraftDocument = "mutation saveSubjectDraft($input: SaveSubjectDraftInput!) {\n  saveSubjectDraft(input: $input) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      primary_caregiver_email\n      name\n    }\n    questions\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n";
exports.AddGroupDocument = "mutation addGroup($newGroup: GroupInput!) {\n    addGroup(newGroup: $newGroup) {\n      id\n      name\n      __typename\n  }\n}\n";
exports.SetGroupsToUserDocument = "mutation setGroupsToUser($userId: String!, $groupIdsToAdd: [String!] = [], $groupIdsToRemove: [String!] = []) {\n    setGroupsToUser(\n      userId: $userId\n      groupIdsToAdd: $groupIdsToAdd\n      groupIdsToRemove: $groupIdsToRemove\n    ) {\n      id\n      type\n      name\n      __typename\n  }\n}\n";
exports.AnswerSurveyDocument = "mutation answerSurvey($instanceId: String!, $answers: [SurveyQuestionAnswer!]!) {\n    answerSurvey(instanceId: $instanceId, answers: $answers)\n}\n";
exports.ListSurveyInstancesDocument = "\nquery listSurveyInstances($patientId: String!, $first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n    surveyInstances(\n      patientId: $patientId\n      first: $first\n      after: $after\n      filterBy: $filterBy\n      sortBy: $sortBy\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          surveyId\n          name\n          due_at\n          status\n          completed_at\n          questions {\n            id\n            type\n            title\n            helpText\n            placeholderText\n            maxLength\n            required\n            possibleAnswers\n            answer\n            __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    pageInfo {\n        hasNextPage\n        endCursor\n        __typename\n    }\n    totalNumberFound\n    __typename\n  }\n}\n";
exports.DeleteGroupsDocument = "mutation deleteGroups($ids: [ID!]!) {\n    deleteGroups(ids: $ids)\n}\n";
exports.AddNotesTemplateDocument = "mutation addNotesTemplate($template: NotesTemplateInput!) {\n    createNotesTemplate(template: $template) {\n      id\n      name\n      created_at\n      questions {\n        id\n        type\n        title\n        helpText\n        answer\n    }\n  }\n}\n";
exports.UpdatePatientInformationDocument = "mutation updateProfile($props: MeInput!) {\n  me(myProps: $props) {\n    id\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    cancer_type\n    diag_details\n    diag_date\n    diag_date_desc\n    is_learn_active\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n      __typename\n  }\n  current_treatment\n  oncologist_name\n  facility {\n      name\n      city\n      state\n      __typename\n  }\n  caregiver {\n      primary_caregiver\n      primary_caregiver_desc\n      name\n      __typename\n  }\n  cheerleader {\n      primary_cheerleader\n      primary_cheerleader_desc\n      name\n      __typename\n  }\n  motivation\n  medication\n  other_medical_conditions\n  last_in_app_review\n  main_goal\n  default_notification_preference\n  __typename\n}\n}";
exports.AnswerDataSurveyBhbDocument = "mutation answerDataSurveyBhb($bhbData: BhbDataSurveyInput!) {\n    answerDataSurveyBhb(bhbData: $bhbData) {\n      bhb_value\n      instance_id\n      measured_at\n      timezone\n  }\n}\n";
exports.GetPatientBhbAnswersDocument = "query getPatientBhbAnswers($patientId: ID!, $first: Int = 10, $after: String, $sortBy: SortBy) {\n    getPatientBhbAnswers(\n      patientId: $patientId\n      first: $first\n      after: $after\n      sortBy: $sortBy\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          bhb_value\n          measured_at\n          situation\n          timezone\n          instance_type\n          status\n          due_at\n          completion_time\n      }\n\n    }\n    pageInfo {\n        hasNextPage\n        endCursor\n    }\n    totalNumberFound\n  }\n}";
exports.CreateFoodProgramUserDocument = "mutation createFoodProgramUser($patientId: ID!, $input: FoodProgramUserInputV2!) {\n    createFoodProgramUserV2(patientId: $patientId, input: $input) {\n      id\n      patient {\n        id\n        name\n        subject_id\n    }\n    dietPlan {\n        id\n        name\n    }\n    status\n    deliverySettings\n    userDeliveryMenus {\n        id\n        snacksPerDay\n        sachetsPerDay\n        mealsPerDay\n        weekdaysOnDiet\n        dietPlanDeliveryMenu {\n          id\n          name\n          description\n          sequenceNumber\n          deliveryDayOfWeek\n      }\n    }\n  }\n}\n";
exports.AddCtPresignupDocument = "mutation addCtPresignup($input: CtPresignupInputV2!) {\n    addCtPresignupV2(input: $input) {\n      id\n      email\n      subjectId\n      associatedSite\n      studyStart\n      courseId\n      dietPlanId\n      snacksPerDay\n      sachetsPerDay\n      weekdaysOnDiet\n      group {\n        id\n        name\n    }\n  }\n}\n";
exports.GetPatientsBySiteDocument = "query getPatientsBySite($siteId: String!, $first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n    patientsBySite(\n      siteId: $siteId\n      first: $first\n      after: $after\n      filterBy: $filterBy\n      sortBy: $sortBy\n    ) {\n      edges {\n        node {\n          id\n          name\n          subject_id\n          picture\n          study_start\n          subjectTrialStatus\n          coach {\n            id\n            name\n            email\n            phone_number\n        }\n      }\n    }\n    pageInfo {\n        hasNextPage\n        endCursor\n    }\n    totalNumberFound\n  }\n}\n";
exports.GetPatientsByTrialDocument = "query getPatientsByTrial($trialId: String!, $first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n    patientsByTrial(\n      trialId: $trialId\n      first: $first\n      after: $after\n      filterBy: $filterBy\n      sortBy: $sortBy\n    ) {\n      edges {\n        node {\n          id\n          name\n          subject_id\n          picture\n          study_start\n          subjectTrialStatus\n          associated_site\n          site {\n            id\n            name\n            site_identification\n            address {\n              formatted\n              street_address\n              locality\n              region\n              postal_code\n              country\n              complement\n          }\n          status\n        }\n        coach {\n            id\n            name\n            email\n            phone_number\n        }\n      }\n    }\n    pageInfo {\n        hasNextPage\n        endCursor\n    }\n    totalNumberFound\n  }\n}\n";
exports.GetFoodProgramUserDocument = "\n    query getFoodProgramUser($patientId: ID!) {\n  foodProgramUserByPatientIdV2(patientId: $patientId) {\n    id\n    patient {\n      id\n      name\n      subject_id\n    }\n    dietPlan {\n      id\n      name\n    }\n    status\n    plannedPauses {\n      startDate\n      endDate\n    }\n    deliverySettings\n    userDeliveryMenus {\n      id\n      snacksPerDay\n      sachetsPerDay\n      mealsPerDay\n      weekdaysOnDiet\n      dietPlanDeliveryMenu {\n        id\n        name\n        description\n        sequenceNumber\n        deliveryDayOfWeek\n        quantity\n      }\n    }\n  }\n}";
exports.EnableFeatureFlagDocument = "\n  mutation enableFeatureFlag($name: String!, $type: FeatureFlagType!) {\n    enableFeatureFlag(name: $name, type: $type) {\n      name\n      enabled\n      type\n    }\n  }\n";
exports.PublishContentfulEntryDocument = "\n  mutation publishContentfulEntry($entryId: String!, $contentfulEnv: String) {\n    publishContentfulEntry(entryId: $entryId, contentfulEnv: $contentfulEnv)\n  }\n";
exports.AddMenuDocument = "\n  mutation addMenu($input: MenuInput!) {\n    addMenu(input: $input) {\n      id\n      name\n      breakfast\n      lunch\n      dinner\n      snacks\n      sides\n    }\n  }\n";
exports.AddDietPlanDocument = "\n  mutation addDietPlan($input: DietPlanInputV2!) {\n    addDietPlanV2(input: $input) {\n      id\n      name\n      deliveries {\n        id\n        name\n        description\n        sequenceNumber\n        deliveryDayOfWeek\n        quantity\n      }\n      startDate\n      createdAt\n      updatedAt\n    }\n  }\n";
exports.ListDietPlansV2Document = "\nquery listDietPlansV2($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  dietPlansV2(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        deliveries {\n          id\n          name\n          description\n          sequenceNumber\n          deliveryDayOfWeek\n          quantity\n        }\n        startDate\n        createdAt\n        updatedAt\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}";
exports.AddFoodOpsUserDocument = "\n  mutation addFoodOpsUser($newUser: AddFoodOpsUserInput!) {\n    addFoodOpsUser(newUser: $newUser) {\n      id\n      type\n      email\n      phone_number\n      name\n      birthdate\n      picture\n      is_active\n      address {\n        formatted\n        street_address\n        locality\n        region\n        postal_code\n        country\n        complement\n      }\n      groups\n    }\n  }\n";
exports.SetFoodOpsUserPasswordDocument = "\n  mutation setFoodOpsUserPassword($username: String!, $password: String!) {\n    setFoodOpsUserPassword(username: $username, password: $password)\n  }\n";
