import React from 'react';

import { ForgotPassword } from '../components';
import LoginBase, { LoginBaseProps } from '../components/LoginBase';

export interface ForgotPasswordPageProps extends LoginBaseProps {}

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({
  ...loginBaseProps
}) => {
  return (
    <LoginBase {...loginBaseProps}>
      <ForgotPassword />
    </LoginBase>
  );
};
