import React, { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';

import { Typography, TypographyProps } from '../Typography/Typography';

export interface InputHelperTextProps {
  i18nKey?: TypographyProps['i18nKey'];
  i18nParams?: TypographyProps['i18nParams'];
  sx?: TypographyProps['sx'];
  v2?: boolean;
}

export const InputHelperText: React.FC<
  PropsWithChildren<InputHelperTextProps>
> = ({ i18nKey, i18nParams, v2 = false, sx, children }) => {
  const theme = useTheme();
  /************ V2 updates *********************/

  const variant = v2 ? 'body2' : 'body1';
  const style = v2
    ? { color: theme.palette.text.hint, marginTop: 0.5, marginX: 0 }
    : { color: theme.palette.text.secondary, marginBottom: 2, marginTop: 1 };

  /*********************************************/

  return (
    <Typography
      data-testid="HELPER_TEXT"
      i18nKey={i18nKey}
      i18nParams={i18nParams}
      variant={variant}
      sx={{
        whiteSpace: 'break-spaces',
        ...style,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
