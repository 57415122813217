import React, { useEffect, useState } from 'react';
import {
  Box,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem,
  useTheme,
  Checkbox,
  FormHelperText,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from '@fdha/common-hooks';

import { VariantType } from '../../types/mui';
import { Icon } from '../Icon/Icon';
import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { Typography } from '../Typography/Typography';

type SelectValueType = string | number | readonly string[] | undefined;
export interface SelectOption {
  label: string;
  i18nKey?: string;
  value: SelectValueType;
}

export type SelectProps = MuiSelectProps & {
  title?: string;
  options: SelectOption[];
  titleVariant?: VariantType;
  selectType?: 'common' | 'checkbox';
  showEmptyValue?: boolean;
  useFirstOptionAsDefault?: boolean;
  helperText?: React.ReactNode;
  showPlaceholderOnly?: boolean;
  customRender?: (option: SelectValueType) => JSX.Element;
  v2?: boolean;
  showSkeleton?: boolean;
  loading?: boolean;
  testId?: string;
  i18nKeyTitle?: string;
  selectAll?: boolean;
};

export const Select: React.FC<SelectProps> = (props) => {
  const { translate } = useTranslation();
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const {
    title,
    titleVariant,
    selectType = 'common',
    showEmptyValue,
    useFirstOptionAsDefault,
    options,
    helperText,
    customRender,
    v2,
    showSkeleton = false,
    loading = false,
    testId,
    i18nKeyTitle,
    selectAll,
    showPlaceholderOnly,
    ...muiProps
  } = props;
  const theme = useTheme();

  const allValues = options.map((option) => option.value);

  useEffect(() => {
    if (selectAll && Array.isArray(muiProps.value)) {
      const isAllSelected = !!(
        muiProps.value.length === allValues.length && allValues.length
      );
      setSelectAllChecked(isAllSelected);
    }
  }, [selectAll, muiProps.value, allValues.length]);

  if (useFirstOptionAsDefault) {
    muiProps.defaultValue = options[0]?.value;
  }

  const renderOption = (value: SelectValueType) => {
    if (customRender) {
      return customRender(value);
    }

    if (Array.isArray(value)) {
      const selectedOption = options.filter((option) =>
        value.includes(option.value)
      );
      return (
        <>
          {selectedOption
            .map((d) => (d.i18nKey ? translate(d.i18nKey, d.label) : d.label))
            .join(', ')}
        </>
      );
    }

    const selectedOption = options.find((option) => option.value === value);
    return (
      <Typography i18nKey={selectedOption?.i18nKey} variant="inherit">
        {selectedOption?.label}
      </Typography>
    );
  };

  const renderValue = (value: SelectValueType) => {
    const isArrayEmpty = Array.isArray(value) && value.length === 0;
    if (!value && showEmptyValue) {
      return 'None';
    } else if (value && !isArrayEmpty && !showPlaceholderOnly) {
      return renderOption(value);
    } else if (props.placeholder) {
      return (
        <Typography sx={{ opacity: 0.42, fontSize: '1rem' }}>
          {props.placeholder}
        </Typography>
      );
    }
  };

  const enableCheckbox = (
    value: string | number | readonly string[] | undefined
  ): boolean => {
    const arrayValue: Array<string> = muiProps.value as Array<string>;
    return arrayValue?.indexOf(String(value)) > -1;
  };

  const showCheckbox = (optionIndex: number) =>
    selectType === 'checkbox' && (!useFirstOptionAsDefault || optionIndex > 0);

  const id = props.title
    ? `${props.title.toUpperCase().replace(/ /g, '_')}_SELECT`
    : 'SELECT';

  const handleSelectAll = () => {
    muiProps.onChange?.(
      {
        target: {
          value: selectAllChecked ? [] : allValues,
        },
      } as SelectChangeEvent<unknown>,
      null
    );
    setSelectAllChecked(!selectAllChecked);
  };

  /************ V2 updates *********************/

  const inputSize = v2 ? 'small' : 'medium';
  const titleMargin = v2 ? 1 : '6px';
  const titleFontWeight = v2 ? 'bold' : 'medium';
  const titleVariantDefault = v2 ? 'body1' : 'subtitle2';
  const hintVariant = v2 ? 'body2' : 'body1';
  const extraInfoStyle = v2
    ? { color: theme.palette.text.hint, marginTop: 0.5, marginX: 0 }
    : { color: theme.palette.text.secondary, marginBottom: 2, marginTop: 1 };

  const renderErrorMessageContent = () => {
    return (
      <>
        {v2 && (
          <Icon
            name="alert-circle-outline"
            fill={theme.palette.error.main}
            size="large"
            sx={{ marginRight: 0.5 }}
          />
        )}
        {helperText}
      </>
    );
  };
  /*********************************************/

  const renderError = () => {
    return (
      <FormHelperText
        data-testid="HELPER_TEXT_ERROR"
        sx={{
          display: 'flex',
          alignItems: 'center',
          lineHeight: 'normal',
          whiteSpace: 'break-spaces',
          fontSize: 14,
          ...extraInfoStyle,
          color: theme.palette.error.main,
        }}
      >
        {renderErrorMessageContent()}
      </FormHelperText>
    );
  };

  const renderHint = () => {
    return (
      <Typography
        variant={hintVariant}
        sx={{
          whiteSpace: 'break-spaces',
          ...extraInfoStyle,
        }}
      >
        {helperText}
      </Typography>
    );
  };

  return (
    <SkeletonWrapper isLoading={showSkeleton}>
      <Box
        display="flex"
        flexDirection="column"
        width={props.fullWidth ? '100%' : undefined}
        data-testid={testId || id}
      >
        {props.title && (
          <Typography
            i18nKey={i18nKeyTitle}
            variant={titleVariant || titleVariantDefault}
            mb={titleMargin}
            fontWeight={titleFontWeight}
          >
            {props.title}
          </Typography>
        )}
        <MuiSelect
          renderValue={(value) => renderValue(value as SelectValueType)}
          size={inputSize}
          {...muiProps}
          data-testid="SELECT_ITEMS"
          displayEmpty
          endAdornment={
            <React.Fragment>
              {loading ? (
                <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
              ) : null}
              {muiProps.endAdornment}
            </React.Fragment>
          }
        >
          {showEmptyValue && (
            <MenuItem value="" data-testid="SELECT_ITEM">
              <em>None</em>
            </MenuItem>
          )}
          {selectAll && (
            <MenuItem onClick={handleSelectAll}>
              <Checkbox checked={selectAllChecked} />
              Select all
            </MenuItem>
          )}
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value} data-testid="SELECT_ITEM">
              {showCheckbox(i) && (
                <Checkbox checked={enableCheckbox(option.value)} />
              )}
              <Typography i18nKey={option?.i18nKey}>
                {renderOption(option.value)}
              </Typography>
            </MenuItem>
          ))}
        </MuiSelect>
        {props.error && helperText && renderError()}
        {!props.error && helperText && renderHint()}
      </Box>
    </SkeletonWrapper>
  );
};
