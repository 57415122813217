import { Box, Card, CardActionArea, Stack, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import {
  DeliveryShipmentStatus as TrackingStatus,
  getPriorityShipmentStatus,
} from '@fdha/common-utils';

import { formatUTCDate, MenuType } from '../../utils';
import { ShipmentLabel } from '../../types/index';
import { Typography } from '../Typography/Typography';
import { IconMenu } from '../Menu/IconMenu';

import { DeliveryStatus } from './DeliveryStatus';

export interface DeliveryCardProps {
  buttonLabel: string;
  deliveryId: string;
  deliveryDate: string;
  openChanges: string;
  status: 'open' | 'closed';
  shipmentLabels?: ShipmentLabel[] | null;
  showShipmentStatus?: boolean;
  isCanceled: boolean;
  isOwner: boolean;
  showIconMenu?: boolean;
  weekOfCycle?: string | null;
  isDelayed?: boolean;
  onClick: () => void;
  menuType: MenuType;
  labelHelper?: React.ReactNode;
}

type IconMenuProps = {
  showIconMenu: true;
  onEdit: (id: string) => void;
  onCancel: (id: string) => void;
  onReactivate: (id: string) => void;
};

type IconMenuPropsFalse = {
  showIconMenu?: false;
};

type DeliveryCardPropsWithIconMenu = DeliveryCardProps &
  (IconMenuProps | IconMenuPropsFalse);

const DeliveryCard: FC<DeliveryCardPropsWithIconMenu> = ({
  buttonLabel,
  deliveryId,
  deliveryDate,
  openChanges,
  status,
  shipmentLabels,
  showShipmentStatus,
  isCanceled,
  isOwner,
  weekOfCycle,
  isDelayed,
  onClick,
  menuType,
  labelHelper,
  ...props
}) => {
  const theme = useTheme();

  const { showIconMenu } = props;

  const editMenuOption = {
    label: 'Edit order',
    testId: 'EDIT_MENU_OPTION',
    icon: 'edit-outline',
    disabled: status === 'closed' || isCanceled ? true : false,
    handleClick: () => showIconMenu && props.onEdit(deliveryId),
  };
  const cancelMenuOption = {
    label: 'Cancel delivery',
    testId: 'CANCEL_MENU_OPTION',
    icon: 'alert-circle-outline',
    handleClick: () => showIconMenu && props.onCancel(deliveryId),
  };
  const reactivateMenuOption = {
    label: 'Reactivate delivery',
    testId: 'REACTIVATE_MENU_OPTION',
    icon: 'alert-circle-outline',
    handleClick: () => showIconMenu && props.onReactivate(deliveryId),
  };

  const deliveryStatus = isCanceled
    ? 'Delivery canceled for'
    : 'Delivery expected for';

  const deliveryShipmentStatus = useMemo(() => {
    if (!showShipmentStatus) {
      return;
    }
    if (!shipmentLabels?.length) {
      return TrackingStatus.BeingPrepared;
    }
    const statuses = shipmentLabels.map((label) => label.trackingStatus);

    return getPriorityShipmentStatus(statuses);
  }, [shipmentLabels, showShipmentStatus]);

  return (
    <Card
      data-testid={`DELIVERY_CARD_${deliveryId}`}
      elevation={5}
      sx={{ boxShadow: theme.shadows[2], mb: 2 }}
    >
      <Box
        padding={1.5}
        display="flex"
        justifyContent="space-between"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Box>
          <Typography
            variant="body1"
            color={theme.palette.text.secondary}
            fontSize={10}
            fontWeight={500}
          >
            {deliveryStatus}
          </Typography>
          <Stack direction="row" paddingY={1} spacing={1}>
            <Typography fontWeight={500}>
              {formatUTCDate(deliveryDate, 'monthWeekDay')}
            </Typography>
            {labelHelper}
            <Typography
              variant="overline"
              color={theme.palette.text.hint}
              ml={1}
              lineHeight={2}
            >
              {menuType === MenuType.QuickShip
                ? 'QUICK SHIP'
                : `WEEK ${weekOfCycle}`}
            </Typography>
          </Stack>
          <DeliveryStatus
            openChangesDate={openChanges}
            status={status}
            isOwner={isOwner}
            trackingStatus={deliveryShipmentStatus}
            isCanceled={isCanceled}
          />
        </Box>
        {showIconMenu && (
          <IconMenu
            items={[
              editMenuOption,
              isCanceled === false ? cancelMenuOption : reactivateMenuOption,
            ]}
          />
        )}
      </Box>
      {isDelayed && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{ backgroundColor: theme.palette.warning.light }}
          py={0.5}
        >
          <Typography variant="caption">
            This delivery might be delayed
          </Typography>
        </Box>
      )}
      <CardActionArea
        onClick={onClick}
        sx={{ padding: 1, textAlign: 'center' }}
      >
        <Typography variant="body2" color={theme.palette.info.main}>
          {buttonLabel}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default DeliveryCard;
