import React, { FC, PropsWithChildren } from 'react';
import { Box, styled, Paper as MuiPaper } from '@mui/material';
import { BasePageV2, BasePageV2Header } from '@fdha/web-ui-library';

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  width: '384px',
  padding: theme.spacing(4),
  border: 'none',
  boxShadow: theme.shadows[2],
}));

export interface LoginBaseProps {
  logoUrl?: string;
}

const LoginBase: FC<PropsWithChildren<LoginBaseProps>> = ({
  children,
  logoUrl,
}) => {
  return (
    <BasePageV2 type="default" verticallyCentered sx={{ height: '100vh' }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <BasePageV2Header logoUrl={logoUrl} showBack={false} />
        <Paper>{children}</Paper>
      </Box>
    </BasePageV2>
  );
};

export default LoginBase;
