import { signOut } from '@aws-amplify/auth';
import { Button, Typography, useDialog } from '@fdha/web-ui-library';
import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ProfileChangePasswordModal = () => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const theme = useTheme();

  const [radioChoice, setRadioChoice] = useState<string>('keepLoggedIn');

  return (
    <FormControl>
      <Typography
        color={theme.palette.text.secondary}
        i18nKey="profile:changePassword.dialog.content"
      >
        To make sure your account is secure, we can log you out of all devices.
        You can log back in with your new password.
      </Typography>
      <RadioGroup
        name="logOutOption"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRadioChoice(event.target.value);
        }}
        defaultValue="keepLoggedIn"
      >
        <FormControlLabel
          value="logOut"
          control={<Radio size="small" />}
          label={
            <Typography
              i18nKey="profile:changePassword.dialog.radioOption"
              variant="inherit"
            >
              Log me out of all devices (including the current session)
            </Typography>
          }
          data-testid="LOG_OUT_RADIO"
        />
        <FormControlLabel
          value="keepLoggedIn"
          control={<Radio size="small" />}
          label={
            <Typography i18nKey="login:keepMeLoggedIn" variant="inherit">
              Keep me logged in
            </Typography>
          }
          data-testid="KEEP_LOGGED_IN_RADIO"
        />
      </RadioGroup>
      <Button
        type="submit"
        variant="contained"
        sx={{ alignSelf: 'flex-end', width: '121px' }}
        onClick={async () => {
          if (radioChoice === 'logOut') {
            await signOut({ global: true });
            navigate('/');
          }
          closeDialog();
        }}
        data-testid="SUBMIT_NEW_PASSWORD_BUTTON"
        i18nKey="common:button:submit"
      >
        Submit
      </Button>
    </FormControl>
  );
};
