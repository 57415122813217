import React, { PropsWithChildren } from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';

import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';

export interface SkeletonWrapperProps {
  isLoading: boolean;
  fullWidth?: boolean;
  variant?: SkeletonProps['variant'];
}

export const SkeletonWrapper: React.FC<
  PropsWithChildren<SkeletonWrapperProps>
> = ({ isLoading, fullWidth = true, variant = 'rectangular', children }) => {
  return (
    <ConditionalWrapper
      showWrapper={!!isLoading}
      wrapper={(children) => (
        <Skeleton variant={variant} width={fullWidth ? '100%' : undefined}>
          {children}
        </Skeleton>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};
