"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNextDay = exports.convertDateToLocalUtc = exports.WeekDayFlag = exports.WeekDay = void 0;
var date_fns_1 = require("date-fns");
var date_fns_tz_1 = require("date-fns-tz");
var WeekDay;
(function (WeekDay) {
    WeekDay[WeekDay["Sun"] = 0] = "Sun";
    WeekDay[WeekDay["Mon"] = 1] = "Mon";
    WeekDay[WeekDay["Tue"] = 2] = "Tue";
    WeekDay[WeekDay["Wed"] = 3] = "Wed";
    WeekDay[WeekDay["Thu"] = 4] = "Thu";
    WeekDay[WeekDay["Fri"] = 5] = "Fri";
    WeekDay[WeekDay["Sat"] = 6] = "Sat";
})(WeekDay || (exports.WeekDay = WeekDay = {}));
var WeekDayFlag;
(function (WeekDayFlag) {
    WeekDayFlag[WeekDayFlag["Sun"] = 1] = "Sun";
    WeekDayFlag[WeekDayFlag["Mon"] = 2] = "Mon";
    WeekDayFlag[WeekDayFlag["Tue"] = 4] = "Tue";
    WeekDayFlag[WeekDayFlag["Wed"] = 8] = "Wed";
    WeekDayFlag[WeekDayFlag["Thu"] = 16] = "Thu";
    WeekDayFlag[WeekDayFlag["Fri"] = 32] = "Fri";
    WeekDayFlag[WeekDayFlag["Sat"] = 64] = "Sat";
})(WeekDayFlag || (exports.WeekDayFlag = WeekDayFlag = {}));
/**
 * Convert date to local time zone
 * @param date Date to convert
 * @returns Date in local time zone
 */
var convertDateToLocalUtc = function (date) {
    return (0, date_fns_tz_1.utcToZonedTime)(date, 'UTC');
};
exports.convertDateToLocalUtc = convertDateToLocalUtc;
/**
 * Return the next day in ISO format
 * @returns String in ISO format
 */
var getNextDay = function () {
    return (0, date_fns_1.formatISO)((0, date_fns_1.addDays)((0, date_fns_1.startOfToday)(), 1));
};
exports.getNextDay = getNextDay;
