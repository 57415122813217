import React from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  RadioProps as MuiRadioProps,
  Radio,
  Paper as MuiPaper,
  FormControlLabel,
  styled,
  useTheme,
  Box,
  Divider,
} from '@mui/material';

import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { Icon, IconProps } from '../Icon/Icon';
import { InputError } from '../InputError/InputError';
import { InputTitle } from '../InputTitle/InputTitle';
import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { InputHelperText } from '../InputHelperText/InputHelperText';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '2px 20px',
  marginBottom: '12px',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
}));

export interface RadioOption {
  label: string;
  value: string;
  icon?: IconProps;
  helperText?: string;
}

export type RadioProps = Omit<MuiRadioProps, 'value' | 'onChange'>;

export type RadioGroupProps = Omit<MuiRadioGroupProps, 'value' | 'onChange'> & {
  title?: string;
  value: string;
  options: RadioOption[];
  onChange?: (value: string) => void;
  error?: React.ReactNode;
  radioProps?: RadioProps;
  showSkeleton?: boolean;
  v2?: boolean;
  showDivider?: boolean;
  type?: 'default' | 'border' | 'background';
  renderContent?: (option: RadioOption) => React.ReactNode;
};

export const RadioGroup = ({
  title,
  value,
  options,
  onChange,
  error,
  renderContent,
  v2 = false,
  type = 'default',
  radioProps = {},
  showSkeleton = false,
  showDivider = false,
  ...muiRadioGroupProps
}: RadioGroupProps) => {
  const theme = useTheme();

  const radioType = type || (v2 ? 'default' : 'background');
  const showBackground = radioType === 'background';
  const showBorder = radioType === 'border';
  const showWrapper = showBorder || showBackground;

  const handleChange = (event: React.SyntheticEvent<Element, Event>) => {
    const newValue = (event.target as HTMLInputElement).value;
    onChange && onChange(newValue);
  };

  /************ V2 updates *********************/
  const radioStyle = v2 ? undefined : { mr: '12px' };

  const formLabelStyle = (selected: boolean) => {
    switch (radioType) {
      case 'background':
        return {
          color: selected ? theme.palette.primary.contrastText : undefined,
          '& .MuiFormControlLabel-label.Mui-disabled': {
            color: selected ? theme.palette.primary.contrastText : undefined,
          },
          '& .MuiRadio-root.Mui-checked ': {
            color: selected ? theme.palette.primary.contrastText : undefined,
          },
          width: '100%',
          mr: 0,
        };
      default: {
        return undefined;
      }
    }
  };

  /*********************************************/

  return (
    <SkeletonWrapper isLoading={showSkeleton}>
      <Box display="flex" flexDirection="column">
        {title && <InputTitle title={title} v2={v2} />}
        <MuiRadioGroup
          value={value}
          {...muiRadioGroupProps}
          sx={{ columnGap: 1, rowGap: 1, ...muiRadioGroupProps.sx }}
        >
          {options.map((option, index) => {
            const selected = option.value === value;

            return (
              <ConditionalWrapper
                key={option.value}
                showWrapper={showWrapper}
                wrapper={(children) => (
                  <Paper
                    sx={{
                      backgroundColor:
                        selected && showBackground
                          ? theme.palette.primary.light
                          : undefined,
                      borderColor:
                        selected && showBorder
                          ? theme.palette.text.secondary
                          : undefined,
                    }}
                  >
                    {children}
                  </Paper>
                )}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  data-testid={`RADIO_OPTION_${option.label}`}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      data-testid={
                        option.value === value ? 'SELECTED' : 'NOT_SELECTED'
                      }
                      value={option.value}
                      control={<Radio sx={radioStyle} {...radioProps} />}
                      label={option.label}
                      onChange={handleChange}
                      sx={{ ...formLabelStyle(selected) }}
                    />
                    {option.icon && <Icon {...option.icon} />}
                  </Box>
                  {option.helperText && (
                    <InputHelperText v2={v2} sx={{ minWidth: 'fit-content' }}>
                      {option.helperText}
                    </InputHelperText>
                  )}
                  {renderContent && renderContent(option)}
                </Box>
                {showDivider && options.length - 1 !== index && (
                  <Divider sx={{ my: 1 }} />
                )}
              </ConditionalWrapper>
            );
          })}
        </MuiRadioGroup>
        {error && <InputError error={error} v2={v2} />}
      </Box>
    </SkeletonWrapper>
  );
};
