import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  CustomField,
  GetAutomationItemsDocument,
  GetPatientUserDocument,
  UpdatePatientTrialInfoInput,
  useGetCustomFieldsValidationQuery,
  useListSitesQuery,
  useListTrialsFromSiteQuery,
  useUpdatePatientTrialInfoMutation,
} from '@fdha/graphql-api-admin';
import { Grid, InputAdornment, Stack } from '@mui/material';
import {
  Button,
  MaskedTextField,
  MultiTextField,
  Select,
  TextField,
  addDateValidation,
  getCustomFieldsValidationSchema,
  requiredMessage,
  useDialog,
  useSnackbar,
  DatePicker,
  formatUTCDate,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import { parseBackendError, getFormikError } from '@fdha/common-utils';

import { ViewEditTrialInformationProps } from './TrialInformation';

const NUMBER_OF_DATA = 10000;

interface TrialInformationSchema {
  site: string;
  trial: string;
  studyStart: Date | null;
  subjectId: string;
  treatmentStartDate: Date | null;
  siteStaffEmails: string[];
}

const basicValidationSchema = Yup.object().shape({
  studyStart: addDateValidation(undefined, undefined, false, true),
  siteStaffEmails: Yup.array().of(
    Yup.string().email('Please type a valid email format')
  ),
});

const adminValidationSchema = Yup.object().shape({
  site: Yup.string().required(requiredMessage),
  trial: Yup.string().required(requiredMessage),
  subjectId: Yup.string().trim().required(requiredMessage),
});

const EditModeTrialInformation: FC<ViewEditTrialInformationProps> = ({
  isAdmin,
  patientUser,
  handleEditMode,
}) => {
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();

  const [trialId, setTrialId] = useState(patientUser?.trial?.id ?? '');

  const [updatePatientTrialInfo] = useUpdatePatientTrialInfoMutation({
    awaitRefetchQueries: true,
    refetchQueries: [GetPatientUserDocument, GetAutomationItemsDocument],
  });

  const { data: sitesData, loading: loadingSites } = useListSitesQuery({
    variables: { first: NUMBER_OF_DATA },
    skip: !isAdmin,
    fetchPolicy: 'cache-and-network',
  });

  const { data: customFieldsData } = useGetCustomFieldsValidationQuery({
    variables: { trialId },
    skip: !isAdmin,
  });

  const sites = sitesData?.sites.edges.map(({ node }) => node);
  const sitesOptions = useMemo(() => {
    if (!isAdmin && patientUser?.site) {
      return [{ label: patientUser?.site?.name, value: patientUser?.site?.id }];
    }
    return (
      sites?.map((site) => ({
        label: site.name,
        value: site.id,
      })) || []
    );
  }, [isAdmin, patientUser?.site, sites]);

  const siteIdentification = useMemo(() => {
    return sites
      ?.find((site) => site.id === patientUser?.site?.id)
      ?.siteTrials?.find(
        (siteTrial) => siteTrial.trial?.id === patientUser?.trial?.id
      )?.site_identification;
  }, [sites, patientUser?.site?.id, patientUser?.trial?.id]);

  const getSubjectIdPrefix = (
    protocolAbbreviation?: string,
    siteIdentification?: string
  ) => {
    if (!protocolAbbreviation || !siteIdentification) {
      return '';
    }
    return `${protocolAbbreviation}-${siteIdentification}-`;
  };

  const getSubjectIdWithoutPrefix = useCallback(
    (
      subjectId?: string,
      protocolAbbreviation?: string,
      siteIdentification?: string
    ) => {
      const subjectIdPrefix = getSubjectIdPrefix(
        protocolAbbreviation,
        siteIdentification
      );
      const initialSubjectId = subjectId?.replace(subjectIdPrefix, '');

      return initialSubjectId?.length === 3 &&
        !isNaN(parseInt(initialSubjectId))
        ? initialSubjectId
        : '';
    },
    []
  );

  const initialValues: TrialInformationSchema = useMemo(() => {
    const studyStartDateUTC = formatUTCDate(patientUser?.study_start);
    const treatmentStartDateUTC = formatUTCDate(
      patientUser?.treatmentStartDate
    );
    const data: TrialInformationSchema = {
      site: patientUser?.site?.id || '',
      trial: patientUser?.trial?.id || '',
      studyStart: studyStartDateUTC ? new Date(studyStartDateUTC) : null,
      subjectId: getSubjectIdWithoutPrefix(
        patientUser?.subject_id ?? undefined,
        patientUser?.trial?.protocol_abbreviation ?? undefined,
        siteIdentification
      ),
      siteStaffEmails: patientUser?.site_staff_emails || [],
      treatmentStartDate: treatmentStartDateUTC
        ? new Date(treatmentStartDateUTC)
        : null,
    };

    return data;
  }, [
    getSubjectIdWithoutPrefix,
    patientUser?.treatmentStartDate,
    patientUser?.site?.id,
    siteIdentification,
    patientUser?.site_staff_emails,
    patientUser?.study_start,
    patientUser?.subject_id,
    patientUser?.trial?.id,
    patientUser?.trial?.protocol_abbreviation,
  ]);

  const handleSave = async (values: TrialInformationSchema) => {
    const nonAdminInput: UpdatePatientTrialInfoInput = {
      startDate: values.studyStart || null,
      email: values.siteStaffEmails,
    };

    const adminInput: UpdatePatientTrialInfoInput = {
      ...nonAdminInput,
      siteId: values.site,
      trialId: values.trial,
      subjectId: values.subjectId
        ? `${subjectIdPrefix}${values.subjectId}`
        : undefined,
      treatmentStartDate: values.treatmentStartDate || null,
    };
    try {
      await updatePatientTrialInfo({
        variables: {
          patientId: patientUser?.id || '',
          patientTrialInfoProps: isAdmin ? adminInput : nonAdminInput,
        },
      });

      handleEditMode(false);
      showSnackbar({
        message: 'Trial information has been submitted.',
        severity: 'success',
      });
    } catch (error) {
      setSubmitting(false);
      const message = parseBackendError(
        error,
        'Error updating patient trial information'
      );

      showSnackbar({
        message,
        severity: 'error',
      });
    }
  };

  const handleCancel = () => {
    handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const handleConfirmation = () => {
    openDialog({
      title: 'Assign site and trial?',
      content: 'Once a site and trial is assigned, it cannot be changed.',
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Save changes',
      handleCancel: () => setSubmitting(false),
      handleConfirm: async () => {
        closeDialog();
        handleSave(values);
      },
    });
  };

  const showConfirmation = !initialValues.site && !initialValues.trial;

  const getValidationSchema = (customFields?: CustomField[]) => {
    const customFieldsValidation = getCustomFieldsValidationSchema(
      false,
      customFields
    );

    return isAdmin
      ? basicValidationSchema
          .concat(adminValidationSchema)
          .concat(customFieldsValidation)
      : basicValidationSchema.concat(customFieldsValidation);
  };

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(
      customFieldsData?.customFieldsValidation
    ),
    enableReinitialize: true,
    onSubmit: showConfirmation ? handleConfirmation : handleSave,
  });

  const { data: trialsData, loading: loadingTrials } =
    useListTrialsFromSiteQuery({
      variables: { siteId: values.site },
      skip: !isAdmin,
    });

  const trialsOptions = useMemo(() => {
    if (!isAdmin && patientUser?.trial) {
      return [
        {
          label: patientUser.trial.protocol_number || '',
          value: patientUser.trial.id,
        },
      ];
    }

    return (
      trialsData?.trialsFromSite?.map((trial) => ({
        label: trial.protocol_number || '',
        value: trial.id,
      })) || []
    );
  }, [isAdmin, patientUser?.trial, trialsData?.trialsFromSite]);

  const selectedTrial = !isAdmin
    ? patientUser?.trial
    : trialsData?.trialsFromSite?.find((trial) => trial.id === values.trial);
  const selectedSite = sites?.find((site) => site.id === values.site);

  const subjectIdPrefix = useMemo(() => {
    const trial = selectedTrial?.protocol_abbreviation;
    const siteIdentification = selectedSite?.siteTrials?.find(
      (st) => st.trial?.id === selectedTrial?.id
    )?.site_identification;

    if (!trial || !siteIdentification) {
      return '';
    }

    return getSubjectIdPrefix(trial, siteIdentification);
  }, [selectedTrial, selectedSite]);

  const siteDisabled = !!initialValues.site || loadingSites || !isAdmin;
  const trialDisabled =
    !!initialValues.trial || !values.site || loadingTrials || !isAdmin;
  const subjectIdDisabled =
    (initialValues.subjectId && initialValues.site && initialValues.trial) ||
    !values.site ||
    !values.trial ||
    !isAdmin;
  const isLarcTrial = selectedTrial?.protocol_abbreviation === 'LARC';

  const getError = (name: string) => {
    return getFormikError<TrialInformationSchema>(name, errors, touched);
  };

  const getTrialValue = () => {
    const selectedTrialId = selectedTrial?.id || '';

    if (!loadingTrials && selectedTrialId !== values.trial) {
      setFieldValue('trial', selectedTrialId);
      setFieldTouched('trial', false);
      setFieldValue('subjectId', '');
      setFieldTouched('subjectId', false);
    }
    return selectedTrialId;
  };

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={5}>
        <Grid item xs={4}>
          <Select
            displayEmpty
            title="Site"
            name="site"
            placeholder="Select a site"
            disabled={siteDisabled}
            value={values.site}
            options={sitesOptions}
            loading={loadingSites}
            error={!!getError('site')}
            helperText={getError('site')}
            onChange={(event) => {
              handleChange(event);
              setTrialId('');
            }}
            onBlur={() => setFieldTouched('site')}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            displayEmpty
            title="Trial"
            name="trial"
            placeholder="Select a trial"
            disabled={trialDisabled}
            value={getTrialValue()}
            options={trialsOptions}
            loading={loadingTrials}
            error={!!getError('trial')}
            helperText={getError('trial')}
            onChange={(event) => {
              handleChange(event);
              setTrialId(event.target.value as string);
            }}
            onBlur={() => setFieldTouched('trial')}
          />
        </Grid>
        <Grid item xs={4}>
          {subjectIdDisabled ? (
            <TextField
              disabled
              title="Subject ID"
              name="subjectId"
              value={patientUser?.subject_id}
              placeholder="XXX-XX-XXX"
              onBlur={handleBlur}
              error={!!getError('subjectId')}
              helperText={getError('subjectId')}
            />
          ) : (
            <MaskedTextField
              mask="number"
              title="Subject ID"
              name="subjectId"
              maxLength={3}
              value={values.subjectId}
              error={!!getError('subjectId')}
              helperText={getError('subjectId')}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {subjectIdPrefix}
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            title={isLarcTrial ? 'Radiotherapy start date' : 'C1D1 date'}
            name="treatmentStartDate"
            value={values.treatmentStartDate}
            onChange={(value) => setFieldValue('treatmentStartDate', value)}
            onBlur={handleBlur}
            error={!!getError('treatmentStartDate')}
            helperText={getError('treatmentStartDate')}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            title="Study start date"
            name="studyStart"
            value={values.studyStart}
            onChange={(value) => setFieldValue('studyStart', value)}
            onBlur={handleBlur}
            error={!!getError('studyStart')}
            helperText={getError('studyStart')}
          />
        </Grid>
        <Grid item xs={4}>
          <MultiTextField
            title="Backup site staff emails"
            placeholder="user@email.com"
            name="siteStaffEmails"
            value={values.siteStaffEmails}
            error={!!getError('siteStaffEmails')}
            helperText={getError('siteStaffEmails')}
            onChange={(values) => setFieldValue('siteStaffEmails', values)}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
          data-testid="EDIT_MODE_TRIAL_INFORMATION_SAVE_BUTTON"
        >
          Save changes
        </Button>
      </Stack>
    </>
  );
};

export default EditModeTrialInformation;
