import React, { FC, useMemo } from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import {
  IconButton,
  LabelBox,
  formatUTCDate,
  shouldShowCustomField,
} from '@fdha/web-ui-library';
import { useGetCustomFieldsValidationQuery } from '@fdha/graphql-api-admin';

import { ViewEditTrialInformationProps } from './TrialInformation';

const tooltipMessage =
  'This item can only be edited once the patient is assigned a Trial and Site.';

const ViewModeTrialInformation: FC<ViewEditTrialInformationProps> = ({
  patientUser,
  isCsr,
  shouldDisableEdit,
  handleEditMode,
}) => {
  const { data: customFieldsData, loading: loadingCustomFields } =
    useGetCustomFieldsValidationQuery({
      variables: { trialId: patientUser?.trial?.id || '' },
    });

  const showTreatmentStartDate = useMemo(
    () =>
      shouldShowCustomField(
        'treatmentStartDate',
        customFieldsData?.customFieldsValidation,
        loadingCustomFields
      ),
    [customFieldsData?.customFieldsValidation, loadingCustomFields]
  );

  const isLarcTrial = patientUser?.trial?.protocol_abbreviation === 'LARC';

  return (
    <Box
      display="flex"
      flexDirection="row"
      data-testid="TRIAL_VIEW_MODE"
      justifyContent="space-between"
    >
      <Box marginBottom={3}>
        <Grid container columnSpacing={3} rowSpacing={0}>
          <LabelBox size={4} label="Site" value={patientUser?.site?.name} />
          <LabelBox
            size={4}
            label="Trial"
            value={patientUser?.trial?.protocol_number}
          />
          <LabelBox
            size={4}
            label="Subject ID"
            value={patientUser?.subject_id}
          />
          {showTreatmentStartDate && (
            <LabelBox
              size={4}
              label={isLarcTrial ? 'Radiotherapy start date' : 'C1D1 date'}
              value={formatUTCDate(patientUser?.treatmentStartDate)}
            />
          )}
          <LabelBox
            size={4}
            label="Study start date"
            value={formatUTCDate(patientUser?.study_start)}
          />
          {!isCsr && (
            <LabelBox
              size={4}
              label="Backup site staff emails"
              value={patientUser?.site_staff_emails?.join(', ')}
            />
          )}
        </Grid>
      </Box>
      {!isCsr && (
        <Tooltip title={shouldDisableEdit ? tooltipMessage : ''}>
          <span>
            <IconButton
              disabled={shouldDisableEdit}
              onClick={() => handleEditMode(true)}
              data-testid="EDIT_BUTTON"
              iconProps={{
                name: 'edit-outline',
                size: 'large',
              }}
            />
          </span>
        </Tooltip>
      )}
    </Box>
  );
};

export default ViewModeTrialInformation;
