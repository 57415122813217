import { useCallback, useEffect } from 'react';
import {
  WebSocketData,
  WebSocketMessage,
  useWebSocket,
} from '@fdha/common-hooks';
import {
  useGetAutomationItemsLazyQuery,
  useGetFoodProgramUserLazyQuery,
} from '@fdha/graphql-api-admin';
import { useApolloClient } from '@apollo/client';

export const useSync = () => {
  const { addListener, removeListener } = useWebSocket();

  const client = useApolloClient();

  const [getAutomationItems] = useGetAutomationItemsLazyQuery();
  const [getFoodProgramUser] = useGetFoodProgramUserLazyQuery();

  const invalidateCache = useCallback(() => {
    client.cache.evict({ fieldName: 'automationItems' });
    client.cache.evict({ fieldName: 'foodProgramUserByPatientIdV2' });
    client.cache.evict({ fieldName: 'patientUser' });
    client.cache.evict({ fieldName: 'deliveriesV2' });

    client.cache.gc();
  }, [client.cache]);

  const handleAutomationSync = useCallback(
    async (data?: WebSocketData) => {
      if (data?.patientId) {
        const { data: cachedAutomationItem } = await getAutomationItems({
          variables: { patientId: data.patientId },
          fetchPolicy: 'cache-only',
        });
        if (cachedAutomationItem) {
          getAutomationItems({
            variables: { patientId: data.patientId },
            fetchPolicy: 'network-only',
          });
        }
      } else {
        console.error('Unable to sync automation. Missing patientId');
      }
    },
    [getAutomationItems]
  );

  const handleUpdateFoodProgramUserSync = useCallback(
    async (data?: WebSocketData) => {
      if (data?.patientId) {
        const { data: cachedFoodProgramUserData } = await getFoodProgramUser({
          variables: { patientId: data.patientId },
          fetchPolicy: 'cache-only',
        });

        if (cachedFoodProgramUserData) {
          getFoodProgramUser({
            variables: { patientId: data.patientId },
            fetchPolicy: 'network-only',
          });
        }
      } else {
        console.error('Unable to sync food program user. Missing patientId');
      }
    },
    [getFoodProgramUser]
  );

  const handleUpdatePatientProfileSync = useCallback(() => {
    client.cache.evict({ fieldName: 'patientUser' });
  }, [client.cache]);

  const handleListDeliveriesSync = useCallback(() => {
    client.cache.evict({ fieldName: 'deliveriesV2' });
  }, [client.cache]);

  const syncData = useCallback(
    async (message: WebSocketMessage) => {
      const { eventType } = message;

      if (eventType === 'invalidate') {
        invalidateCache();
      }

      if (eventType === 'sync') {
        const { dataType, data } = message;

        switch (dataType) {
          case 'automations':
            handleAutomationSync(data);
            break;
          case 'updateFoodProgramUser':
            handleUpdateFoodProgramUserSync(data);
            break;
          case 'updatePatientTrialInfo':
            handleListDeliveriesSync();
            handleUpdatePatientProfileSync();
            break;
          case 'updatePatientProfile':
            handleUpdatePatientProfileSync();
            break;
          default:
            console.warn(`Unhandled sync data type: ${dataType}`);
        }
      }
    },
    [
      handleAutomationSync,
      handleUpdateFoodProgramUserSync,
      handleUpdatePatientProfileSync,
      handleListDeliveriesSync,
      invalidateCache,
    ]
  );

  useEffect(() => {
    addListener(syncData);

    return () => {
      removeListener(syncData);
    };
  }, [addListener, removeListener, syncData]);
};
