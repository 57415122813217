import React, { PropsWithChildren } from 'react';
import { Box, Grid, IconButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import { parseISO } from 'date-fns';
import Linkify from 'linkify-react';
import { useTranslation } from '@fdha/common-hooks';

import {
  ActivityFrequency,
  ActivityReminder,
  AppointmentDuration,
  AppointmentLocation,
  durationLabelByValue,
  formatDate,
  formatHour,
  formatUTCDate,
  locationLabelByValue,
  reminderLabelByValue,
  WeekDay,
} from '../../utils';
import { Typography } from '../Typography/Typography';

export const EditButton: React.FC<{ onEdit: () => void }> = ({ onEdit }) => {
  return (
    <IconButton onClick={onEdit} sx={{ alignSelf: 'flex-start' }}>
      <Icon name="edit-outline" size="medium" fill={grey[800]} />
    </IconButton>
  );
};

export const DescriptionText: React.FC<{
  v2?: boolean;
  title: string;
  i18nKeyTitle?: string;
  text?: string;
  i18nKeyText?: string;
  i18nParamsText?: { [key: string]: string };
}> = ({ v2, title, text, i18nKeyTitle, i18nKeyText, i18nParamsText }) => {
  const theme = useTheme();

  /************ V2 updates *********************/

  const fontSize = v2 ? '16px' : '14px';

  /*********************************************/

  return (
    <Grid item xs={6} mb={2}>
      <Typography
        variant="subtitle2"
        fontSize={fontSize}
        color={theme.palette.text.secondary}
        sx={{ overflowWrap: 'anywhere' }}
        i18nKey={i18nKeyTitle}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        i18nKey={i18nKeyText}
        i18nParams={i18nParamsText}
        sx={{ overflowWrap: 'anywhere' }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export interface DateProps {
  v2?: boolean;
  date?: string | null;
}

export const Date: React.FC<DateProps> = ({ v2, date }) => {
  const textDate = date ? formatDate(parseISO(date)) : '';
  return (
    <>
      {date && (
        <DescriptionText
          v2={v2}
          title="Date"
          i18nKeyTitle="activities:fields.date.label"
          text={textDate}
          i18nKeyText="activities:fields.date.value"
          i18nParamsText={{ date }}
        />
      )}
    </>
  );
};

export const EndDate: React.FC<DateProps> = ({ v2, date }) => {
  let text = 'Never';

  if (date) {
    text = formatUTCDate(date) || '';
  }

  const i18nKeyText = date
    ? 'activities:fields.ends.value'
    : 'activities:fields.ends.never';

  return (
    <DescriptionText
      v2={v2}
      title="Ends"
      i18nKeyTitle="activities:fields.ends.label"
      text={text}
      i18nKeyText={i18nKeyText}
      i18nParamsText={{ date: date || '' }}
    />
  );
};

export interface FrequencyProps {
  v2?: boolean;
  frequency: ActivityFrequency;
  weekDays?: WeekDay[] | null;
}

export const Frequency: React.FC<FrequencyProps> = ({
  v2,
  frequency,
  weekDays,
}) => {
  const { translate } = useTranslation();

  const hasWeekDays =
    frequency === ActivityFrequency.Weekly ||
    frequency === ActivityFrequency.Biweekly ||
    frequency === ActivityFrequency.Triweekly;

  const weekDaysTrans = weekDays?.map((day) =>
    translate(
      `activities:fields.frequency.weekDays.upper.${day.toLocaleLowerCase()}`,
      day
    )
  );
  const weekDaysList = weekDaysTrans?.join(', ') || '';
  return (
    <DescriptionText
      v2={v2}
      title="Frequency"
      i18nKeyTitle="activities:fields.frequency.label"
      text={`${frequency}${hasWeekDays ? `, ${weekDaysList}` : ''}`}
      i18nKeyText={`activities:fields.frequency.options.${
        hasWeekDays
          ? `withDays.${frequency.toLocaleLowerCase()}`
          : frequency.toLocaleLowerCase()
      }`}
      i18nParamsText={{ weekDays: weekDaysList }}
    />
  );
};

export interface TimeProps {
  v2?: boolean;
  time: string;
}

export const Time: React.FC<TimeProps> = ({ v2, time }) => {
  return (
    <DescriptionText
      v2={v2}
      title="Time"
      i18nKeyTitle="activities:fields.time.label"
      text={formatHour(time, '12Hours')}
    />
  );
};

export interface DurationProps {
  v2?: boolean;
  duration: AppointmentDuration;
}

export const Duration: React.FC<DurationProps> = ({ v2, duration }) => {
  const { label, i18nKey } = durationLabelByValue[duration];
  return (
    <DescriptionText
      v2={v2}
      title="Duration"
      i18nKeyTitle="activities:fields.duration.label"
      text={label}
      i18nKeyText={i18nKey}
    />
  );
};

export interface ReminderProps {
  v2?: boolean;
  reminder: ActivityReminder;
}

export const Reminder: React.FC<ReminderProps> = ({ v2, reminder }) => {
  const { label, i18nKey } = reminderLabelByValue[reminder];
  return (
    <DescriptionText
      v2={v2}
      title="Remind me"
      i18nKeyTitle="activities:fields.remindMe.label"
      i18nKeyText={i18nKey}
      text={label}
    />
  );
};

export interface LocationProps {
  v2?: boolean;
  location: AppointmentLocation;
}

export const Location: React.FC<LocationProps> = ({ v2, location }) => {
  const { label, i18nKey } = locationLabelByValue[location];
  return (
    <DescriptionText
      v2={v2}
      title="Location"
      i18nKeyTitle="activities:fields.location.label"
      text={label}
      i18nKeyText={i18nKey}
    />
  );
};

export interface DetailsProps {
  v2?: boolean;
  details?: string | null;
}

export const Details: React.FC<DetailsProps> = ({ v2, details }) => {
  const theme = useTheme();

  /************ V2 updates *********************/

  const fontSize = v2 ? '16px' : '14px';

  /*********************************************/

  if (!details) {
    return null;
  }

  return (
    <Box mb={2}>
      <Typography
        variant="subtitle2"
        fontSize={fontSize}
        color={theme.palette.text.secondary}
        sx={{ overflowWrap: 'anywhere' }}
        i18nKey="activities:fields.details.label"
      >
        Details
      </Typography>
      <Linkify options={{ target: '_blank' }}>
        <Typography variant="body1" sx={{ overflowWrap: 'anywhere' }}>
          {details}
        </Typography>
      </Linkify>
    </Box>
  );
};

export interface CreatedByProps {
  v2?: boolean;
  isCreatedByCoach: boolean;
}

export const CreatedBy: React.FC<CreatedByProps> = ({
  v2,
  isCreatedByCoach,
}) => {
  const theme = useTheme();

  const label = v2
    ? isCreatedByCoach
      ? 'Coach'
      : 'you'
    : isCreatedByCoach
    ? 'coach'
    : 'patient';

  const i18nKey = isCreatedByCoach
    ? 'activities:details.createdBy.coach'
    : 'activities:details.createdBy.you';

  return (
    <Typography
      variant="body2"
      sx={{ color: theme.palette.text.secondary }}
      i18nKey={i18nKey}
    >
      {`created by ${label}`}
    </Typography>
  );
};

export interface ActivityDetailsCardProps {
  v2?: boolean;
}

const ActivityDetailsCard: React.FC<
  PropsWithChildren<ActivityDetailsCardProps>
> = ({ children }) => {
  return <>{children}</>;
};

export default Object.assign(ActivityDetailsCard, {
  EditButton,
  Date,
  Frequency,
  Time,
  Reminder,
  Details,
  Location,
  CreatedBy,
  EndDate,
  Duration,
});
