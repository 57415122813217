import { Button as MuiButton, styled, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { FC } from 'react';
import Icon from 'react-eva-icons';

import { Typography } from '../Typography/Typography';

export interface CardButtonProps {
  label: string;
  handleClick: () => void;
  icon: string;
}

const Button = styled(MuiButton)(() => ({
  padding: '16px 36px 16px 24px',
  marginTop: '32px',
  backgroundColor: grey[300],
  textTransform: 'none',
  borderRadius: 15,
  width: '100%',
  justifyContent: 'space-between',
}));

const CardButton: FC<CardButtonProps> = ({ label, handleClick, icon }) => {
  const theme = useTheme();

  return (
    <Button
      endIcon={<Icon name={icon} fill={theme.palette.secondary.contrastText} />}
      onClick={handleClick}
    >
      <Typography variant="h6" color={theme.palette.secondary.contrastText}>
        {label}
      </Typography>
    </Button>
  );
};

export default CardButton;
