import React, { FC } from 'react';
import { Paper, Box, useTheme } from '@mui/material';
import Linkify from 'linkify-react';
import { grey } from '@mui/material/colors';

import PostAuthor from '../PostAuthor/PostAuthor';
import TimeAgo from '../TimeAgo/TimeAgo';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { CommunityRole, pluralize, timeAgoOverrideFn } from '../../utils';
import { IconMenu } from '../Menu/IconMenu';
import { UserType } from '../SelectType/SelectType';
import { Typography } from '../Typography/Typography';

import { DeletedByModerator } from './DeletedByModerator';

export enum PostStatus {
  Created = 'created',
  Removed = 'removed',
  Restored = 'restored',
}

export interface PostSchema {
  id: string;
  numComments: number;
  pictures?: string[] | null;
  text?: string | null;
  time: string;
  isEdited: boolean;
  user: CommunityUser;
  removedBy?: {
    id: string;
    name: string;
    isBanned: boolean;
    role: CommunityRole;
  } | null;
  status?: PostStatus | null;
  removedAt?: string;
  restoredBy?: CommunityUser;
  isPersisted: boolean;
}

export interface CommunityUser {
  id: string;
  name: string;
  picture?: string | null;
  isBanned: boolean;
  role: CommunityRole;
  type?: UserType | null;
  userId?: string | null;
}

export interface PostProps {
  v2?: boolean;
  post: PostSchema;
  isMine: boolean;
  isPostRemoved: boolean;
  isOnFeed?: boolean;
  isModerator?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onRemovePost?: (id: string) => void;
  onRestrict?: (user: CommunityUser) => void;
  onLiftRestriction?: (user: CommunityUser) => void;
  onClick?: (id: string) => void;
  onClickComment?: (id: string, focusInput?: boolean) => void;
  onClickProfile?: (url: string) => void;
}

export const Post: FC<PostProps> = ({
  v2,
  post,
  onEdit,
  onDelete,
  onRemovePost,
  isMine,
  isPostRemoved,
  isOnFeed,
  isModerator,
  onRestrict,
  onLiftRestriction,
  onClick,
  onClickComment,
  onClickProfile,
}) => {
  const theme = useTheme();

  const imageUrl = (post.pictures && post.pictures[0]) ?? undefined;

  /*************** Post Removed ***************/

  const postTextColor = isPostRemoved
    ? theme.palette.text.secondary
    : theme.palette.text.primary;
  const cursorNumComments = isPostRemoved ? 'default' : 'pointer';
  const imageOpacity = isPostRemoved ? 0.8 : undefined;

  /**************************************************/

  /*************** v2 ***************/

  const borderTopRadius = v2 ? 8.9 : 15;
  const buttonCommentColor = v2 ? undefined : theme.palette.text.primary;

  /**************************************************/

  const editMenuOption = {
    label: 'Edit',
    testId: 'EDIT_MENU_OPTION',
    icon: 'edit-outline',
    handleClick: () => onEdit(post.id),
  };
  const deleteMenuOption = {
    label: 'Delete',
    testId: 'DELETE_MENU_OPTION',
    icon: 'trash-2-outline',
    handleClick: () => onDelete(post.id),
  };
  const removeMenuOption = {
    label: 'Remove post',
    testId: 'REMOVE_MENU_OPTION',
    icon: 'trash-2-outline',
    handleClick: () => {
      if (onRemovePost) onRemovePost(post.id);
    },
  };
  const liftRestrictionMenuOption = {
    label: 'Lift restriction',
    testId: 'LIFT_RESTRICTION_MENU_OPTION',
    icon: 'person-done-outline',
    handleClick: () => {
      if (onLiftRestriction) onLiftRestriction(post.user);
    },
  };
  const restrictMenuOption = {
    label: 'Restrict user from community',
    testId: 'RESTRICT_USER_MENU_OPTION',
    icon: 'slash-outline',
    handleClick: () => {
      if (onRestrict) onRestrict(post.user);
    },
  };

  const restrictionMenuOption = post.user.isBanned
    ? liftRestrictionMenuOption
    : restrictMenuOption;

  const getUserMenuOptions = () =>
    isMine
      ? [editMenuOption, deleteMenuOption]
      : isModerator
      ? [removeMenuOption, restrictionMenuOption]
      : [];

  const hasMenuOptions = (isMine || isModerator) && !isPostRemoved;

  const getPostText = () =>
    (isModerator || !isPostRemoved) && post.text
      ? post.text
      : 'Removed by a moderator';

  const Body = () => {
    return (
      <Box
        mt={1}
        onClick={() => onClick && onClick(post.id)}
        sx={{ cursor: onClick && 'pointer' }}
      >
        <Linkify options={{ target: '_blank' }}>
          <Typography
            variant="body1"
            color={postTextColor}
            fontStyle={isModerator || !isPostRemoved ? 'normal' : 'italic'}
            sx={{
              px: 2,
              pb: 2,
              wordWrap: 'break-word',
              wordBreak: 'break-all',
            }}
          >
            {getPostText()}
          </Typography>
        </Linkify>
        {imageUrl && (
          <img
            src={imageUrl}
            alt="post"
            width="100%"
            style={{ display: 'block', opacity: imageOpacity }}
          />
        )}
      </Box>
    );
  };

  const getPaperBorderValue = () =>
    !isOnFeed ? (isPostRemoved ? 15 : 0) : undefined;

  return (
    <Paper
      sx={{
        alignSelf: 'center',
        width: '100%',
        borderBottomLeftRadius: getPaperBorderValue(),
        borderBottomRightRadius: getPaperBorderValue(),
        padding: 'unset',
      }}
    >
      {isPostRemoved && (
        <Box
          p={1.5}
          sx={{
            backgroundColor: grey[300],
            borderTopLeftRadius: borderTopRadius,
            borderTopRightRadius: borderTopRadius,
          }}
        >
          <DeletedByModerator
            entityName="post"
            removedBy={post.removedBy?.name}
            isModerator={!!isModerator}
            removedAt={post.removedAt}
            isPersisted={post.isPersisted}
          />
        </Box>
      )}
      <Box
        mx={2}
        mt={2}
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
      >
        <PostAuthor
          isMine={isMine}
          isBanned={post.user.isBanned}
          userId={post.user.userId}
          type={post.user.type}
          name={post.user.name}
          role={post.user.role}
          isRemoved={isPostRemoved}
          picture={post.user.picture ?? undefined}
          subtitle={
            <TimeAgo
              date={post.time}
              textOverrideFn={timeAgoOverrideFn(
                post,
                post.status === PostStatus.Removed
              )}
            />
          }
          isModerator={isModerator}
          onClickProfile={onClickProfile}
        />
        {hasMenuOptions && (
          <IconMenu disabled={!post.isPersisted} items={getUserMenuOptions()} />
        )}
      </Box>
      <Body />
      {((!isOnFeed && !isPostRemoved) || isOnFeed) && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          borderTop="1px solid"
          borderColor={theme.palette.divider}
          px={2}
          py={1}
        >
          <Button
            startIcon={
              <Icon
                name="message-square-outline"
                fill={theme.palette.primary.main}
                size="medium"
              />
            }
            onClick={() => onClickComment && onClickComment(post.id, true)}
            disabled={isPostRemoved}
          >
            <Typography
              variant="subtitle1"
              fontWeight={500}
              color={buttonCommentColor}
            >
              Comment
            </Typography>
          </Button>
          <Button
            onClick={() => onClick && onClick(post.id)}
            sx={{
              cursor: cursorNumComments,
            }}
            disabled={isPostRemoved}
          >
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
              sx={{ textTransform: 'none' }}
            >
              {pluralize(post.numComments, 'comment')}
            </Typography>
          </Button>
        </Box>
      )}
    </Paper>
  );
};
