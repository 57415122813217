import React, { PropsWithChildren } from 'react';

interface ConditionalWrapperProps {
  showWrapper: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

export const ConditionalWrapper: React.FC<
  PropsWithChildren<ConditionalWrapperProps>
> = ({ showWrapper, wrapper, children }) => (
  <>{showWrapper ? wrapper(children) : children}</>
);
