import React from 'react';
import {
  formatDate,
  HeadCell,
  SnackbarContent,
  CopyLinkButton,
} from '@fdha/web-ui-library';
import { Box, TableCell } from '@mui/material';
import {
  DetailedLearningModule,
  LearningModuleOverview,
} from '@fdha/graphql-api-admin';

import Chip from '../components/Chip/Chip';
import AssignUnassignButton from '../pages/patient/tabs/Learning/AssignUnassignButton';
import LinearProgress from '../components/LinearProgress/LinearProgress';

export const getHeaderCells = <
  D extends LearningModuleOverview | DetailedLearningModule
>(
  showSortable: boolean = true,
  isCsr?: boolean
) => {
  const headCells: HeadCell<D>[] = [
    {
      id: 'image',
      label: 'Course Image',
      sortable: showSortable && false,
      searchable: false,
    },
    {
      id: 'title',
      label: 'Course Name',
      sortable: showSortable && true,
      searchable: true,
    },
    {
      id: 'accessLink',
      label: 'Access Link',
      sortable: false,
      searchable: false,
    },
    {
      id: 'assigned_on',
      label: 'Assigned on',
      sortable: showSortable && true,
      searchable: false,
    },
    {
      id: 'status',
      label: 'Status',
      sortable: showSortable && false,
      searchable: false,
    },
    ...Object.assign(
      !isCsr ? [{ id: 'is_assigned', label: '', sortable: false }] : []
    ),
  ];

  return headCells;
};

export const onCopyLink = async (
  copyLink: string,
  showSnackbar?: (snackbar: SnackbarContent) => void
) => {
  try {
    if (copyLink) {
      await window.navigator.clipboard.writeText(copyLink);
      showSnackbar?.({
        message: 'Link copied',
        severity: 'success',
      });
    }
  } catch (err) {
    console.error('Error in copying link: ', err);
    showSnackbar?.({
      message: 'Error in copying link',
      severity: 'error',
    });
  }
};

export const renderTableCells = (
  row: LearningModuleOverview | DetailedLearningModule,
  patientId?: string,
  isCsr?: boolean,
  isAutoAssigned?: boolean,
  showSnackbar?: (snackbar: SnackbarContent) => void
) => {
  const assignedOn = row?.assigned_on
    ? formatDate(new Date(row.assigned_on))
    : '';

  return (
    <>
      <TableCell data-testid="COURSE_IMAGE_CELL">
        <img
          src={row?.image || ''}
          alt="Course cover"
          width="180px"
          height="67px"
          style={{ objectFit: 'cover' }}
        />
      </TableCell>
      <TableCell
        data-testid="COURSE_TITLE_CELL"
        sx={{
          minWidth: '170px',
        }}
      >
        {row?.title}
      </TableCell>
      <TableCell data-testid="ACCESS_LINK_CELL" sx={{ minWidth: '150px' }}>
        <CopyLinkButton
          handleClick={() => onCopyLink(row.courseLink ?? '', showSnackbar)}
        />
      </TableCell>
      <TableCell
        sx={{ minWidth: '160px' }}
        data-testid="COURSE_ASSIGNED_ON_CELL"
      >
        {isAutoAssigned ? (
          <Chip label="auto-assigned" />
        ) : row?.is_assigned ? (
          assignedOn
        ) : (
          <Chip label="unassigned" />
        )}
      </TableCell>
      <TableCell data-testid="COURSE_STATUS_CELL">
        {!row?.is_assigned && !isAutoAssigned ? (
          <Chip label="none" />
        ) : (
          <Box minWidth="150px">
            <LinearProgress value={row.status ?? 0} withLabel={true} />
          </Box>
        )}
      </TableCell>
      {!isCsr && (
        <TableCell data-testid="COURSE_ASSIGN_UNASSIGN_CELL">
          <AssignUnassignButton
            moduleId={row?.id}
            patientId={patientId}
            isAssigned={row?.is_assigned}
            isAutoAssigned={isAutoAssigned}
          />
        </TableCell>
      )}
    </>
  );
};
