const convertMaskToE164 = (originalPhone?: string | null) => {
  if (!originalPhone) {
    return '';
  }
  return `+1${originalPhone.replace(/-/g, '')}`;
};

const maskPhone = (value?: string | null, useHyphens: boolean = true) => {
  if (!value) {
    return '';
  }
  return value
    .replace('+1', '')
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, useHyphens ? '$1-$2' : '$1 $2')
    .replace(/(\d{3})(\d)/, useHyphens ? '$1-$2' : '$1 $2')
    .replace(/(\d{4})(\d)/, '$1');
};

export { convertMaskToE164, maskPhone };
