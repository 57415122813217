import {
  AutomationItem,
  AutomationItemStatus,
  AutomationItemType,
  AutomationRequirementStatus,
} from '@fdha/graphql-api-admin';
import { formatDate, formatHour } from '@fdha/web-ui-library';
import { parseISO } from 'date-fns';

export const colorVariantAutomationByStatus: Record<
  AutomationRequirementStatus | AutomationItemStatus,
  'success' | 'warning' | 'error' | 'info'
> = {
  [AutomationItemStatus.Checking]: 'info',
  [AutomationRequirementStatus.Done]: 'success',
  [AutomationRequirementStatus.Incomplete]: 'warning',
  [AutomationRequirementStatus.Invalid]: 'error',
  [AutomationItemStatus.Triggered]: 'success',
  [AutomationItemStatus.Scheduled]: 'info',
  [AutomationItemStatus.Error]: 'error',
};

export const statusPriorityOrder: (
  | AutomationItemStatus
  | AutomationRequirementStatus
)[] = [
  AutomationItemStatus.Checking,
  AutomationItemStatus.Invalid,
  AutomationItemStatus.Scheduled,
  AutomationItemStatus.Incomplete,
  AutomationItemStatus.Triggered,
  AutomationRequirementStatus.Done,
];

export const automationItemsPerSection = {
  foodProgramInformation: [
    AutomationItemType.FrozenFood,
    AutomationItemType.Sachets,
  ],
  trialInformation: [
    AutomationItemType.FrozenFood,
    AutomationItemType.WelcomeBinder,
    AutomationItemType.WelcomeKit,
  ],
};

export const getAutomationItemsPerSection = (
  automationItems: AutomationItem[],
  section: 'foodProgramInformation' | 'trialInformation'
) => {
  const filteredAutomationItems = automationItems.filter((automationItem) =>
    automationItemsPerSection[section].includes(automationItem.type)
  );

  return filteredAutomationItems;
};

export function getMostPriorityAutomationStatus<
  T extends AutomationItemStatus | AutomationRequirementStatus
>(status: T[]): T {
  const [statusSorted] = status.sort((a, b) => {
    return statusPriorityOrder.indexOf(a) - statusPriorityOrder.indexOf(b);
  });

  return statusSorted;
}

export const getCardDescription = (
  item: AutomationItem
): string | undefined => {
  const lastDate =
    item.expirationDate &&
    formatDate(parseISO(item.expirationDate), 'formalMonthDay');

  const updatedDate = item.updated_at && parseISO(item.updated_at);
  const updated = updatedDate && {
    date: formatDate(updatedDate, 'formalMonthDay'),
    hour: formatHour(updatedDate),
  };

  const triggerDate = item.scheduledDate
    ? formatDate(parseISO(item.scheduledDate), 'formalMonthDay')
    : null;

  switch (item.status) {
    case AutomationItemStatus.Checking:
      return 'An item linked to this automation was recently edited and a status check is in progress. This may take a while.';
    case AutomationItemStatus.Invalid:
      return `Automation has an invalid input and can’t be triggered, adjust the item or proceed manually. This automation will disappear on ${lastDate} if it stays invalid.`;
    case AutomationItemStatus.Scheduled:
      return `All criteria is met and automation is scheduled to be triggered on ${triggerDate}. You can still make changes to the automation’s items before it is triggered.`;
    case AutomationItemStatus.Incomplete:
      return item.type !== AutomationItemType.FrozenFood
        ? undefined
        : triggerDate
        ? `Automation will trigger on ${triggerDate} based on current medical food start date if all items are completed.`
        : 'Automation triggers around 8 business days before the medical food start date.';
    case AutomationItemStatus.Triggered:
      return `Automation was triggered ${updated?.date} at ${updated?.hour}. Any changes made to items won’t affect automation. This notification will clear out on ${lastDate}.`;
    case AutomationItemStatus.Error:
      return 'The application is unable to reach the automation, please reach out to the system administrator for assistance.';
  }
};
