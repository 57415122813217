import fdhaConfig from '@fdha/aws-config-common';

import { IPasswordRule } from './passwordRuleUtils';

const apiKey = fdhaConfig.api.apiKey;
const baseUrl = fdhaConfig.api.commonApi;
const configs = {
  method: 'GET',
  headers: {
    'x-api-key': apiKey,
  },
};

export const getEnv = async (): Promise<string> => {
  const url = `${baseUrl}/environment`;
  return await doGet(url);
};

export const getPasswordRules = async (): Promise<IPasswordRule[]> => {
  const url = `${baseUrl}/password-rules`;
  return await doGet(url);
};

export const getTermsAndPrivacyPolicy = async (): Promise<string[]> => {
  const url = `${baseUrl}/terms-and-privacy-policy`;
  return await doGet(url);
};

export const doGet = async (url: string) => {
  const res = await fetch(url, configs);
  return await res.json();
};
