import { omitBy } from 'lodash';

import { CalendarConfigSchema } from '../components/CalendarConfigCard/CalendarConfigCard';

export const getCalendarConfigPayload = (values: CalendarConfigSchema) => {
  if (Object.values(values).every((value) => value == null || value === '')) {
    return null;
  }
  const payload = omitBy(
    {
      calendarId: values.calendarId,
      appointmentTypeId: values.appointmentTypeId,
    },
    (value) => value == null
  );
  return payload;
};
