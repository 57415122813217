import React from 'react';

import LoginBase, { LoginBaseProps } from '../components/LoginBase';
import { Login } from '../components';

export interface LoginPageProps extends LoginBaseProps {}

export const LoginPage: React.FC<LoginPageProps> = ({ ...loginBaseProps }) => {
  return (
    <LoginBase {...loginBaseProps}>
      <Login />
    </LoginBase>
  );
};
