"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEffectOnce = void 0;
var react_1 = require("react");
var useEffectOnce = function (effect) {
    var isFirstRender = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(function () {
        if (isFirstRender.current) {
            effect();
            isFirstRender.current = false;
        }
    }, [effect]);
};
exports.useEffectOnce = useEffectOnce;
