"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.deliveryShipmentStatusIconByValue = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
exports.deliveryShipmentStatusIconByValue = (_a = {},
    _a[graphql_api_admin_1.TrackingStatus.BeingPrepared] = 'clock-outline',
    _a[graphql_api_admin_1.TrackingStatus.Delivered] = 'checkmark-outline',
    _a[graphql_api_admin_1.TrackingStatus.Failure] = 'alert-circle-outline',
    _a[graphql_api_admin_1.TrackingStatus.Returned] = 'alert-circle-outline',
    _a[graphql_api_admin_1.TrackingStatus.Transit] = 'car-outline',
    _a[graphql_api_admin_1.TrackingStatus.Unknown] = 'alert-circle-outline',
    _a);
