import React, { useState } from 'react';

import { KeyOfNestedLeaves } from '../../types/keyOfNestedLeaves';

import {
  StatelessSearchableTableHeader,
  StatelessSearchableTableHeaderProps,
} from './StatelessSearchableTableHeader';

export type SearchableTableHeaderProps<D extends object> = Omit<
  StatelessSearchableTableHeaderProps<D>,
  'onSearchFieldChange' | 'onSearchQueryChange' | 'searchField' | 'searchQuery'
> & {
  defaultSearchField: KeyOfNestedLeaves<D>;
  onSearchChange: (field: KeyOfNestedLeaves<D>, value: string) => void;
};

export function SearchableTableHeader<D extends object>({
  defaultSearchField,
  onSearchChange,
  ...statelessSearchableTableHeaderProps
}: SearchableTableHeaderProps<D>) {
  const [searchField, setSearchField] =
    useState<KeyOfNestedLeaves<D>>(defaultSearchField);
  const [searchQuery, setSearchQuery] = useState('');

  const handleFieldChange = (value: KeyOfNestedLeaves<D>) => {
    setSearchField(value);
    onSearchChange(value, searchQuery);
  };

  const handleQueryChange = (value: string) => {
    setSearchQuery(value);
    onSearchChange(searchField, value);
  };

  return (
    <StatelessSearchableTableHeader<D>
      searchField={searchField}
      searchQuery={searchQuery}
      onSearchFieldChange={handleFieldChange}
      onSearchQueryChange={handleQueryChange}
      {...statelessSearchableTableHeaderProps}
    />
  );
}
