import { styled, Button } from '@mui/material';
import React from 'react';

import { Avatar } from '../Avatar/Avatar';
import { Typography } from '../Typography/Typography';

const UserBox = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-start',
  padding: theme.spacing(),
  border: '1px solid',
  borderRadius: 15,
  borderColor: theme.palette.divider,
  textTransform: 'inherit',
}));

interface UserCardProps {
  name?: string;
  picture?: string | null;
  showUserType: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  downloadPicture?: (picture: string) => Promise<string | undefined>;
}

function UserCard({
  name,
  picture,
  showUserType = true,
  handleClick,
  downloadPicture,
}: UserCardProps) {
  return (
    <UserBox data-testid="USER_CARD" onClick={handleClick}>
      {showUserType && <Typography marginRight="5px">Coach</Typography>}
      <Avatar
        name={name}
        type="imageText"
        textPosition="left"
        picture={picture}
        downloadPicture={downloadPicture}
        testId="USER_AVATAR"
        sx={{ marginLeft: '20px' }}
      />
    </UserBox>
  );
}

export default UserCard;
