import { getPasswordRules } from './commonApi';

enum PasswordRuleType {
  MIN_CHARACTERS = 'min_characters',
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
  NUMBER = 'number',
  SPECIAL_CHARACTER = 'special_character',
}

export interface IPasswordRule {
  type: PasswordRuleType;
  regex: string;
  additionalInfo?: string;
}

export interface PasswordRule {
  type: PasswordRuleType;
  text: string;
}

interface RulesText {
  [key: string]: (additionalInfo?: string) => string;
}

export abstract class PasswordRuleUtils {
  private static rulesText: RulesText = {
    min_characters: (additionalInfo?: string) =>
      `at least ${additionalInfo} characters`,
    uppercase: (_additionalInfo?: string) =>
      'at least 1 uppercase letter (A;Z)',
    lowercase: (_additionalInfo?: string) =>
      'at least 1 lowercase letter (a;z)',
    number: (_additionalInfo?: string) => 'at least 1 number (0-9)',
    special_character: (additionalInfo?: string) =>
      `at least 1 special character (${additionalInfo})`,
  };

  private static passwordRules: IPasswordRule[] | undefined;

  private static getRulesListText = (rules: IPasswordRule[] | undefined) => {
    let rulesListText = '';

    rules?.forEach((rule, index) => {
      const ruleText = PasswordRuleUtils.rulesText[rule.type];
      const text = ruleText(rule.additionalInfo);

      rulesListText += `\u2022 ${text}`;

      if (index !== rules.length - 1) {
        rulesListText += '\n';
      }
    });

    return rulesListText;
  };

  public static setPasswordRules(data: IPasswordRule[]) {
    PasswordRuleUtils.passwordRules = data;
  }

  static getPasswordRulesListError = (password: string) => {
    if (!PasswordRuleUtils.passwordRules?.length) {
      return;
    }

    const violatedRules = PasswordRuleUtils.passwordRules.filter(
      (tip) => password.search(new RegExp(tip.regex)) === -1
    );

    return PasswordRuleUtils.getRulesListText(violatedRules);
  };

  static getPasswordHintMessage = () => {
    let hintText = 'Your password must contain:\n';

    hintText += PasswordRuleUtils.getRulesListText(
      PasswordRuleUtils.passwordRules
    );

    return hintText;
  };

  private static getRulesV2 = async () => {
    const passwordRules = await getPasswordRules();
    PasswordRuleUtils.setPasswordRules(passwordRules);
  };

  private static getRulesListV2 = (rules: IPasswordRule[] | undefined) => {
    const rulesList = rules?.map((rule) => {
      const ruleText = PasswordRuleUtils.rulesText[rule.type];
      const text = ruleText(rule.additionalInfo);
      return { text, type: rule.type };
    });
    return rulesList;
  };

  static getPasswordHintMessageV2 = async () => {
    if (!PasswordRuleUtils.passwordRules) {
      await PasswordRuleUtils.getRulesV2();
    }
    return PasswordRuleUtils.getRulesListV2(PasswordRuleUtils.passwordRules);
  };

  static getPasswordRulesListErrorV2 = (password: string) => {
    if (!PasswordRuleUtils.passwordRules?.length) {
      return;
    }

    return PasswordRuleUtils.passwordRules
      .filter((tip) => password.search(new RegExp(tip.regex)) === -1)
      .map((rule) => rule.type);
  };
}

export const getHintMessage = async () => {
  const passwordRules = await getPasswordRules();
  PasswordRuleUtils.setPasswordRules(passwordRules);
  return PasswordRuleUtils.getPasswordHintMessage();
};
