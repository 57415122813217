import { useAnalytics } from '@fdha/common-hooks';
import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  useTheme,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import Icon from 'react-eva-icons';

import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { Typography } from '../Typography/Typography';
import { i18n } from '../../types/i18n';

interface EvaIconProps {
  name: string;
  fill?: string;
  size?: string;
}

type ButtonPropsCommonProps = MuiButtonProps & {
  startEvaIcon?: EvaIconProps;
  endEvaIcon?: EvaIconProps;
  component?: string | React.ElementType<any>;
  to?: string;
  state?: any;
  target?: React.HTMLAttributeAnchorTarget;
  showSkeleton?: boolean;
};

export type ButtonProps =
  | (ButtonPropsCommonProps & {
      /** @deprecated Use `i18n` instead */ i18nKey?: string;
      i18n?: never;
    })
  | (ButtonPropsCommonProps & { i18n?: i18n; i18nKey?: never });

export function Button(props: PropsWithChildren<ButtonProps>) {
  const { analyticsClient } = useAnalytics();
  const theme = useTheme();

  const {
    startEvaIcon,
    endEvaIcon,
    showSkeleton = false,
    i18n,
    i18nKey,
    ...muiProps
  } = props;
  const customProps: Pick<ButtonProps, 'startIcon' | 'endIcon'> = {};
  const fullWidth = showSkeleton && muiProps.children == null;

  const getEvaIcon = (iconProps: EvaIconProps) => {
    if (!iconProps.fill) {
      if (props.disabled) {
        iconProps.fill = theme.palette.action.disabled;
      } else {
        const buttonColor =
          props.color !== 'inherit' && props.color !== undefined
            ? props.color
            : 'primary';

        iconProps.fill =
          props.variant === 'contained'
            ? theme.palette[buttonColor].contrastText
            : theme.palette[buttonColor].main;
      }
    }

    return (
      <Box display="flex" key={iconProps.name}>
        <Icon {...iconProps} size={iconProps.size || 'medium'} />
      </Box>
    );
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const buttonText = (event.target as HTMLButtonElement).innerText;

    analyticsClient?.track(`Click button event: ${buttonText}`);

    if (props.onClick) {
      props.onClick(event);
    }
  };

  if (startEvaIcon) {
    customProps.startIcon = getEvaIcon(startEvaIcon);
  }

  if (endEvaIcon) {
    customProps.endIcon = getEvaIcon(endEvaIcon);
  }

  const key = props.disabled ? 'button-disabled' : 'button-enabled';

  return (
    <SkeletonWrapper isLoading={showSkeleton} fullWidth={fullWidth}>
      <MuiButton key={key} {...muiProps} {...customProps} onClick={handleClick}>
        <Typography
          i18nKey={i18n?.key || i18nKey}
          i18nComponents={i18n?.components}
          i18nParams={i18n?.params}
          variant="inherit"
          style={{ display: 'inherit' }}
        >
          {muiProps.children}
        </Typography>
      </MuiButton>
    </SkeletonWrapper>
  );
}
