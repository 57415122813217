import { Box, Fab, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { pluralize, WeekDay } from '../../utils';
import { Typography } from '../Typography/Typography';

const weekDays = [
  WeekDay.Sun,
  WeekDay.Mon,
  WeekDay.Tue,
  WeekDay.Wed,
  WeekDay.Thu,
  WeekDay.Fri,
  WeekDay.Sat,
];

interface ItemProps {
  label: string;
  i18nKey?: string;
  selected: boolean;
  onPressDay: () => void;
  dataTestId: string;
  v2?: boolean;
}

const WeekDayItem: FC<ItemProps> = ({
  dataTestId,
  label,
  i18nKey,
  selected,
  onPressDay,
  v2,
}) => {
  const theme = useTheme();

  /************ V2 updates *********************/

  const color = !v2
    ? theme.palette.primary.contrastText
    : selected
    ? theme.palette.common.white
    : theme.palette.text.primary;

  const hoverColor = v2 ? theme.palette.common.white : undefined;

  /***********************************/

  return (
    <Fab
      onClick={onPressDay}
      size="small"
      sx={{
        backgroundColor: selected
          ? theme.palette.primary.main
          : theme.palette.divider,
        boxShadow: 'none',
        color: color,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: hoverColor,
        },
      }}
      data-testid={dataTestId}
    >
      <Typography variant="body2" fontWeight="medium" i18nKey={i18nKey}>
        {label}
      </Typography>
    </Fab>
  );
};

export interface WeekDaysPickerProps {
  selectedDays: WeekDay[];
  onPressDay: (days: WeekDay[]) => void;
  fullWidth?: boolean;
  title?: string;
  showSelectedDaysQtd?: boolean;
  v2?: boolean;
}

export const WeekDaysPicker: FC<WeekDaysPickerProps> = ({
  selectedDays,
  onPressDay,
  fullWidth,
  title,
  showSelectedDaysQtd,
  v2,
}) => {
  const theme = useTheme();

  const daysSelectedQtd = selectedDays.length;
  const daysSelectedLabel = `${pluralize(daysSelectedQtd, 'day')} selected`;

  const onClickDay = (day: WeekDay) => {
    const result = [...selectedDays];

    const idx = selectedDays.findIndex((d) => d === day);

    if (idx !== -1) {
      result.splice(idx, 1);
    } else {
      result.push(day);
    }

    onPressDay(result);
  };

  const id = title
    ? `${title.toUpperCase().replace(/ /g, '_')}_WEEK_DAYS_PICKER`
    : 'WEEK_DAYS_PICKER';

  const WeekList = () => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={fullWidth ? 'space-between' : undefined}
        paddingTop={1}
        columnGap={1}
        data-testid={id}
      >
        {weekDays.map((weekDay) => (
          <WeekDayItem
            key={weekDay}
            label={weekDay.charAt(0)}
            i18nKey={`activities:fields.frequency.weekDays.initials.${weekDay.toLocaleLowerCase()}`}
            selected={selectedDays.includes(weekDay)}
            onPressDay={() => onClickDay(weekDay)}
            dataTestId={`${weekDay.toUpperCase()}_BUTTON_${selectedDays.includes(
              weekDay
            )}`}
            v2={v2}
          />
        ))}
      </Box>
    );
  };

  if (v2) {
    return <WeekList />;
  }

  return (
    <Box>
      {title && (
        <Typography variant="body1" marginBottom="6px" fontWeight="500">
          {title}
        </Typography>
      )}
      <WeekList />
      {showSelectedDaysQtd && (
        <Typography
          variant="body1"
          color={theme.palette.text.secondary}
          marginTop={1}
        >
          {daysSelectedLabel}
        </Typography>
      )}
    </Box>
  );
};
