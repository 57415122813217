import {
  buildDateUTCTime,
  Button,
  formatDate,
  Option,
  useSnackbar,
  addDayPeriodValidation,
  addEndsAtValidation,
  addTimeValidation,
  addWeekDaysValidation,
  addNotInPastDateValidation,
  formatHourUTC,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { Box, useTheme } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import Icon from 'react-eva-icons';
import { format } from 'date-fns';
import {
  SurveyFrequency,
  SurveyScheduleInput,
  useAssignBhbSurveyToPatientMutation,
  useAssignSurveyToUsersMutation,
  WeekDay,
} from '@fdha/graphql-api-admin';

import PatientsAndGroups from './PatientsAndGroups';
import Schedule from './Schedule';
import Survey from './Survey';

interface BaseSurveyData {
  id?: string;
  name: string;
  isDataSurvey?: boolean;
}

interface AssignedSurveyData extends BaseSurveyData {
  isAssigned: true;
  startsAt?: string;
  endsAt?: string;
  frequency?: SurveyFrequency;
  completeWithinDays?: number | null;
  weekDays?: WeekDay[] | null;
}

interface UnassignedSurveyData extends BaseSurveyData {
  isAssigned?: false;
}

type AssignMode = 'toPatient' | 'toPatientsAndGroups' | 'toGroup';

interface BaseProps {
  mode: AssignMode;
  onSuccess?: () => void;
  onFinish?: () => void;
}

interface ToGroupProps extends BaseProps {
  mode: 'toGroup';
  groupId: string;
}

interface ToPatientProps extends BaseProps {
  mode: 'toPatient';
  patientData: { id: string; name: string };
  surveyData: AssignedSurveyData | UnassignedSurveyData;
}

interface ToPatientAndGroupsProps extends BaseProps {
  mode: 'toPatientsAndGroups';
  surveyData: { id: string; name: string };
}

type Props = ToPatientProps | ToPatientAndGroupsProps | ToGroupProps;

export interface AssignSurveySchema {
  patients: Option[];
  groups: Option[];
  survey: Option | null;
  date: Date;
  time: string;
  ends: 'never' | 'date';
  endsAt?: Date | null;
  dayPeriod: 'am' | 'pm';
  frequency: SurveyFrequency;
  weekDays?: WeekDay[];
  completeWithinDays: number;
  timezone?: string;
}

enum Screen {
  PATIENT_AND_GROUPS = 'PATIENT_AND_GROUPS',
  SCHEDULE = 'SCHEDULE',
  SURVEY = 'SURVEY',
}

const requiredMessage = 'This field is required';
const validationSchemaByScreen: Record<Screen, Yup.AnySchema> = {
  [Screen.PATIENT_AND_GROUPS]: Yup.object().shape(
    {
      patient: Yup.array().when(['groups'], {
        is: (groups: Option[]) => !groups.length,
        then: Yup.array().min(1),
      }),
      groups: Yup.array().when(['patients'], {
        is: (patients: Option[]) => !patients.length,
        then: Yup.array().min(1),
      }),
    },
    [['patient', 'groups']]
  ),
  [Screen.SCHEDULE]: Yup.object().shape({
    date: addNotInPastDateValidation(undefined, false, false),
    time: addTimeValidation(),
    endsAt: addEndsAtValidation(),
    dayPeriod: addDayPeriodValidation(),
    frequency: Yup.string().required(requiredMessage),
    weekDays: addWeekDaysValidation(),
    completeWithinDays: Yup.string().required(requiredMessage),
  }),
  [Screen.SURVEY]: Yup.object().shape({
    survey: Yup.object().required(),
  }),
};

const screensByAssignMode: Record<AssignMode, Screen[]> = {
  toPatient: [Screen.SCHEDULE],
  toPatientsAndGroups: [Screen.PATIENT_AND_GROUPS, Screen.SCHEDULE],
  toGroup: [Screen.SURVEY, Screen.SCHEDULE],
};

const AssignSurvey: FC<Props> = (props) => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const [assignSurvey] = useAssignSurveyToUsersMutation();
  const [assignBHBSurveyToPatient] = useAssignBhbSurveyToPatientMutation();

  const [activeStep, setActiveStep] = useState(0);

  const screens = screensByAssignMode[props.mode];
  const activeScreen = screens[activeStep];
  const isLastStep = activeStep === screens.length - 1;
  const currentSchema = validationSchemaByScreen[activeScreen];

  const now = new Date();
  const defaultWeekDay = format(now, 'EEE') as WeekDay;
  const defaultTime = format(now, 'hh:mm');
  const defaultDayPeriod = format(now, 'aaa');

  const isToPatientMode = props.mode === 'toPatient';
  const isAssigned = isToPatientMode && props.surveyData.isAssigned;
  const isDataSurvey = isToPatientMode && props.surveyData.isDataSurvey;

  const scheduledValues =
    isToPatientMode && props.surveyData.isAssigned && props.surveyData
      ? props.surveyData
      : undefined;

  const endsAtUTC = formatUTCDate(scheduledValues?.endsAt);
  const startsAtUTC = formatUTCDate(now.toISOString());

  const initialValues: AssignSurveySchema = {
    patients: [],
    groups: [],
    survey: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    date: startsAtUTC ? new Date(startsAtUTC) : now,
    time: formatHourUTC(scheduledValues?.startsAt, 'hour') || defaultTime,
    ends: scheduledValues?.endsAt ? 'date' : 'never',
    endsAt: endsAtUTC ? new Date(endsAtUTC) : null,
    dayPeriod: (formatHourUTC(scheduledValues?.startsAt, 'dayPeriod') ||
      defaultDayPeriod) as 'am' | 'pm',
    frequency: scheduledValues?.frequency
      ? scheduledValues?.frequency
      : isDataSurvey
      ? SurveyFrequency.Daily
      : SurveyFrequency.Once,
    weekDays: scheduledValues?.weekDays || [defaultWeekDay],
    completeWithinDays: scheduledValues?.completeWithinDays
      ? scheduledValues.completeWithinDays
      : isDataSurvey
      ? 99
      : 1,
  };

  const buildSchedule = (values: AssignSurveySchema) => {
    const parsedStartDate = buildDateUTCTime(
      formatDate(values.date, 'monthDayYear'),
      values.time,
      values.dayPeriod
    );

    return {
      starts_at: parsedStartDate,
      ends_at: values.endsAt
        ? new Date(values.endsAt).toISOString()
        : undefined,
      frequency: values.frequency,
      weekDays: values.weekDays,
      complete_within_days: values.completeWithinDays,
      timezone: values.timezone,
    };
  };

  const handleAssignSurvey = async (
    userIds: string[],
    groupIds: string[],
    surveyId: string,
    schedule: SurveyScheduleInput
  ) => {
    try {
      await assignSurvey({
        variables: {
          userIds,
          groupIds,
          surveyId,
          schedule,
        },
      });

      showSnackbar({
        message: isAssigned ? 'Changes saved!' : 'Survey assigned with success',
        severity: 'success',
      });
      props.onSuccess && props.onSuccess();
    } catch (error) {
      showSnackbar({
        message: `Error to ${isAssigned ? 'assign survey' : 'save changes'}`,
        severity: 'error',
      });
    } finally {
      props.onFinish && props.onFinish();
    }
  };

  const handleAssignDataSurvey = async (
    userIds: string[],
    schedule: SurveyScheduleInput
  ) => {
    const patientId = userIds[0];
    try {
      await assignBHBSurveyToPatient({
        variables: {
          patientId,
          schedule,
        },
      });

      showSnackbar({
        message: 'Survey assigned with success',
        severity: 'success',
      });
      props.onSuccess && props.onSuccess();
    } catch (error) {
      showSnackbar({
        message: 'Error to assign data survey',
        severity: 'error',
      });
    } finally {
      props.onFinish && props.onFinish();
    }
  };

  const handleSubmit = async (
    values: AssignSurveySchema,
    actions: FormikHelpers<AssignSurveySchema>
  ) => {
    if (isLastStep) {
      const schedule = buildSchedule(values);
      const userIds =
        props.mode === 'toPatient'
          ? [props.patientData.id]
          : values.patients.map((pat) => pat.id);

      const groupIds =
        props.mode === 'toGroup'
          ? [props.groupId]
          : values.groups.map((group) => group.id);

      const surveyId =
        props.mode !== 'toGroup'
          ? props.surveyData?.id || ''
          : values.survey?.id || '';

      if (isDataSurvey) {
        await handleAssignDataSurvey(userIds, schedule);
      } else {
        await handleAssignSurvey(userIds, groupIds, surveyId, schedule);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const renderStepContent = (selectedSurveyName: string = '') => {
    switch (activeScreen) {
      case Screen.PATIENT_AND_GROUPS:
        return <PatientsAndGroups />;
      case Screen.SCHEDULE: {
        const surveyName =
          isToPatientMode || props.mode === 'toPatientsAndGroups'
            ? props.surveyData.name
            : selectedSurveyName;

        const title = isToPatientMode
          ? isAssigned
            ? `Edit survey schedule for ${props.patientData.name}`
            : `Assign this ${isDataSurvey ? 'data measure' : 'survey'} to ${
                props.patientData.name
              }`
          : undefined;

        return (
          <Schedule
            surveyName={surveyName}
            title={title}
            isDataSurvey={isDataSurvey}
            isBulkAssign={!isToPatientMode}
            patientId={isToPatientMode ? props?.patientData.id : undefined}
          />
        );
      }
      case Screen.SURVEY:
        return <Survey />;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="flex-start">
                {activeStep !== 0 && (
                  <Button
                    color="primary"
                    size="large"
                    startIcon={
                      <Box height={18}>
                        <Icon
                          name="arrow-ios-back-outline"
                          fill={theme.palette.primary.main}
                        />
                      </Box>
                    }
                    sx={{ marginBottom: 3 }}
                    onClick={handleBack}
                    data-testid="SURVEY_BACK_BUTTON"
                  >
                    back
                  </Button>
                )}
              </Box>
              {renderStepContent(values.survey?.label)}
              <Box display="flex" justifyContent="flex-end" marginTop={3}>
                <Button
                  data-testid="CANCEL_DIALOG_BUTTON"
                  color="primary"
                  size="large"
                  onClick={props.onFinish}
                >
                  CANCEL
                </Button>
                <Button
                  data-testid={
                    isLastStep ? 'ASSIGN_DIALOG_BUTTON' : 'NEXT_DIALOG_BUTTON'
                  }
                  disabled={isSubmitting || !currentSchema.isValidSync(values)}
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                >
                  {isLastStep ? 'SAVE' : 'NEXT'}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignSurvey;
