"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMenuOptionFilter = void 0;
var react_1 = require("react");
var lodash_1 = require("lodash");
var common_utils_1 = require("@fdha/common-utils");
var buildFilterOptions = function (fullList) {
    var options = [];
    fullList.forEach(function (menuOption) {
        var _a;
        (_a = menuOption.tagsByCategory) === null || _a === void 0 ? void 0 : _a.forEach(function (category) {
            var _a;
            var items = category.tags.map(function (tag) { return ({
                value: tag.id,
                label: tag.name,
            }); });
            var existingOption = options.find(function (option) { return option.category === category.categoryName; });
            if (existingOption) {
                (_a = existingOption.items).push.apply(_a, items);
            }
            else {
                options.push({
                    category: category.categoryName,
                    items: items,
                });
            }
        });
    });
    // Sort options by category and items by label and remove duplicates
    return (0, lodash_1.sortBy)(options.map(function (option) { return (__assign(__assign({}, option), { items: (0, lodash_1.uniqBy)((0, lodash_1.sortBy)(option.items, 'label'), 'value') })); }), 'category');
};
var filter = function (fullList, selectedValues) {
    var filteredList = [];
    if (!selectedValues.length) {
        filteredList.push.apply(filteredList, fullList);
    }
    fullList.forEach(function (option) {
        var tagIds = (0, common_utils_1.getAllMenuOptionSelectionsTags)([option]).map(function (tag) { return tag.id; });
        if (selectedValues.every(function (value) { return tagIds.includes(value); })) {
            filteredList.push(option);
        }
    });
    return filteredList;
};
var useMenuOptionFilter = function (fullList, skip) {
    var _a = (0, react_1.useState)([]), filterOptions = _a[0], setFilterOptions = _a[1];
    var _b = (0, react_1.useState)([]), appliedFilters = _b[0], setAppliedFilters = _b[1];
    var _c = (0, react_1.useState)([]), draftResults = _c[0], setDraftResults = _c[1];
    var _d = (0, react_1.useState)(), results = _d[0], setResults = _d[1];
    (0, react_1.useEffect)(function () {
        if (skip) {
            return;
        }
        if (filterOptions.length === 0) {
            setFilterOptions(buildFilterOptions(fullList));
        }
        if (!results) {
            setResults(fullList);
        }
    }, [filterOptions.length, fullList, results, skip]);
    var handleFilterChange = (0, react_1.useCallback)(function (selectedValues, shouldApply) {
        if (shouldApply === void 0) { shouldApply = false; }
        var hasFilterApplied = !!selectedValues.length;
        var filteredResults = hasFilterApplied
            ? filter(fullList, selectedValues)
            : [];
        setDraftResults(filteredResults);
        var results = hasFilterApplied ? filteredResults : fullList;
        if (shouldApply) {
            setResults(results);
            setAppliedFilters(selectedValues);
        }
        var availableTags = (0, common_utils_1.getAllMenuOptionSelectionsTags)(results);
        var updatedFilterOptions = filterOptions.map(function (option) { return (__assign(__assign({}, option), { items: option.items.map(function (item) { return (__assign(__assign({}, item), { disabled: !availableTags.some(function (tag) { return tag.id === item.value; }) })); }) })); });
        setFilterOptions(updatedFilterOptions);
    }, [filterOptions, fullList]);
    var handleApply = (0, react_1.useCallback)(function (selectedValues) {
        handleFilterChange(selectedValues, true);
    }, [handleFilterChange]);
    var handleDraftChange = (0, react_1.useCallback)(function (selectedValues) {
        handleFilterChange(selectedValues);
    }, [handleFilterChange]);
    var handleCancel = (0, react_1.useCallback)(function () {
        handleFilterChange(appliedFilters, true);
    }, [appliedFilters, handleFilterChange]);
    var handleClearAll = (0, react_1.useCallback)(function () {
        handleFilterChange([], true);
    }, [handleFilterChange]);
    return {
        handleApply: handleApply,
        handleDraftChange: handleDraftChange,
        handleClearAll: handleClearAll,
        handleCancel: handleCancel,
        filterOptions: filterOptions,
        appliedFilters: appliedFilters,
        draftResults: draftResults,
        results: results || [],
    };
};
exports.useMenuOptionFilter = useMenuOptionFilter;
