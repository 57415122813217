import React from 'react';
import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  useTheme,
} from '@mui/material';

import { Icon, IconProps } from '../Icon/Icon';

type ChipIcon = Omit<IconProps, 'fill' | 'size'> & {
  fill?: IconProps['fill'];
  size?: IconProps['size'];
};

export interface ChipProps extends Omit<MuiChipProps, 'icon'> {
  icon?: ChipIcon;
  v2?: boolean;
}

export type ChipColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

export const Chip: React.FC<ChipProps> = ({ icon, v2, ...muiProps }) => {
  const theme = useTheme();

  const renderIcon = () => {
    if (!icon) {
      return undefined;
    }

    const color: ChipColor | undefined =
      muiProps.color !== 'default' ? muiProps.color : undefined;
    const fill = theme.palette[color || 'primary'].main;

    return (
      <Icon {...icon} size={icon.size || 'medium'} fill={icon.fill || fill} />
    );
  };

  return (
    <MuiChip
      sx={{
        height: 'unset',
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        columnGap: 1,
        '&.MuiChip-sizeSmall': {
          padding: `2px ${theme.spacing(1)}`,
        },
        '&.MuiChip-sizeMedium': {
          padding: `4px ${theme.spacing(2)}`,
        },
        '& .MuiChip-label': {
          lineHeight: '1.5',
          padding: 0,
        },
        '& .MuiChip-deleteIcon': {
          margin: 0,
        },
      }}
      {...muiProps}
      icon={renderIcon()}
    />
  );
};
