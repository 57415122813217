import { Box, styled, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useMemo } from 'react';
import EvaIcon from 'react-eva-icons';

import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';

const StyledSpan = styled('span')(() => ({
  // Fixes issue to align icon with text
  display: 'inline-flex',
  '& .eva': {
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  },
  '& .eva-hover': {
    display: 'inline-flex',
    '&:hover': {
      '& .eva-icon-hover-spin': {
        animation: 'spin 1s infinite linear',
      },
    },
  },
  '& .eva-icon-spin': {
    animation: 'spin 1s infinite linear',
  },
}));

// 12px | 18px | 24px | 30px
export type IconSizes = 'small' | 'medium' | 'large' | 'xlarge';

const IconSizeMap: Record<IconSizes, number> = {
  small: 12,
  medium: 18,
  large: 24,
  xlarge: 30,
};

export interface Animation {
  type: 'zoom' | 'pulse' | 'shake' | 'flip' | 'spin';
  animateOn: 'hover' | 'always';
}

export interface IconProps {
  'data-testid'?: string;
  name: string;
  fill: string;
  animation?: Animation;
  size: IconSizes;
  sx?: SxProps;
  backgroundColor?: string;
  showSkeleton?: boolean;
  showBorder?: boolean;
  showHover?: boolean;
}

export const Icon: React.FC<IconProps> = ({
  sx,
  name,
  fill,
  size,
  animation,
  backgroundColor,
  showBorder = false,
  showHover = false,
  showSkeleton = false,
  'data-testid': dataTestId,
}) => {
  const key = `${name}-${fill}-${size}-${animation?.type}`;

  const showBackground = !!backgroundColor;

  const showWrapper = useMemo(
    () => showBorder || showHover || showBackground,
    [showBackground, showBorder, showHover]
  );

  const renderWrapper = (children: React.ReactNode) => {
    const backgroundProps = showBackground
      ? {
          backgroundColor,
          padding: IconSizeMap[size] * 0.08,
        }
      : {};

    return (
      <Box
        display="inline-flex"
        alignContent="center"
        justifyContent="center"
        padding={0.1}
        sx={{
          ...backgroundProps,
          borderRadius: '50%',
          border: showBorder ? `1px solid ${grey[300]}` : undefined,
          '&:hover': showHover
            ? {
                backgroundColor: grey[200],
                borderRadius: '50%',
              }
            : undefined,
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    <SkeletonWrapper isLoading={showSkeleton} fullWidth={false}>
      <ConditionalWrapper
        showWrapper={showWrapper}
        wrapper={(children) => renderWrapper(children)}
      >
        <StyledSpan key={key} sx={sx} data-testid={dataTestId} id={key}>
          <EvaIcon
            name={name}
            size={size}
            fill={fill}
            animation={{
              type: animation?.type,
              hover: animation?.animateOn === 'hover',
              infinite: animation?.animateOn === 'always',
            }}
          />
        </StyledSpan>
      </ConditionalWrapper>
    </SkeletonWrapper>
  );
};
