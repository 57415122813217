import { Paper, useTheme } from '@mui/material';
import React from 'react';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

interface EmptyStateCardPropsBase {
  message: React.ReactNode;
  title?: string;
  i18nKeyTitle?: string;
  i18nKeyMessage?: string;
  iconType?: 'default' | 'none' | 'custom';
}

interface EmptyStateCardPropsWithCustomIcon extends EmptyStateCardPropsBase {
  iconType?: 'custom';
  customIcon: React.ReactNode;
}

interface EmptyStateCardPropsWithoutCustomIcon extends EmptyStateCardPropsBase {
  iconType?: 'default' | 'none';
}

export type EmptyStateCardProps =
  | EmptyStateCardPropsWithCustomIcon
  | EmptyStateCardPropsWithoutCustomIcon;

export const EmptyStateCard: React.FC<EmptyStateCardProps> = (props) => {
  const theme = useTheme();

  const { message, title, i18nKeyTitle, i18nKeyMessage } = props;

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      {props.iconType === 'custom' ? (
        props.customIcon
      ) : props.iconType === 'default' ? (
        <Icon
          name="eye-off-outline"
          size="xlarge"
          fill={theme.palette.text.secondary}
        />
      ) : null}
      {title && (
        <Typography
          i18nKey={i18nKeyTitle}
          variant="body1"
          color={theme.palette.text.disabled}
          fontWeight="bold"
          textAlign="center"
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
      )}
      <Typography
        i18nKey={i18nKeyMessage}
        variant="body1"
        color={theme.palette.text.disabled}
        textAlign="center"
        whiteSpace="break-spaces"
      >
        {message}
      </Typography>
    </Paper>
  );
};
