import React from 'react';
import { CalendarConfig } from '@fdha/graphql-api-admin';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';

import { CalendarConfigInputs } from '../../components/';

export interface CalendarConfigSchema {
  calendarId?: string | null;
  appointmentTypeId?: string | null;
}

interface CalendarConfigCardProps {
  data?: CalendarConfig | null;
  onSubmit: (values: CalendarConfigSchema) => void;
}

const CalendarConfigCard: React.FC<CalendarConfigCardProps> = ({
  data,
  onSubmit,
}) => {
  const theme = useTheme();

  const initialValues: CalendarConfigSchema = {
    calendarId: data?.calendarId,
    appointmentTypeId: data?.appointmentTypeId,
  };

  const {
    handleSubmit,
    isSubmitting,
    values,
    dirty,
    resetForm,
    setFieldValue,
  } = useFormik<CalendarConfigSchema>({
    initialValues,
    onSubmit: async (values) => {
      if (!dirty) {
        return;
      }
      await onSubmit(values);
      resetForm({ values });
    },
  });

  return (
    <>
      <Paper sx={{ boxShadow: theme.shadows[2] }}>
        <Box
          padding={2}
          bgcolor={theme.palette.secondary.light}
          borderRadius="15px 15px 0px 0px"
          borderBottom={`0.5px solid ${theme.palette.divider}`}
        >
          <Typography variant="h6">Acuity Configuration</Typography>
        </Box>
        <Box padding={3}>
          <CalendarConfigInputs
            calendarIdValue={values.calendarId}
            appointmentTypeIdValue={values.appointmentTypeId}
            calendarIdName="calendarId"
            appointmentTypeIdName="appointmentTypeId"
            handleBlur={() => handleSubmit()}
            disabled={isSubmitting}
            onChange={setFieldValue}
          />
        </Box>
      </Paper>
    </>
  );
};

export default CalendarConfigCard;
