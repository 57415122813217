"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildMessageForNotification = void 0;
var buildMessageForNotification = function (type) {
    switch (type) {
        case 'CommunityNotificationComment':
            return ' commented on your post';
        case 'CommunityNotificationRemoveComment':
            return 'Your comment has been removed by a moderator';
        case 'CommunityNotificationRemovePost':
            return 'Your post has been removed by a moderator';
        case 'CommunityNotificationRestrictUser':
            return 'You have been restricted from Faeth Community by a moderator';
    }
};
exports.buildMessageForNotification = buildMessageForNotification;
