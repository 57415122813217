import { Box, Paper, TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import { parseISO } from 'date-fns';
import {
  Button,
  useDialog,
  formatDate,
  Table,
  HeadCell,
  SearchableTableHeader,
  pluralize,
  maskPhone,
} from '@fdha/web-ui-library';
import {
  CoachUser,
  FilterOp,
  useListCoachUsersQuery,
  UserType,
} from '@fdha/graphql-api-admin';

import Avatar from '../../../../components/Avatar/Avatar';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
} from '../../../../hooks';

import AddCustomerServiceDialog from './AddCustomerServiceDialog';

const headCells: HeadCell<CoachUser>[] = [
  { id: 'name', label: 'Name', sortable: true, searchable: true },
  { id: 'created_at', label: 'Joined on', sortable: true, searchable: false },
  { id: 'email', label: 'Email', sortable: true, searchable: true },
  {
    id: 'phone_number',
    label: 'Phone number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'number_of_patients',
    label: 'Patients',
    sortable: true,
    searchable: false,
  },
];

const getNumberOfPatients = (patients?: number | null) => {
  if (patients) {
    return pluralize(patients, 'patient');
  }
  return 'None';
};

const renderRow = (row: CoachUser) => {
  return (
    <TableRow hover key={row.id} data-testid="TABLE_ROW">
      <TableCell data-testid="NAME_CELL">
        <Avatar type="imageText" picture={row.picture} name={row.name} />
      </TableCell>
      <TableCell data-testid="JOINED_ON_CELL">
        {formatDate(parseISO(row.created_at))}
      </TableCell>
      <TableCell data-testid="EMAIL_CELL">{row.email}</TableCell>
      <TableCell data-testid="PHONE_CELL">
        {maskPhone(row.phone_number, false)}
      </TableCell>
      <TableCell data-testid="PATIENTS_CELL">
        {getNumberOfPatients(row.number_of_patients)}
      </TableCell>
      <TableCell padding="checkbox">
        <Link
          to={`/coach/${row.id}`}
          state={{ backRoute: '/settings/csr' }}
          data-testid="COACH_PROFILE_BUTTON"
        >
          <Icon
            name="arrow-ios-forward-outline"
            fill={grey[600]}
            size="large"
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

const CustomerServiceTab = () => {
  const { openDialog } = useDialog();
  const [sortBy, setSortBy] = useSortBy<CoachUser>('name', 'asc');
  const [filterBy, setFilterBy] = useFilterBy<CoachUser>('name', '');
  const filterByDebounced = useDebouncedValue(filterBy);

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'csr',
  });

  const csrTypeFilter = {
    field: 'type',
    op: FilterOp.Equal,
    value: UserType.CustomerService,
  };
  const { data, fetchMore, loading, networkStatus } = useListCoachUsersQuery({
    variables: {
      first: rowsPerPage,
      sortBy: {
        sortBy: [sortBy],
      },
      filterBy: {
        filterBy: [csrTypeFilter, filterByDebounced],
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const nodes = data?.coachUsers.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.coachUsers.pageInfo;
  const totalNumberFound = data?.coachUsers.totalNumberFound;
  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const handleNewCsr = () => {
    openDialog({
      title: '',
      content: <AddCustomerServiceDialog />,
    });
  };

  return (
    <>
      <Box display="flex" marginBottom={2} alignItems="center">
        <SearchableTableHeader<CoachUser>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setFilterBy}
          disableMargin
        />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startEvaIcon={{ name: 'plus-outline' }}
          sx={{ marginLeft: 2 }}
          onClick={handleNewCsr}
          data-testid="NEW_CSR_BUTTON"
        >
          NEW
        </Button>
      </Box>
      <Paper data-testid="CUSTOMER_SERVICE_TABLE">
        <Table<CoachUser>
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          renderRow={renderRow}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          withPagination
          onSortChange={setSortBy}
        />
      </Paper>
    </>
  );
};

export default CustomerServiceTab;
