"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFewestWeekdaysOnDietDeliveries = exports.getFreshestWeekdaysOnDietDeliveries = exports.getWeekdaysOnDietDeliveries = exports.isWeekdaysIncluded = exports.getOptimalOptions = exports.sumDaysDistance = exports.getPossibleOptions = exports.getFreshnessMask = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var weekDaysOrdered = [
    graphql_api_admin_1.WeekDay.Mon,
    graphql_api_admin_1.WeekDay.Tue,
    graphql_api_admin_1.WeekDay.Wed,
    graphql_api_admin_1.WeekDay.Thu,
    graphql_api_admin_1.WeekDay.Fri,
    graphql_api_admin_1.WeekDay.Sat,
    graphql_api_admin_1.WeekDay.Sun,
];
/**
 * Return the list days of the week in order from the startDay
 * Generating the freshness mask of this day
 *
 * @param startDay - Start day of the freshness mask
 * @param currentFreshness - Amount of days to be on the mask (default 5)
 * @returns The days ordered from startDay with the currentFreshness length
 */
var getFreshnessMask = function (startDay, currentFreshness) {
    if (currentFreshness === void 0) { currentFreshness = 5; }
    var startIndex = weekDaysOrdered.indexOf(startDay);
    return weekDaysOrdered
        .slice(startIndex + 1)
        .concat(weekDaysOrdered.slice(0, startIndex + 1))
        .slice(0, currentFreshness);
};
exports.getFreshnessMask = getFreshnessMask;
/**
 * Return the possible combinations of 1-3 days within the specified days
 *
 * @param days - The days to be used in the combinations
 * @returns An array of arrays of the possible combinations of 1-3 days
 */
var getPossibleOptions = function (days) {
    var possibleOptions = [];
    for (var i = 0; i < days.length; i++) {
        possibleOptions.push([days[i]]);
        for (var j = i + 1; j < days.length; j++) {
            possibleOptions.push([days[i], days[j]]);
            for (var k = j + 1; k < days.length; k++) {
                possibleOptions.push([
                    days[i],
                    days[j],
                    days[k],
                ]);
            }
        }
    }
    return possibleOptions;
};
exports.getPossibleOptions = getPossibleOptions;
/**
 * Return the sum of the distance between the days and the base day
 *
 * @param days - The days to calculate the distance to
 * @param baseDay - The day to calculate the distance from
 * @returns The sum of the distance between the days and the base day
 */
var sumDaysDistance = function (days, baseDay) {
    var daysFromFreshness = (0, exports.getFreshnessMask)(baseDay, 7);
    return days.reduce(function (acc, day) {
        var dayIndex = daysFromFreshness.indexOf(day);
        return acc + dayIndex;
    }, 0);
};
exports.sumDaysDistance = sumDaysDistance;
/**
 * Return the optimal options for the first and second day
 *
 * @param firstDay - The first day to calculate the optimal options
 * @param secondDay - The second day to calculate the optimal options
 * @returns The optimal options for the first and second day
 */
var getOptimalOptions = function (firstDay, secondDay) {
    var firstDayMask = (0, exports.getFreshnessMask)(firstDay);
    var secondDayMask = (0, exports.getFreshnessMask)(secondDay);
    var intersectionDays = firstDayMask.filter(function (day) {
        return secondDayMask.includes(day);
    });
    var possibleOptions = (0, exports.getPossibleOptions)(intersectionDays);
    var firstOptimalOptions = [];
    var secondOptimalOptions = [];
    possibleOptions.forEach(function (option) {
        var firstDaySum = (0, exports.sumDaysDistance)(option, firstDay);
        var secondDaySum = (0, exports.sumDaysDistance)(option, secondDay);
        if (firstDaySum < secondDaySum) {
            firstOptimalOptions.push(option);
        }
        else {
            secondOptimalOptions.push(option);
        }
    });
    return { firstOptimalOptions: firstOptimalOptions, secondOptimalOptions: secondOptimalOptions };
};
exports.getOptimalOptions = getOptimalOptions;
/**
 * Return if the weekdays are included in the options
 *
 * @param options - The options to be checked
 * @param days - The days to be checked
 * @returns If the weekdays are included in the options
 */
var isWeekdaysIncluded = function (options, days) {
    return options.some(function (option) {
        return option.length === days.length && option.every(function (day) { return days.includes(day); });
    });
};
exports.isWeekdaysIncluded = isWeekdaysIncluded;
/**
 * Gets the weekdays on diet deliveries according to the algorithm.
 *
 * @param weekdays - Weekdays on diet.
 * @param settings - Delivery Algorithm (Fewest or Freshest).
 * @returns Weekdays on diet for the first and second deliveries.
 */
var getWeekdaysOnDietDeliveries = function (weekdays, settings) {
    if (settings === void 0) { settings = graphql_api_admin_1.DeliverySettings.Freshest; }
    if (settings === graphql_api_admin_1.DeliverySettings.Freshest || weekdays.length > 4) {
        return (0, exports.getFreshestWeekdaysOnDietDeliveries)(weekdays);
    }
    return (0, exports.getFewestWeekdaysOnDietDeliveries)(weekdays);
};
exports.getWeekdaysOnDietDeliveries = getWeekdaysOnDietDeliveries;
/**
 * Gets the weekdays on diet deliveries for Freshest algorithm.
 *
 * @param weekdays - Weekdays on diet.
 * @returns Weekdays on diet for the first and second deliveries for Freshest algorithm.
 */
var getFreshestWeekdaysOnDietDeliveries = function (weekdays) {
    var allFirstDeliveryWeekdaysOnDiet = [
        graphql_api_admin_1.WeekDay.Wed,
        graphql_api_admin_1.WeekDay.Thu,
        graphql_api_admin_1.WeekDay.Fri,
    ];
    var firstDeliveryWeekdaysOnDiet = [];
    var secondDeliveryWeekdaysOnDiet = [];
    weekdays.forEach(function (day) {
        if (allFirstDeliveryWeekdaysOnDiet.includes(day)) {
            firstDeliveryWeekdaysOnDiet.push(day);
        }
        else {
            secondDeliveryWeekdaysOnDiet.push(day);
        }
    });
    return {
        firstDeliveryWeekdaysOnDiet: firstDeliveryWeekdaysOnDiet,
        secondDeliveryWeekdaysOnDiet: secondDeliveryWeekdaysOnDiet,
    };
};
exports.getFreshestWeekdaysOnDietDeliveries = getFreshestWeekdaysOnDietDeliveries;
/**
 * Gets the weekdays on diet deliveries for Fewest algorithm.
 *
 * @param weekdays - Weekdays on diet.
 * @returns Weekdays on diet for the first and second deliveries for Fewest algorithm.
 */
var getFewestWeekdaysOnDietDeliveries = function (weekdays) {
    var firstDeliveryMask = (0, exports.getFreshnessMask)(graphql_api_admin_1.WeekDay.Tue);
    var secondDeliveryMask = (0, exports.getFreshnessMask)(graphql_api_admin_1.WeekDay.Fri);
    var _a = (0, exports.getOptimalOptions)(graphql_api_admin_1.WeekDay.Tue, graphql_api_admin_1.WeekDay.Fri), firstOptimalOptions = _a.firstOptimalOptions, secondOptimalOptions = _a.secondOptimalOptions;
    var fitsOnFirstDelivery = weekdays.every(function (day) {
        return firstDeliveryMask.includes(day);
    });
    var fitsOnSecondDelivery = weekdays.every(function (day) {
        return secondDeliveryMask.includes(day);
    });
    // Only first delivery or optimal for first delivery
    if ((fitsOnFirstDelivery && !fitsOnSecondDelivery) ||
        (0, exports.isWeekdaysIncluded)(firstOptimalOptions, weekdays)) {
        return {
            firstDeliveryWeekdaysOnDiet: weekdays,
            secondDeliveryWeekdaysOnDiet: [],
        };
    }
    // Only second delivery or optimal for second delivery
    if ((fitsOnSecondDelivery && !fitsOnFirstDelivery) ||
        (0, exports.isWeekdaysIncluded)(secondOptimalOptions, weekdays)) {
        return {
            firstDeliveryWeekdaysOnDiet: [],
            secondDeliveryWeekdaysOnDiet: weekdays,
        };
    }
    // Doesn't fit on any delivery, so the freshest option is chosen
    return (0, exports.getFreshestWeekdaysOnDietDeliveries)(weekdays);
};
exports.getFewestWeekdaysOnDietDeliveries = getFewestWeekdaysOnDietDeliveries;
