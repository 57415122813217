import { Badge, Box, ListItemButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC } from 'react';
import { buildMessageForNotification } from '@fdha/community-notifications';

import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import TimeAgo from '../TimeAgo/TimeAgo';
import icon from '../../images/Community_Moderation.png';
import { Typography } from '../Typography/Typography';

interface NotificationItemProps {
  name?: string;
  picture?: string | null;
  read: boolean;
  time: string;
  type?: string;
  handleClick?: () => void;
}

export const NotificationItem: FC<NotificationItemProps> = ({
  name,
  picture,
  read,
  time,
  type,
  handleClick,
}) => {
  const theme = useTheme();
  return (
    <ListItemButton
      disableGutters
      onClick={handleClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 22,
        paddingRight: 22,
        borderBottom: `1px solid ${grey[300]}`,
        minHeight: 90,
      }}
    >
      <Badge
        color="error"
        variant="dot"
        style={{ marginRight: 10 }}
        invisible={read}
      />
      <Box display="flex">
        {picture || name ? (
          <Badge
            color="info"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            overlap="circular"
            badgeContent={
              <Icon
                name="message-square-outline"
                size="small"
                fill={theme.palette.primary.contrastText}
              />
            }
          >
            <Avatar picture={picture} name={name} size={56} />
          </Badge>
        ) : (
          <Avatar picture={icon} size={56} />
        )}
        <Box display="flex" flexDirection="column" ml={2}>
          <Box display="flex" flexDirection="row" alignItems="baseline">
            {name && (
              <Typography variant="body1" fontWeight="bold">
                {name}&nbsp;
              </Typography>
            )}
            <Typography variant="body1">
              {buildMessageForNotification(type)}
            </Typography>
          </Box>
          <TimeAgo date={time} />
        </Box>
      </Box>
    </ListItemButton>
  );
};
