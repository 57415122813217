import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider,
  StaticDatePicker as MuiStaticDatePicker,
  StaticDatePickerProps,
} from '@mui/x-date-pickers';
import { useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StaticDatePicker: React.FC<StaticDatePickerProps<string>> = ({
  ...muiProps
}) => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiStaticDatePicker
        {...muiProps}
        sx={{
          height: 333,
          border: `1px solid ${grey[400]}`,
          borderRadius: '10px',
          display: 'flex',
          '.MuiPickersLayout-toolbar': { display: 'none' },
          '.MuiPickersLayout-actionBar': { display: 'none' },
          '.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
            color: grey[500],
          },
          '.MuiPickersDay-root.Mui-selected': {
            backgroundColor: theme.palette.primary.background,
            border: `1px solid ${theme.palette.text.disabled}`,
            color: theme.palette.text.primary,
            '&:hover': { backgroundColor: theme.palette.primary.background },
            '&:focus': { backgroundColor: theme.palette.primary.background },
          },
        }}
      />
    </LocalizationProvider>
  );
};
