import React from 'react';
import { Box as MuiBox, styled, BoxProps } from '@mui/material';

const Box = styled(MuiBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

const SpaceBetweenBox: React.FC<BoxProps> = (props) => (
  <Box {...props}>{props.children}</Box>
);

export default SpaceBetweenBox;
