import Axios from 'axios';
import fdhaConfig from '@fdha/aws-config-common';

export enum AddressValidationStatus {
  Ok = 'Ok',
  MissingComplement = 'MissingComplement',
  Invalid = 'Invalid',
}
interface Place {
  componentName: {
    text: string;
  };
  componentType: string;
  confirmationLevel: string;
}

const getPlaceName = (components: Place[], type: string) => {
  const component = components.find(
    (component) => component.componentType === type
  );
  return component ? component.componentName.text : '';
};

const getFields = (components: Place[]) => {
  const fields = [
    'street_number',
    'route',
    'locality',
    'administrative_area_level_1',
    'postal_code',
  ];
  const resList = fields.map((field) => {
    return getPlaceName(components, field);
  });
  return {
    streetAddress: `${resList[0]} ${resList[1]}`,
    locality: resList[2],
    region: resList[3],
    postalCode: resList[4],
    country: 'USA',
  };
};

export const validateAddress = async (
  address: string,
  complement?: string | null
) => {
  const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${fdhaConfig.secretsManager.mapsApiKey}`;
  try {
    const response = await Axios.post(
      url,
      {
        address: {
          regionCode: 'US',
          addressLines: [address],
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const { address: googleAddress } = response.data.result;

    const isInvalid: boolean = googleAddress.addressComponents?.length < 6;

    const fields = isInvalid
      ? undefined
      : {
          ...getFields(googleAddress.addressComponents),
          formatted: googleAddress.formattedAddress,
        };

    const isMissingComplement: boolean =
      googleAddress.missingComponentTypes &&
      googleAddress.missingComponentTypes.includes('subpremise') &&
      !complement;

    const status = isInvalid
      ? AddressValidationStatus.Invalid
      : isMissingComplement
      ? AddressValidationStatus.MissingComplement
      : AddressValidationStatus.Ok;

    return {
      fields,
      status,
    };
  } catch (error) {
    console.error(error);
  }
};
