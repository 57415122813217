import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  ActivityDetectorProvider,
  CachedFiltersProvider,
  ChatProvider,
  FeatureFlagsProvider,
  StreamUser,
  WebSocketProvider,
} from '@fdha/common-hooks';
import {
  DialogProvider,
  Loader,
  LoadingBarProvider,
  useSnackbar,
  useDialog,
} from '@fdha/web-ui-library';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { useNavigate } from 'react-router-dom';
import {
  useGetApiKeysLazyQuery,
  useGetCommunityUserQuery,
  useGetProfileLazyQuery,
  useUpdateUserTimezoneMutation,
} from '@fdha/graphql-api-admin';
import { onError } from '@apollo/client/link/error';
import { Quill } from 'react-quill';

import Routes from './routes/routes';
import PublicRoutes from './routes/public.routes';
import { ChatNotificationProvider } from './hooks';
import { AppLayout } from './components';

const QuillLink = Quill.import('formats/link');

QuillLink.sanitize = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

function App() {
  const client = useApolloClient();
  const authStatus = useAuthStatus();
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [updateUserTimezone] = useUpdateUserTimezoneMutation();

  const onSignOut = useCallback(async () => {
    showSnackbar({
      severity: 'warning',
      message: 'Session expired. Please log in again.',
      closeOnClickOutside: true,
      closeAutomatically: false,
    });
    closeDialog();
    navigate('/', { replace: true });
  }, [showSnackbar, closeDialog, navigate]);

  const [getApiKeys, { data: apiKeysData }] = useGetApiKeysLazyQuery();
  const [getProfile, { data: profileData }] = useGetProfileLazyQuery({
    onCompleted: (result) =>
      updateCoachTimezone(result.me.id, result.me.timezone),
  });
  const { refetch: refetchCommunity } = useGetCommunityUserQuery();

  const errorControl = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (!extensions) {
          return;
        }
        if (extensions['code'] === 'COMMUNITY_BANNED') {
          refetchCommunity();
        }
      });
    }
  });

  client.setLink(errorControl.concat(client.link));

  const updateCoachTimezone = async (
    coachId: string,
    timezone?: string | null
  ) => {
    const coachTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (coachId && coachTimezone !== timezone) {
      await updateUserTimezone({
        variables: {
          timezone: coachTimezone,
        },
      });
    }
  };

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      getApiKeys();
      getProfile();
    }
  }, [authStatus, getApiKeys, getProfile]);

  useEffect(() => {
    Intercom('shutdown');

    const clearCache = async () => {
      try {
        await client.cache.reset();
      } catch (e) {
        console.log('Error cleaning cache', e);
      }
    };

    if (authStatus === AuthStatus.SIGNED_OUT) {
      clearCache();
    }
  }, [authStatus, client]);

  const { me } = profileData || {};

  const streamKey = apiKeysData?.apiKeys.stream;
  const streamUserData: StreamUser | undefined = useMemo(() => {
    return me
      ? {
          name: me.name,
          picture: me.picture,
          streamUserId: me.stream_user_id,
          streamUserToken: me.stream_user_token,
        }
      : undefined;
  }, [me]);

  if (authStatus === AuthStatus.LOADING) {
    return <Loader />;
  }
  return (
    <>
      {authStatus === AuthStatus.SIGNED_IN ? (
        <FeatureFlagsProvider>
          <WebSocketProvider>
            <DialogProvider>
              <AppLayout>
                <ChatProvider streamKey={streamKey} user={streamUserData}>
                  <ChatNotificationProvider>
                    <ActivityDetectorProvider onSignOut={onSignOut}>
                      <LoadingBarProvider>
                        <CachedFiltersProvider>
                          <Routes />
                        </CachedFiltersProvider>
                      </LoadingBarProvider>
                    </ActivityDetectorProvider>
                  </ChatNotificationProvider>
                </ChatProvider>
              </AppLayout>
            </DialogProvider>
          </WebSocketProvider>
        </FeatureFlagsProvider>
      ) : (
        <Box width="100%">
          <PublicRoutes />
        </Box>
      )}
    </>
  );
}

export default App;
