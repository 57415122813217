import { Autocomplete } from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { UserType, useListGroupsQuery } from '@fdha/graphql-api-admin';

import { useGetPatientsList, useGetUserType } from '../../hooks';

import { AssignSurveySchema } from './AssignSurvey';

const NUMBER_OF_RESULTS = 10000;

const PatientsAndGroups = () => {
  const { isAdmin } = useGetUserType();

  const { nodes: patients, loading: loadingPatients } = useGetPatientsList(
    !!isAdmin,
    isAdmin === undefined,
    NUMBER_OF_RESULTS,
    false,
    undefined,
    undefined,
    true
  );

  const { data: groupListData, loading: loadingGroups } = useListGroupsQuery({
    variables: {
      first: NUMBER_OF_RESULTS,
    },
    fetchPolicy: 'cache-and-network',
  });

  const patientOptions = useMemo(
    () =>
      patients?.map((p) => {
        const nameSuffix =
          p.type === UserType.ClinicalTrialPatient && p.subject_id
            ? ` (${p.subject_id})`
            : '';
        return {
          label: `${p.name}${nameSuffix}`,
          id: p.id,
        };
      }) ?? [],
    [patients]
  );

  const groupOptions = useMemo(
    () =>
      groupListData?.groups.edges.map(({ node }) => ({
        label: node.name,
        id: node.id,
      })) ?? [],
    [groupListData?.groups.edges]
  );

  const { setFieldValue, values } = useFormikContext<AssignSurveySchema>();

  return (
    <>
      <Autocomplete
        multiple
        allowSelectAll
        title="Select One or More Patients"
        options={patientOptions}
        defaultValue={[]}
        value={values.patients}
        loading={loadingPatients}
        onChange={(_event, value) => {
          setFieldValue('patients', value);
        }}
        placeholder="Search by name or subject ID"
      />
      <Box marginTop={3}>
        <Autocomplete
          multiple
          allowSelectAll
          title="Select One or More Groups"
          options={groupOptions}
          defaultValue={[]}
          value={values.groups}
          loading={loadingGroups}
          onChange={(_event, value) => {
            setFieldValue('groups', value);
          }}
          placeholder="Name"
        />
      </Box>
    </>
  );
};

export default PatientsAndGroups;
