import { InputAdornment, IconButton, Box, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import { escape } from 'lodash';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

import TextField, { TextFieldProps } from './TextField';

enum PasswordRuleType {
  MIN_CHARACTERS = 'min_characters',
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
  NUMBER = 'number',
  SPECIAL_CHARACTER = 'special_character',
}
interface PasswordRule {
  type: PasswordRuleType;
  text: string;
}

export type PasswordTextFieldProps = Omit<TextFieldProps, 'type'> & {
  title?: string;
  errorMessage?: React.ReactNode;
  hint?: string;
  v2?: boolean;
  passwordHint?: PasswordRule[];
  rulesErrorList?: string[];
  showRulesError?: boolean;
};

const PasswordTextField: React.FC<PasswordTextFieldProps> = ({
  errorMessage,
  hint,
  v2,
  passwordHint,
  rulesErrorList,
  showRulesError,
  ...muiProps
}) => {
  const theme = useTheme();
  const helperText = errorMessage || hint;
  const error = !!errorMessage;

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /************ V2 updates *********************/

  const iconColor = v2 ? grey[900] : grey[600];
  const iconOnName = v2 ? 'eye' : 'eye-outline';
  const iconOffName = v2 ? 'eye-off' : 'eye-off-outline';

  /*********************************************/

  const i18nKeys: Record<PasswordRuleType, string> = {
    [PasswordRuleType.MIN_CHARACTERS]: 'validations:passwordRules.minLength',
    [PasswordRuleType.LOWERCASE]: 'validations:passwordRules.lowercase',
    [PasswordRuleType.UPPERCASE]: 'validations:passwordRules.uppercase',
    [PasswordRuleType.NUMBER]: 'validations:passwordRules.number',
    [PasswordRuleType.SPECIAL_CHARACTER]:
      'validations:passwordRules.specialCharacter',
  };

  const getIcon = () => {
    const iconName = showPassword ? iconOnName : iconOffName;

    return <Icon name={iconName} size="large" fill={iconColor} />;
  };

  const isRuleValid = (rule: PasswordRule) => {
    return muiProps.value && !rulesErrorList?.includes(rule.type);
  };

  const getColor = (rule: PasswordRule) => {
    return isRuleValid(rule)
      ? theme.palette.success.main
      : showRulesError
      ? theme.palette.error.main
      : theme.palette.text.hint;
  };

  const getHintIcon = (rule: PasswordRule) => {
    return !isRuleValid(rule) && showRulesError
      ? 'close-circle'
      : 'checkmark-circle-2';
  };

  return (
    <Box>
      <TextField
        v2={v2}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end">
                {getIcon()}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={error}
        helperText={helperText}
        {...muiProps}
      />
      {passwordHint && (
        <Box data-testid="HINTS_PASSWORD" mt={0.5}>
          {passwordHint?.map((rule) => (
            <Box key={rule.type} display="flex" alignItems="center">
              <Icon
                name={getHintIcon(rule)}
                size="medium"
                fill={getColor(rule)}
              />
              <Typography
                i18nKey={i18nKeys[rule.type]}
                i18nParams={{
                  characters: escape('(!@#$%^&*()_+-=[]{}|<>?~)'),
                }}
                variant="subtitle1"
                color={getColor(rule)}
                ml={0.5}
              >
                {rule.text}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PasswordTextField;
