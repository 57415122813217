import { useGetProfileQuery, UserType } from '@fdha/graphql-api-admin';

export const useGetUserType = () => {
  const { data, loading: isLoading } = useGetProfileQuery();

  const isAdmin = data?.me.is_admin;
  const isCsr = data?.me.type === UserType.CustomerService;

  return { isAdmin, isCsr, isLoading };
};
