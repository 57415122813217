import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetCoachSiteTrialQuery } from '@fdha/graphql-api-admin';

import { BasePage } from '../../../components';

import { AddOrEditCoachSiteTrial } from './AddOrEditCoachSiteTrial';

const EditCoachSiteTrial = () => {
  const navigate = useNavigate();
  const params = useParams();

  const coachSiteTrialId = params.coachSiteTrialId || '';

  const { data: coachSiteTrialData, loading: loadingCoachSiteTrial } =
    useGetCoachSiteTrialQuery({
      variables: { id: coachSiteTrialId },
      fetchPolicy: 'cache-and-network',
    });

  const coachSiteTrial = coachSiteTrialData?.coachSiteTrial;

  return (
    <BasePage>
      <BasePage.BackButton />
      <BasePage.Wrapper>
        <BasePage.Title>Edit Site/Trial Assignment</BasePage.Title>
        <AddOrEditCoachSiteTrial
          onCancel={() => navigate(-1)}
          onSuccess={(coachId) => navigate(`/coach/${coachId}`)}
          data={coachSiteTrial}
          loadingCoachSiteTrial={loadingCoachSiteTrial}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default EditCoachSiteTrial;
