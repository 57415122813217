import { Alert as MuiAlert, AlertProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export const Alert = ({
  children,
  sx,
  ...props
}: PropsWithChildren<AlertProps>) => {
  return (
    <MuiAlert
      {...props}
      sx={{
        borderRadius: '5px',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </MuiAlert>
  );
};

export default Alert;
