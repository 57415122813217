import React from 'react';
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import { Trans } from 'react-i18next';

import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';

export interface TypographyProps extends MuiTypographyProps {
  showSkeleton?: boolean;
  i18nKey?: string;
  i18nParams?: { [key: string]: string | number };
  i18nComponents?: { [tagName: string]: React.ReactElement };
  component?: React.ElementType;
}

export const Typography: React.FC<TypographyProps> = ({
  showSkeleton = false,
  i18nKey,
  i18nParams,
  i18nComponents,
  component,
  children,
  ...muiProps
}) => {
  const fullWidth = showSkeleton && children == null;
  const allProps: MuiTypographyProps & {
    component?: React.ElementType;
  } = muiProps;

  if (component != null) {
    allProps.component = component;
  }

  // Loading will not be shown, added it here only to make
  // the typography have a height correspondent to the variant
  // when loading
  const typographyChildren =
    showSkeleton && children == null ? 'Loading' : children;

  return (
    <SkeletonWrapper isLoading={showSkeleton} fullWidth={fullWidth}>
      <MuiTypography {...allProps}>
        {i18nKey ? (
          <Trans
            i18nKey={i18nKey}
            values={i18nParams}
            components={i18nComponents}
            shouldUnescape
          >
            {typographyChildren}
          </Trans>
        ) : (
          <>{typographyChildren}</>
        )}
      </MuiTypography>
    </SkeletonWrapper>
  );
};
