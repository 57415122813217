import React, { PropsWithChildren } from 'react';
import {
  FrequencyInput,
  Select,
  TimeInput,
  completeWithinLabelByValue,
  completeWithinOptionsByFrequency,
  DatePicker,
  surveyFrequencyLabelByValue,
} from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { SurveyFrequency, WeekDay } from '@fdha/graphql-api-admin';

import { getError } from '../../utils';

import { AssignSurveySchema } from './AssignSurvey';

const StartDate = () => {
  const {
    values: { date },
    touched,
    errors,
    setFieldValue,
    handleBlur,
    setFieldTouched,
  } = useFormikContext<AssignSurveySchema>();

  const error = getError('date', errors, touched);

  return (
    <DatePicker
      title="Start date"
      name="date"
      value={date}
      onChange={async (value) => {
        await setFieldValue('date', value, false);
        setFieldTouched('date', true);
      }}
      error={!!error}
      helperText={error}
      onBlur={handleBlur}
    />
  );
};

const Time = () => {
  const {
    values: { time, dayPeriod },
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<AssignSurveySchema>();

  const error = touched.time && errors.time;

  return (
    <TimeInput
      time={time}
      dayPeriod={dayPeriod}
      error={error}
      onChange={setFieldValue}
      caption="This will be assigned in the patient's local time zone"
    />
  );
};
interface FrequencyFormProps {
  weekDaysOnDiet: WeekDay[];
  isBulkAssign: boolean;
}

const FrequencyForm: FC<FrequencyFormProps> = ({
  weekDaysOnDiet,
  isBulkAssign,
}) => {
  const {
    values: { completeWithinDays, frequency, weekDays },
    initialValues: { weekDays: initialWeekDays },
    setFieldValue,
  } = useFormikContext<AssignSurveySchema>();

  const frequencyOptions = Object.entries(SurveyFrequency).map(
    ([key, value]) => ({
      label: surveyFrequencyLabelByValue[value],
      value: key,
    })
  );

  return (
    <FrequencyInput
      completeWithinDays={completeWithinDays}
      frequency={frequency}
      frequencyOptions={frequencyOptions}
      weekDays={weekDays}
      initialWeekDays={initialWeekDays}
      setFieldValue={setFieldValue}
      weekDaysOnDiet={weekDaysOnDiet}
      isBulkAssign={isBulkAssign}
    />
  );
};

const CompleteWithin: FC<{ disabled?: boolean; title?: string }> = ({
  disabled,
  title,
}) => {
  const {
    values: { completeWithinDays, frequency },
    handleChange,
  } = useFormikContext<AssignSurveySchema>();

  const completeWithinOptions = frequency
    ? completeWithinOptionsByFrequency[frequency]
    : [];
  const selectOptions = completeWithinOptions.map((option) => ({
    label: completeWithinLabelByValue[option],
    value: option.valueOf(),
  }));

  return (
    <Select
      name="completeWithinDays"
      title={title || 'Close after'}
      value={completeWithinDays}
      onChange={handleChange}
      options={selectOptions}
      disabled={selectOptions.length <= 1 || disabled}
    />
  );
};

const EndsAt = () => {
  const {
    values: { ends, endsAt },
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  } = useFormikContext<AssignSurveySchema>();

  const options = [
    {
      value: 'never',
      label: 'Never',
    },
    {
      value: 'date',
      label: 'On date',
    },
  ];

  const error = getError('endsAt', errors, touched);

  return (
    <Box>
      <Select
        name="ends"
        title="Ends"
        value={ends}
        onChange={(option) => setFieldValue('ends', option.target.value)}
        options={options}
      />
      {ends === 'date' && (
        <DatePicker
          name="endsAt"
          data-testid="ENDS_ON_DATE"
          value={endsAt}
          onChange={async (value) => {
            await setFieldValue('endsAt', value, false);
            setFieldTouched('endsAt', true);
          }}
          sx={{
            mt: 2,
          }}
          error={!!error}
          helperText={error}
          onBlur={handleBlur}
        />
      )}
    </Box>
  );
};

const SchedulesPreferences: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack spacing={2} marginTop={3}>
      {children}
    </Stack>
  );
};

export default Object.assign(SchedulesPreferences, {
  StartDate,
  Time,
  EndsAt,
  FrequencyForm,
  CompleteWithin,
});
