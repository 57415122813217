import { makeVar } from '@apollo/client';

interface CognitoUserCustom {
  username: string;
  challengeParam: {
    CODE_DELIVERY_DESTINATION: string;
  };
}

const cognitoUser = makeVar<CognitoUserCustom | undefined>(undefined);

const setCognitoUserInitialValue = () => {
  cognitoUser(undefined);
};

const setCognitoUser = (value: CognitoUserCustom) => {
  cognitoUser(value);
};

export { cognitoUser, setCognitoUserInitialValue, setCognitoUser };
