import React, { ChangeEventHandler, FC } from 'react';
import { Box, IconButton, Paper, Stack, styled, useTheme } from '@mui/material';

import {
  Button,
  CharactersCount,
  Icon,
  SpaceBetweenBox,
  TextField,
  Typography,
} from '..';
import PostAuthor from '../PostAuthor/PostAuthor';
import { CommunityRole, WritePostSchema } from '../../utils';

export const Input = styled('input')({
  display: 'none',
});

export const Image = styled('img')({
  maxWidth: '100%',
  borderRadius: 20,
});

export interface AddOrEditPostCardProps {
  v2?: boolean;
  name: string;
  error: boolean;
  role: CommunityRole;
  values: WritePostSchema;
  picture?: string;
  isValid: boolean;
  helperText?: string;
  isEditMode?: boolean;
  isModerator?: boolean;
  isSubmitting: boolean;
  handleCancel: () => void;
  handleNavigateProfile?: (url: string) => void;
  handleUploadPicture: (imageFile: File) => Promise<string | undefined>;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (field: string, value: string) => void;
}

const AddOrEditPostCard: FC<AddOrEditPostCardProps> = ({
  v2,
  name,
  error,
  role,
  values,
  picture,
  isValid,
  helperText,
  isEditMode,
  isModerator,
  isSubmitting,
  handleCancel,
  handleNavigateProfile,
  handleUploadPicture,
  handleChange,
  handleSubmit,
  setFieldValue,
}) => {
  const theme = useTheme();
  const submitButtonMsg = isEditMode ? 'Save Changes' : 'Publish';

  const handleSelectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const imageFile = e.target.files[0];
      const imageUrl = await handleUploadPicture(imageFile);

      if (imageUrl) {
        setFieldValue('picture', imageUrl);
      }
    }
  };

  /************ V2 updates *********************/

  const buttonColor = v2 ? undefined : 'secondary';

  /*********************************************/

  return (
    <Paper sx={{ py: 3, px: 2 }}>
      {!v2 && (
        <Typography variant="h5" mb={3}>
          {isEditMode ? 'Edit Post' : 'Write a Post'}
        </Typography>
      )}
      <SpaceBetweenBox mb={2}>
        <PostAuthor
          isMine
          role={role}
          name={name}
          picture={picture}
          subtitle={<CharactersCount text={values.text} maxCount={280} />}
          isModerator={isModerator}
          onClickProfile={handleNavigateProfile}
        />
        <IconButton component="label">
          <Input
            accept="image/*"
            id="upload-image"
            type="file"
            onChange={handleSelectImage}
          />
          <Icon
            size="large"
            name="attach-outline"
            fill={theme.palette.text.primary}
          />
        </IconButton>
      </SpaceBetweenBox>
      <TextField
        placeholder="What’s on your mind?"
        multiline
        rows={7}
        name="text"
        onChange={handleChange}
        error={error}
        helperText={helperText}
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={values.text}
        v2={v2}
      />
      {values.picture && (
        <Box position="relative">
          <Image src={values.picture} />
          <IconButton
            onClick={() => setFieldValue('picture', '')}
            sx={{
              backgroundColor: theme.palette.common.white,
              position: 'absolute',
              top: 8,
              right: 8,
              boxShadow: theme.shadows[2],
              border: '0.5px solid',
              borderColor: theme.palette.divider,
            }}
          >
            <Icon size="medium" name="close" fill={theme.palette.grey[900]} />
          </IconButton>
        </Box>
      )}
      <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
        <Button variant="outlined" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          disabled={!isValid || isSubmitting}
          onClick={() => handleSubmit()}
          color={buttonColor}
        >
          {submitButtonMsg}
        </Button>
      </Stack>
    </Paper>
  );
};

export default AddOrEditPostCard;
