import { getTermsAndPrivacyPolicy } from './commonApi';

export abstract class TermsAndConditionsUtils {
  private static termsAndConditions: string;

  private static getTermsAndConditionsLink = async () => {
    const termsAndConditions = await getTermsAndPrivacyPolicy();
    TermsAndConditionsUtils.setTermsAndConditions(termsAndConditions[0]);
  };

  public static setTermsAndConditions(data: string) {
    TermsAndConditionsUtils.termsAndConditions = data;
  }

  static getTermsLink = async () => {
    if (!TermsAndConditionsUtils.termsAndConditions) {
      await TermsAndConditionsUtils.getTermsAndConditionsLink();
    }

    return TermsAndConditionsUtils.termsAndConditions;
  };
}

export abstract class PrivacyPolicyUtils {
  private static privacyPolicy: string;

  private static getPrivacyPolicyLink = async () => {
    const privacyPolicy = await getTermsAndPrivacyPolicy();
    PrivacyPolicyUtils.setPrivacyPolicy(privacyPolicy[1]);
  };

  public static setPrivacyPolicy(data: string) {
    PrivacyPolicyUtils.privacyPolicy = data;
  }

  static getPolicyLink = async () => {
    if (!PrivacyPolicyUtils.privacyPolicy) {
      await PrivacyPolicyUtils.getPrivacyPolicyLink();
    }

    return PrivacyPolicyUtils.privacyPolicy;
  };
}
