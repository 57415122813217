import React, { FC } from 'react';
import { DeliveryCard, Loader } from '@fdha/web-ui-library';
import { Box, Typography } from '@mui/material';
import {
  AutomationItemType,
  DeliveryV2,
  MenuType,
  useListDeliveriesQuery,
} from '@fdha/graphql-api-admin';
import { useNavigate } from 'react-router';
import { useSystemSetting } from '@fdha/common-hooks';
import {
  getFromDateForDeliveriesQuery,
  isDeliveryDelayed,
} from '@fdha/common-utils';

import { useAutomation, useDeliveryDialog } from '../../../../../hooks';
import { AutomationDetails } from '../../../../../components';

interface ActiveDeliveriesProps {
  patientId?: string;
  subjectId?: string | null;
}

const ActiveDeliveries: FC<ActiveDeliveriesProps> = ({
  patientId = '',
  subjectId,
}) => {
  const navigate = useNavigate();

  const { value, isLoading: loadingSystemSetting } = useSystemSetting<number>(
    'offsetForDeliveriesQuery'
  );

  const { data, loading } = useListDeliveriesQuery({
    variables: {
      date: getFromDateForDeliveriesQuery(value || 0),
      patientId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !patientId || loadingSystemSetting,
  });

  const deliveries = data?.deliveriesV2;
  const isLoading = loading || loadingSystemSetting;

  const { openReactivateDialog, openCancelDialog } = useDeliveryDialog(
    subjectId ?? ''
  );

  const { automationItems } = useAutomation(patientId);

  const renderEmptyState = () => (
    <Box display="flex" justifyContent="center">
      <Typography variant="body2">No upcoming deliveries.</Typography>
    </Box>
  );

  const LabelHelper = ({ menuType }: { menuType: MenuType }) => {
    if (menuType === MenuType.Regular) {
      return;
    }

    const items = automationItems.filter(
      (item) => item.type === AutomationItemType.FrozenFood
    );

    return <AutomationDetails automationItems={items} />;
  };

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : !deliveries?.length ? (
        renderEmptyState()
      ) : (
        deliveries?.map((delivery: DeliveryV2) => (
          <DeliveryCard
            key={delivery.id}
            deliveryId={delivery.id}
            deliveryDate={delivery.deliveryDate}
            openChanges={delivery.openChanges}
            status={delivery.status}
            shipmentLabels={delivery.order?.shipmentLabels}
            showShipmentStatus
            isCanceled={delivery.isCanceled}
            isOwner={false}
            showIconMenu
            onClick={() =>
              navigate(`/patient/${patientId}/delivery/${delivery.id}`)
            }
            onEdit={() =>
              navigate(`/patient/${patientId}/delivery/${delivery.id}`, {
                state: { editMode: true },
              })
            }
            onCancel={openCancelDialog}
            onReactivate={openReactivateDialog}
            buttonLabel="See more details"
            weekOfCycle={delivery.weekOfCycle}
            isDelayed={isDeliveryDelayed(
              new Date(delivery.deliveryDate),
              delivery.order?.shipmentLabels
            )}
            menuType={delivery.dietPlanDeliveryMenu.type}
            labelHelper={
              <LabelHelper menuType={delivery.dietPlanDeliveryMenu.type} />
            }
          />
        ))
      )}
    </Box>
  );
};

export default ActiveDeliveries;
