import React from 'react';
import { Box, styled } from '@mui/material';

import { Typography } from '../Typography/Typography';

const Typo = styled(Typography)(() => ({
  display: 'inline',
}));

interface User {
  id?: string;
  name?: string;
}

interface ManageCoachInfoProps {
  mainCoach: User;
  backupCoach: User;
  meId?: string;
}

function ManageCoachInfo(props: ManageCoachInfoProps) {
  const renderMainCoachInfo = (mainCoach: User) => {
    return (
      <Box data-testid="MAIN_COACH">
        {mainCoach.id === props.meId ? (
          <Typo>You’re the main coach. </Typo>
        ) : (
          <Typo>{mainCoach.name} is the main coach. </Typo>
        )}
      </Box>
    );
  };

  const renderBackupCoachInfo = (backupCoach: User) => {
    return (
      <Box data-testid="BACKUP_COACH">
        {backupCoach.id === props.meId ? (
          <Typo>You’re the backup coach.</Typo>
        ) : (
          <Typo>{backupCoach.name} is the backup coach.</Typo>
        )}
      </Box>
    );
  };

  return (
    <Box data-testid="COACH_INFO">
      {props.mainCoach.id && renderMainCoachInfo(props.mainCoach)}
      {props.backupCoach.id && renderBackupCoachInfo(props.backupCoach)}
    </Box>
  );
}

export default ManageCoachInfo;
