import { createTheme } from '@mui/material';
// To extend the default theme structure with the extension components available in the lab
import createPalette from '@mui/material/styles/createPalette';

import themeV1 from './theme';

import type {} from '@mui/lab/themeAugmentation';

declare module '@mui/material/styles' {
  interface PaletteColor {
    textDark?: string;
    lightBg?: string;
    selected?: string;
    border?: string;
  }

  interface SimplePaletteColorOptions {
    textDark?: string;
    lightBg?: string;
    selected?: string;
    border?: string;
    background?: string;
  }

  interface TypeText {
    hint: string;
  }
}

/* For simplicity, adding the spread only on root properties and overriding the entire child objects.
 * If some child property must inherit the theme values, then the spread should be added to this
 * specific object
 */

const palette = createPalette({
  ...themeV1.palette,
  primary: {
    main: '#1D4834',
    dark: '#00210E',
    light: '#48745E',
    border: '#80B592',
    contrastText: '#F3F1EE',
    background: '#D8E8E0',
  },
  secondary: {
    main: '#0C0C0C',
    dark: '#000000',
    light: '#333333',
    border: '#858585',
    contrastText: '#F3F1EE',
  },
  background: {
    ...themeV1.palette.background,
    default: '#EDEBE8',
    paper: '#FFFFFF',
  },
  success: {
    main: '#006B25',
    dark: '#003F00',
    light: '#439A50',
    textDark: '#002B0F',
    lightBg: '#E5F0E9',
    contrastText: '#F3F1EE',
    border: '#80B592',
  },
  info: {
    main: '#005CB4',
    dark: '#003484',
    light: '#5688E7',
    textDark: '#002548',
    lightBg: '#E5EFF7',
    contrastText: '#F3F1EE',
    border: '#80ADD9',
  },
  warning: {
    main: '#FBD338',
    dark: '#C4A200',
    light: '#FFFF6D',
    textDark: '#645416',
    lightBg: '#FFFBEB',
    contrastText: '#1D1D1D',
    border: '#FDE99B',
  },
  error: {
    main: '#BC062E',
    dark: '#840007',
    light: '#F54E57',
    textDark: '#4B0212',
    lightBg: '#F8E6EA',
    contrastText: '#F3F1EE',
    border: '#DD8397',
  },
  divider: '#C7C7C7',
  text: {
    ...themeV1.palette.text,
    primary: '#1D1D1D',
    secondary: '#4A4A4A',
    disabled: '#616161',
  },
  action: {
    ...themeV1.palette.action,
    disabled: '#616161',
    disabledBackground: '#E0DEDC',
  },
});

const theme = createTheme({
  ...themeV1,
  components: {
    ...themeV1.components,
    MuiAppBar: {
      styleOverrides: { root: { color: '#1D1D1D' } },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1rem',
        },
      },
    },
    MuiLinearProgress: {},
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.MuiChip-outlined': {
            '&.MuiChip-colorError': {
              backgroundColor: '#F8E6EA',
            },
            '&.MuiChip-colorInfo': {
              backgroundColor: '#E5EFF7',
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: '#E5F0E9',
            },
            '&.MuiChip-colorSecondary': {
              backgroundColor: '#EEEEEE',
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: '#FFFBEB',
            },
            color: '#1D1D1D',
          },
          fontWeight: '700',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          color: '#005CB4',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: { '&.Mui-completed': { color: '#002B0F' } },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: { '&.Mui-completed': { color: '#006B25' } },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
          '&.MuiCard-root': {
            padding: 'unset',
          },
          '&.MuiAppBar-root': {
            padding: 'unset',
          },
          '&.MuiAccordion-root': {
            padding: 'unset',
          },
          '&.MuiMenu-paper': {
            padding: 'unset',
          },
          '&.MuiPickersPopper-paper': {
            padding: 'unset',
            maxWidth: 'min-content',
          },
          '&.MuiDrawer-paper': {
            padding: 'unset',
          },
          '&.MuiDialog-paper': {
            padding: 'unset',
          },
        }),
        rounded: {
          borderRadius: '10px',
        },
        outlined: {
          '&.MuiCard-root': {
            border: '1px solid #9E9E9E',
          },
        },
      },
    },
    MuiTab: {},
    MuiTableCell: {},
    MuiTablePagination: {},
    MuiTabs: {},
    MuiToggleButton: {},
    MuiToggleButtonGroup: {},
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
        action: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {},
    MuiCard: {
      styleOverrides: {
        root: {
          '&:hover': { backgroundColor: '#EBEBEB' },
          boxShadow: '0px 3px 6px -1px rgba(55, 55, 55, 0.1)',
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': { color: '#616161' },
        },
      },
    },
    MuiSkeleton: { styleOverrides: { root: { backgroundColor: '#F7F7F7' } } },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: '-2px 2px 4px -1px rgb(55 55 55 / 30%)',
        },
      },
    },
  },
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
    },
  },
  typography: {
    ...themeV1.typography,
    h6: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.125rem',
    },
  },
});

export default theme;
