export enum ActivityFrequency {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  Triweekly = 'Triweekly',
  Weekly = 'Weekly',
}

export enum ActivityKind {
  Appointment = 'Appointment',
  Goal = 'Goal',
  Task = 'Task',
}

export enum ActivityReminder {
  FifteenMinutes = 'FifteenMinutes',
  Never = 'Never',
  OneDay = 'OneDay',
  TenMinutes = 'TenMinutes',
  ThirtyMinutes = 'ThirtyMinutes',
}

export enum WeekDay {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed',
}

export type Activity = {
  creatorId?: string | null;
  description?: string | null;
  ends_at?: string | null;
  frequency: ActivityFrequency;
  id: string;
  instanceId?: string | null;
  kind: ActivityKind;
  reminder: ActivityReminder;
  time: string;
  title: string;
  weekDays?: WeekDay[] | null;
};

export enum AppointmentDuration {
  FifteenMinutes = 'FifteenMinutes',
  OneHour = 'OneHour',
  ThirtyMinutes = 'ThirtyMinutes',
}

export enum AppointmentLocation {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export type Appointment = Activity & {
  appleEventId?: string;
  duration: AppointmentDuration;
  ends?: string;
  google_event_id?: string;
  hasVideoCall: boolean;
  location: AppointmentLocation;
  patientId?: string;
};

type GoalStep = {
  completed: boolean;
  id: string;
  title: string;
};

export type Goal = Activity & {
  completed: boolean;
  steps: GoalStep[];
};

export type Task = Activity & {
  completed: boolean;
  ends?: string;
};

export const durationLabelByValue: Record<
  AppointmentDuration,
  { label: string; i18nKey: string }
> = {
  [AppointmentDuration.FifteenMinutes]: {
    label: '15 minutes',
    i18nKey: 'activities:fields.duration.options.15min',
  },
  [AppointmentDuration.ThirtyMinutes]: {
    label: '30 minutes',
    i18nKey: 'activities:fields.duration.options.30min',
  },
  [AppointmentDuration.OneHour]: {
    label: '1 hour',
    i18nKey: 'activities:fields.duration.options.1hr',
  },
};

export const locationLabelByValue: Record<
  AppointmentLocation,
  { label: string; i18nKey: string }
> = {
  [AppointmentLocation.InPerson]: {
    label: 'In Person',
    i18nKey: 'activities:fields.location.options.inPerson',
  },
  [AppointmentLocation.Virtual]: {
    label: 'Virtual',
    i18nKey: 'activities:fields.location.options.virtual',
  },
};

export const reminderLabelByValue: Record<
  ActivityReminder,
  { label: string; i18nKey: string }
> = {
  [ActivityReminder.Never]: {
    label: 'Never',
    i18nKey: 'activities:fields.remindMe.options.never',
  },
  [ActivityReminder.TenMinutes]: {
    label: '10 min before',
    i18nKey: 'activities:fields.remindMe.options.10min',
  },
  [ActivityReminder.FifteenMinutes]: {
    label: '15 min before',
    i18nKey: 'activities:fields.remindMe.options.15min',
  },
  [ActivityReminder.ThirtyMinutes]: {
    label: '30 min before',
    i18nKey: 'activities:fields.remindMe.options.30min',
  },
  [ActivityReminder.OneDay]: {
    label: 'A day before',
    i18nKey: 'activities:fields.remindMe.options.day',
  },
};

export const frequencyLabelByValue: Record<
  ActivityFrequency,
  { label: string; i18nKey: string }
> = {
  [ActivityFrequency.Once]: {
    label: 'Once',
    i18nKey: 'activities:fields.frequency.options.once',
  },
  [ActivityFrequency.Daily]: {
    label: 'Daily',
    i18nKey: 'activities:fields.frequency.options.daily',
  },
  [ActivityFrequency.Weekly]: {
    label: 'Weekly',
    i18nKey: 'activities:fields.frequency.options.weekly',
  },
  [ActivityFrequency.Biweekly]: {
    label: 'Biweekly',
    i18nKey: 'activities:fields.frequency.options.biweekly',
  },
  [ActivityFrequency.Triweekly]: {
    label: 'Triweekly',
    i18nKey: 'activities:fields.frequency.options.triweekly',
  },
  [ActivityFrequency.Monthly]: {
    label: 'Monthly',
    i18nKey: 'activities:fields.frequency.options.monthly',
  },
};

export const endsLabelByValue: Record<'never' | 'date', string> = {
  never: 'Never',
  date: 'On date',
};
