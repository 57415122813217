enum WeekDay {
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun',
}

enum FoodProgramStatus {
  Active = 'active',
  Archived = 'archived',
}

export const getFoodProgramStatus = {
  [FoodProgramStatus.Active]: 'Active',
  [FoodProgramStatus.Archived]: 'Archived',
};

export const getWeekDays = (weekDays?: WeekDay[] | null) => {
  if (!weekDays || weekDays.length === 0) {
    return 'Days on diet is not defined yet';
  }

  return `${weekDays.length}: ${weekDays
    .map((day) => day.toLowerCase())
    .join(', ')}`;
};
