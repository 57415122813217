import React from 'react';
import { Box, CircularProgressProps, SxProps } from '@mui/material';

import CircularProgress from '../CircularProgress/CircularProgress';

type LoaderProps = {
  wrapperSx?: SxProps;
} & CircularProgressProps;

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <Box
      display="flex"
      flex={1}
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={props.wrapperSx}
    >
      <CircularProgress color="primary" variant="indeterminate" {...props} />
    </Box>
  );
};

export default Loader;
