import React from 'react';

import { ChangePassword } from '../components';
import LoginBase, { LoginBaseProps } from '../components/LoginBase';

export interface ChangePasswordPageProps extends LoginBaseProps {}

export const ChangePasswordPage: React.FC<ChangePasswordPageProps> = ({
  ...loginBaseProps
}) => {
  return (
    <LoginBase {...loginBaseProps}>
      <ChangePassword />
    </LoginBase>
  );
};
