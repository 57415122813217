import React, { FC, useMemo } from 'react';

import { AddressValidationStatus } from '../../utils';
import { Warning } from '../Warning/Warning';

export interface AddressWarningProps {
  status?: AddressValidationStatus | null;
}

export const AddressWarning: FC<AddressWarningProps> = ({ status }) => {
  const warning = useMemo(() => {
    switch (status) {
      case AddressValidationStatus.Invalid:
        return "We couldn't find the provided address in our database. You will still be able to proceed, but please check for any missing or incorrect details.";
      case AddressValidationStatus.MissingComplement:
        return 'Please confirm that the address provided includes any secondary address details, such as apartment, suite, unit, building or floor numbers. This will help us ensure accurate delivery and communication.';
      default:
        return;
    }
  }, [status]);

  return <Warning text={warning} />;
};
