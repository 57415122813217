import { alpha, createTheme, toggleButtonClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
// To extend the default theme structure with the extension components available in the lab
import createPalette from '@mui/material/styles/createPalette';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { FONT_FAMILY } from '../utils';

// Customizing Shadows
const shadows = createTheme().shadows;
shadows[1] = '0px 2px 4px -1px rgba(55, 55, 55, 0.1)';
shadows[2] = '0px 3px 6px -1px rgba(55, 55, 55, 0.1)';
shadows[3] = '0px 26px 52px -1px rgba(55, 55, 55, 0.2)';
shadows[4] = '0px -3px 6px 0px rgba(55, 55, 55, 0.10)';

declare module '@mui/material/styles' {
  interface PaletteColor {
    textDark?: string;
    lightBg?: string;
    selected?: string;
    background?: string;
  }

  interface TypeAction {
    background?: string;
    main?: string;
  }

  interface TypeText {
    hint: string;
  }

  interface TypeBackground {
    bright1: string;
    bright2: string;
    bright3: string;
    bright4: string;
    bright5: string;
  }

  interface SimplePaletteColorOptions {
    textDark?: string;
    lightBg?: string;
    selected?: string;
  }
}

const palette = createPalette({
  primary: {
    main: '#123034',
    dark: '#00080D',
    light: '#3C595E',
    contrastText: '#FFFFFF',
    background: '#ECEFEF',
  },
  secondary: {
    main: '#75C2BE',
    dark: '#44918E',
    light: '#D4F4EE',
    border: '#B9E0DE',
    contrastText: '#00080D',
    selected: 'rgba(117, 194, 190, 0.08)',
  },
  background: {
    default: '#F3F1EE',
    paper: '#FFFFFF',
    bright1: '#FAF7F5',
    bright2: '#F2F0ED',
    bright3: '#E5E3E1',
    bright4: '#E1DFDD',
    bright5: '#E4E4E4',
  },
  success: {
    main: '#367F19',
    dark: '#185B0C',
    light: '#439A50',
    lightBg: '#EBF2E8',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#01628C',
    dark: '#003964',
    light: '#59C6DC',
    contrastText: '#FFFFFF',
    lightBg: '#E5EFF3',
  },
  warning: {
    main: '#F6B900',
    dark: '#B17C00',
    light: '#FCDE64',
    contrastText: alpha('#000000', 0.87),
    border: '#FFE030',
    lightBg: '#FFFBEB',
  },
  error: {
    main: '#93112B',
    dark: '#B72D3B',
    light: '#DE6869',
    contrastText: '#FFFFFF',
    textDark: '#3B0711',
    lightBg: '#F4E7EA',
  },
  divider: '#0000003B',
  text: {
    secondary: alpha('#000000', 0.54),
    hint: '#616161',
  },
  action: {
    background: '#EBEBEB',
    disabledBackground: '#D6D4D1',
    main: '#E1DFDD',
  },
});

const getButtonWithoutBgHoverColor = (
  color?:
    | 'default'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
) => {
  if (color === 'inherit') {
    return null;
  }
  const bgColor =
    color && color !== 'default'
      ? palette[color].main
      : palette['primary'].main;

  return alpha(bgColor, palette.action.hoverOpacity);
};

const theme = createTheme({
  shape: {
    borderRadius: 25,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#0000003B transparent',
          scrollbarWidth: 'thin',
          '& *::-webkit-scrollbar': {
            width: '14px',
            height: '14px',
          },
          '& *::-webkit-scrollbar-thumb': {
            backgroundClip:
              'padding-box' /* creates padding around scroll thumb */,
            border:
              '4px solid transparent' /* creates padding around scroll thumb */,
            borderRadius: '15px' /* roundness of the scroll thumb */,
            backgroundColor: '#0000003B' /* color of the scroll thumb */,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        size: 'large',
      },
      styleOverrides: {
        text: ({ ownerState }) => {
          return {
            lineHeight: '1rem',
            '&:hover': {
              backgroundColor: getButtonWithoutBgHoverColor(ownerState.color),
            },
          };
        },
        contained: {
          boxShadow: shadows[2],
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: '1.1875rem',
        },
        icon: {
          marginLeft: 8,
          marginRight: -4,
        },
        iconSmall: {
          marginLeft: 8,
          marginRight: -4,
        },
        label: {
          paddingLeft: 10,
          paddingRight: 10,
        },
        labelSmall: {
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            '&.MuiPickersDay-root': {
              '&:focus': {
                backgroundColor: theme.palette.action.main,
              },
              '&:not(.Mui-selected):hover': {
                backgroundColor: theme.palette.action.main,
              },
              '&:not(.Mui-disabled)': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.background,
                '&:focus': {
                  backgroundColor: theme.palette.action.main,
                },
              },
              '&.Mui-selected': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
              },
              '&.Mui-disabled:not(.Mui-selected)': {
                color: grey[700],
              },
              '&.Mui-disabled': {
                '&.selectable': {
                  cursor: 'pointer',
                  pointerEvents: 'unset',

                  '&.Mui-selected': {
                    opacity: 1,
                  },
                },
              },
            },
          };
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            lineHeight: '1rem',
            '&:hover': {
              backgroundColor: getButtonWithoutBgHoverColor(ownerState.color),
            },
          };
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#AFCCA3',
        },
        barColorPrimary: {
          backgroundColor: '#367F19',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          color: '#01628C',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: { '&.Mui-completed': { color: '#185B0C' } },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: { '&.Mui-completed': { color: '#367F19' } },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          backgroundColor: '#FFFFFF',
          '&.Mui-disabled': { backgroundColor: grey[100] },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '&.MuiPickersPopper-paper': {
            padding: 'unset',
            maxWidth: 'min-content',
          },
        },
        rounded: {
          borderRadius: '15px',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          lineHeight: 1,
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: alpha('#000000', 0.54),
          fontSize: '0.75rem',
        },
        select: {
          color: alpha('#000000', 0.87),
          fontSize: '0.75rem',
        },
        displayedRows: {
          color: alpha('#000000', 0.87),
          fontSize: '0.75rem',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
        textColor: 'inherit',
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          flex: 1,
          fontSize: '0.9375rem',
          color: '#123034',
          [`&.${toggleButtonClasses['selected']}`]: {
            backgroundColor: alpha('#123034', 0.08),
            color: '#123034',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        exclusive: true,
        size: 'small',
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
        },
        action: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
  palette,
  typography: {
    fontFamily: FONT_FAMILY,
  },
  shadows,
});

export default theme;
