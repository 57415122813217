import React, { useState } from 'react';
import Icon from 'react-eva-icons';
import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Menu, MenuItem } from './Menu';

export interface IconMenuProps {
  menuIcon?: string;
  disabled?: boolean;
  items: MenuItem[];
}

export const IconMenu: React.FC<IconMenuProps> = ({
  items,
  menuIcon,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        disabled={disabled}
        onClick={handleOpenMenu}
        data-testid="OPEN_MENU_ICON"
      >
        <Icon
          name={menuIcon || 'more-vertical-outline'}
          size="large"
          fill={grey[900]}
        />
      </IconButton>
      <Menu
        items={items}
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        open={open}
      />
    </Box>
  );
};
