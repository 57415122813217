import React, { useMemo } from 'react';
import { differenceInDays, endOfDay, isPast } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import {
  DatePickerSlotsComponents,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  PickersDay,
  PickersDayProps,
  esES,
} from '@mui/x-date-pickers';
import { capitalize } from 'lodash';

import TextField, { TextFieldProps } from '../TextField/TextField';
import { TextFieldV2 } from '../V2Wrapper';

export type DatePickerDisabledMode = 'notSelectable' | 'selectable';

interface CustomPickersDayProps extends PickersDayProps<Date> {
  shouldDisableDate: (date: Date) => boolean;
  disabledMode: DatePickerDisabledMode;
}

const CustomPickersDay: React.FC<CustomPickersDayProps> = ({
  shouldDisableDate,
  disabledMode,
  ...props
}) => {
  const shouldDisable = shouldDisableDate(props.day);

  const className = shouldDisable
    ? `Mui-disabled ${disabledMode}`
    : `${disabledMode}`;

  const disabled = disabledMode === 'notSelectable' ? shouldDisable : false;

  return <PickersDay className={className} {...props} disabled={disabled} />;
};

type CustomSlots = Omit<DatePickerSlotsComponents<Date>, 'day'> & {};

export interface DatePickerProps
  extends Omit<MuiDatePickerProps<Date>, 'title' | 'slotsProps' | 'minDate'> {
  title?: TextFieldProps['title'];
  i18nKeyTitle?: TextFieldProps['i18nKeyTitle'];
  locale?: string | null;
  onBlur?: TextFieldProps['onBlur'];
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  minDate?: () =>
    | MuiDatePickerProps<Date>['minDate']
    | MuiDatePickerProps<Date>['minDate'];
  required?: TextFieldProps['required'];
  shouldDisablePastDates?: boolean;
  showSkeleton?: TextFieldProps['showSkeleton'];
  disabledMode?: DatePickerDisabledMode;
  name?: TextFieldProps['name'];
  v2?: TextFieldProps['v2'];
  ['data-testid']?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  title,
  i18nKeyTitle,
  onBlur,
  error,
  helperText,
  required,
  shouldDisableDate: shouldDisableDateProp,
  shouldDisablePastDates,
  showSkeleton,
  v2,
  slots,
  locale = 'en-US',
  minDate: minDateProp,
  name,
  'data-testid': dataTestId,
  disabledMode = 'notSelectable',
  ...muiProps
}) => {
  const TextFieldComponent = v2 ? TextFieldV2 : TextField;

  const esLocaleMonthUpper: Locale = useMemo(() => {
    return {
      ...esLocale,
      localize: esLocale.localize
        ? {
            ...esLocale.localize,
            month: (number, width) => {
              const month = esLocale.localize?.month(number, width);
              return capitalize(month);
            },
          }
        : undefined,
    };
  }, []);

  const minDate = useMemo(
    () => (typeof minDateProp === 'function' ? minDateProp() : minDateProp),
    [minDateProp]
  );

  const shouldDisableDate = (date: Date) => {
    if (shouldDisablePastDates && isPast(endOfDay(date))) {
      return true;
    }

    if (minDate) {
      return differenceInDays(date, minDate) < 0;
    }

    if (shouldDisableDateProp) {
      return shouldDisableDateProp(date);
    }

    return false;
  };

  const adapterLocale = locale === 'es-MX' ? esLocaleMonthUpper : undefined;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
      localeText={
        locale === 'es-MX'
          ? {
              ...esES.components.MuiLocalizationProvider.defaultProps
                .localeText,
              fieldYearPlaceholder: () => 'A'.repeat(4),
            }
          : undefined
      }
    >
      <MuiDatePicker
        {...muiProps}
        sx={{
          '.MuiPaper-root': {
            maxWidth: 'min-content',
          },
          ...muiProps.sx,
        }}
        slots={
          {
            textField: TextFieldComponent,
            day: CustomPickersDay,
            ...slots,
          } as CustomSlots
        }
        slotProps={{
          day: (props) => ({
            day: props.day,
            selected: props.selected,
            disabled: props.disabled,
            disabledMode,
            shouldDisableDate,
          }),
          textField: {
            title,
            i18nKeyTitle,
            onBlur,
            error,
            helperText,
            required,
            showSkeleton,
            name,
            'data-testid': dataTestId,
          } as TextFieldProps,
        }}
      />
    </LocalizationProvider>
  );
};
