import { Box, Paper, styled } from '@mui/material';
import React, { useCallback } from 'react';

import { formatDate } from '../../utils';
import { IconMenu } from '../Menu/IconMenu';
import { MenuItem } from '../Menu/Menu';
import { Typography } from '../Typography/Typography';

const Card = styled(Paper)(({ theme }) => ({
  display: 'flex',
  width: '350px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  border: '0.5px solid',
  borderColor: theme.palette.divider,
  margin: '0px 24px 24px 0px',
  marginLeft: 0,
  boxShadow: theme.shadows[2],
}));

const iconByAction: Record<Exclude<Action, 'custom'>, string> = {
  archive: 'archive-outline',
  edit: 'edit-outline',
  delete: 'trash-2-outline',
  duplicate: 'copy-outline',
  preview: 'eye-outline',
};

const labelByAction: Record<Exclude<Action, 'custom'>, string> = {
  archive: 'Archive',
  edit: 'Edit',
  delete: 'Delete',
  duplicate: 'Duplicate',
  preview: 'Preview',
};

export type Action =
  | 'archive'
  | 'edit'
  | 'delete'
  | 'duplicate'
  | 'preview'
  | 'custom';

interface BaseProps {
  type: Action;
  testId?: string;
  onClick: () => void;
}

interface CustomAction extends BaseProps {
  type: 'custom';
  label: string;
  icon?: string;
}
interface DefaultAction extends BaseProps {
  type: Exclude<Action, 'custom'>;
}

export type ActionItem = CustomAction | DefaultAction;

export interface ActionCardProps {
  title: string;
  subtitle?: string;
  actionDate?: Date;
  actions: ActionItem[];
}

export const ActionCard: React.FC<ActionCardProps> = ({
  title,
  subtitle,
  actionDate,
  actions,
}) => {
  const getMenuItems = useCallback((): MenuItem[] => {
    return actions.map((action) => {
      if (action.type === 'custom') {
        return {
          testId: action.testId,
          handleClick: action.onClick,
          label: action.label,
          icon: action.icon,
        };
      }
      return {
        testId: `${action.type.toUpperCase()}_MENU_OPTION`,
        handleClick: action.onClick,
        label: labelByAction[action.type],
        icon: iconByAction[action.type],
      };
    });
  }, [actions]);

  const currentSubtitle = subtitle
    ? subtitle
    : actionDate
    ? `last updated on ${formatDate(actionDate, 'dayOfMonth')}`
    : '';

  return (
    <Card data-testid={`${title.toUpperCase()}_ACTION_CARD`}>
      <Box textOverflow="ellipsis" overflow="hidden">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{currentSubtitle}</Typography>
      </Box>
      <IconMenu items={getMenuItems()} />
    </Card>
  );
};
