import React, { FC } from 'react';
import { Box, Grid, GridSize, RegularBreakpoints } from '@mui/material';

import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { Typography } from '../Typography/Typography';

interface LabelBoxCommonProps {
  label: string;
  value?: string | JSX.Element | null;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  v2?: boolean;
  hideMargin?: boolean;
  showSkeleton?: boolean;
  showRequiredLabel?: boolean;
}

export type LabelBoxProps =
  | (LabelBoxCommonProps & {
      /**
       * @deprecated Use `breakpoints` instead
       */
      size?: boolean | GridSize;
      breakpoints?: never;
    })
  | (LabelBoxCommonProps & { size?: never; breakpoints?: RegularBreakpoints });

export const LabelBox: FC<LabelBoxProps> = ({
  label,
  value,
  endAdornment,
  startAdornment,
  v2,
  hideMargin,
  showSkeleton = false,
  showRequiredLabel = false,
  ...conditionalProps
}) => {
  const shouldRenderLabelValue = typeof value === 'string' || value == null;

  /************ V2 updates *********************/

  const titleVariant = v2 ? 'body1' : 'subtitle2';
  const titleFontWeight = v2 ? 'bold' : undefined;

  /*********************************************/
  const valueFontStyle = !value && showRequiredLabel ? 'italic' : 'normal';
  const valueFontColor = !value && showRequiredLabel ? 'error' : undefined;

  let breakpoints = {};

  if ('size' in conditionalProps && conditionalProps.size) {
    breakpoints = { xs: conditionalProps.size };
  } else if (
    'breakpoints' in conditionalProps &&
    conditionalProps.breakpoints
  ) {
    breakpoints = conditionalProps.breakpoints;
  }

  return (
    <Grid item {...breakpoints}>
      <Box
        marginBottom={hideMargin ? 0 : 5}
        data-testid={label.toUpperCase().replace(/ /g, '_')}
      >
        <SkeletonWrapper isLoading={showSkeleton}>
          <ConditionalWrapper
            showWrapper={!!endAdornment}
            wrapper={(children) => {
              return (
                <Box display="flex" columnGap={1} alignItems="center">
                  {children}
                </Box>
              );
            }}
          >
            {startAdornment}
            <Typography
              variant={titleVariant}
              fontWeight={titleFontWeight}
              sx={{ wordBreak: 'break-word' }}
            >
              {label}
            </Typography>
            {endAdornment}
          </ConditionalWrapper>
          {shouldRenderLabelValue ? (
            <Typography
              variant="body1"
              data-testid="LABEL_VALUE"
              sx={{ overflowWrap: 'anywhere', wordBreak: 'break-word' }}
              fontStyle={valueFontStyle}
              color={valueFontColor}
            >
              {value
                ? value
                : showRequiredLabel
                ? 'Required missing data'
                : '-'}
            </Typography>
          ) : (
            value
          )}
        </SkeletonWrapper>
      </Box>
    </Grid>
  );
};
