import {
  Box,
  AppBar as MuiAppBar,
  Theme,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

import { DrawerMenu, DrawerMenuProps } from '../DrawerMenu/DrawerMenu';
import { APP_BAR_HEIGHT } from '../../utils';
import { IconMenu, IconMenuProps } from '../Menu/IconMenu';
import { Avatar } from '../Avatar/Avatar';

const Offset = styled('div')(() => ({
  height: APP_BAR_HEIGHT,
}));

export type AppBarDrawerProps = DrawerMenuProps & {
  i18nKey?: string;
};
export type AppBarUserMenuProps = {
  options: IconMenuProps['items'];
  name: string;
  picture?: string;
  fetchPicture: (picture: string) => Promise<string | undefined>;
  onAvatarClick?: () => void;
};

export interface LogoProps {
  url: string;
  onClick: () => void;
}

export interface AppBarProps {
  hasTransparentBackground?: boolean;
  shouldHide?: boolean;
  drawerProps: AppBarDrawerProps;
  logoProps: LogoProps;
  userMenuProps: AppBarUserMenuProps;
}

export const AppBar: React.FC<AppBarProps> = ({
  hasTransparentBackground,
  shouldHide,
  drawerProps,
  logoProps,
  userMenuProps,
}) => {
  const theme = useTheme();

  const isBelowMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const headerBgConditionalStyles = hasTransparentBackground
    ? { backgroundColor: 'transparent', borderBottom: 'none' }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottom: '1px solid',
        borderColor: theme.palette.divider,
      };

  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          display: shouldHide ? 'none' : 'unset',
          ...headerBgConditionalStyles,
        }}
        data-testid="APP_BAR"
      >
        <Toolbar
          disableGutters
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 4,
            height: APP_BAR_HEIGHT,
          }}
        >
          <Box width={250}>
            <DrawerMenu {...drawerProps} />
          </Box>
          <Box display="flex" justifyContent="center">
            <img
              style={{ cursor: 'pointer' }}
              src={logoProps.url}
              alt="Faeth Logo"
              onClick={logoProps.onClick}
              data-testid="FAETH_APP_BAR_ICON"
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={250}
            data-testid="PROFILE_MENU"
          >
            <Avatar
              name={userMenuProps.name}
              picture={userMenuProps.picture}
              type={isBelowMedium ? 'imageOnly' : 'imageText'}
              size={40}
              downloadPicture={userMenuProps.fetchPicture}
              onClick={userMenuProps.onAvatarClick}
            />
            <Box marginLeft={1}>
              <IconMenu
                items={userMenuProps.options}
                menuIcon="arrow-ios-downward-outline"
              />
            </Box>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Offset sx={{ display: shouldHide ? 'none' : 'unset' }} />
    </>
  );
};
