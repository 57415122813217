import { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { Box, Checkbox, FormControlLabel, Link, Stack } from '@mui/material';
import {
  Button,
  PasswordTextField,
  Typography,
  useSnackbar,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { getTranslatedErrorMessage } from '@fdha/common-utils';
import { Trans } from 'react-i18next';

import { PasswordRule, PasswordRuleUtils } from '../utils/passwordRuleUtils';
import { PrivacyPolicyUtils, TermsAndConditionsUtils } from '../utils';

import { ChangePasswordFormFields } from './ResetPassword';

interface ChangePasswordErrorsProps {
  newPasswordError: string;
  confirmNewPasswordError: string;
}

interface ChangePasswordFormProps {
  errors: ChangePasswordErrorsProps;
  hint?: string;
  passwordHint?: PasswordRule[];
  handleSubmit: (
    values: ChangePasswordFormFields,
    setErrors: (errors: FormikErrors<ChangePasswordFormFields>) => void
  ) => void;
  v2?: boolean;
  showTosToggle?: boolean;
  showSmsToggle?: boolean;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  errors,
  hint,
  passwordHint,
  handleSubmit,
  v2,
  showTosToggle,
  showSmsToggle,
}) => {
  const acceptTermsMessage = getTranslatedErrorMessage('acceptTerms', 'web');
  const requiredMessage = getTranslatedErrorMessage('required', 'web');
  const matchPasswordMessage = getTranslatedErrorMessage(
    'matchPassword',
    'web'
  );

  const [rulesErrorList, setRulesErrorList] = useState<string[] | undefined>(
    undefined
  );
  const [terms, setTerms] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const { showSnackbarV2 } = useSnackbar();

  const navigate = useNavigate();

  /************ V2 updates *********************/

  const titleNewPassword = v2 ? 'New Password' : 'New password';
  const titleConfirmNewPassword = v2
    ? 'Confirm New Password'
    : 'Confirm new password';
  const placeholderNewPassword = v2 ? '' : 'New password...';
  const placeholderConfirmNewPassword = v2 ? '' : 'Confirm new password...';
  const buttonLabel = v2 ? 'Set Password' : 'Change password';
  const buttonColor = v2 ? 'primary' : 'secondary';
  const buttonSize = v2 ? 'large' : 'medium';
  const newPasswordErrorMessage = v2 ? undefined : errors.newPasswordError;

  const schema = Yup.object().shape({
    newPassword: Yup.string().required(requiredMessage),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], matchPasswordMessage)
      .required(requiredMessage),
  });

  const tosSchema = Yup.object().shape({
    acceptedTos: Yup.bool().oneOf([true], acceptTermsMessage),
  });

  const v2ValidationSchema = v2 ? schema : null;

  const validationSchema = showTosToggle
    ? v2ValidationSchema?.concat(tosSchema)
    : v2ValidationSchema;

  /***********************************/

  const handleValidate = (values: ChangePasswordFormFields) => {
    const errors: Partial<ChangePasswordFormFields> = {};

    if (values.newPassword && v2) {
      setRulesErrorList(
        PasswordRuleUtils.getPasswordRulesListErrorV2(values.newPassword.trim())
      );
    }

    return errors;
  };

  useEffect(() => {
    const getTerms = async () => {
      const termsLink = await TermsAndConditionsUtils.getTermsLink();
      setTerms(termsLink);
    };
    const getPrivacyPolicy = async () => {
      const privacyPolicyLink = await PrivacyPolicyUtils.getPolicyLink();
      setPrivacyPolicy(privacyPolicyLink);
    };

    if (!terms) {
      getTerms();
    }

    if (!privacyPolicy) {
      getPrivacyPolicy();
    }
  }, [terms, privacyPolicy]);

  const getLink = (link: string) => <Link href={link} target="_blank" />;
  const termsLink = getLink(terms);
  const privacyLink = getLink(privacyPolicy);

  const getCheckBoxLabel = () => {
    return (
      <Trans
        i18nKey="login:changePassword.terms.agree"
        components={{ termsLink, privacyLink }}
      >
        I agree to the{' '}
        <Link href={terms} target="_blank">
          terms
        </Link>{' '}
        and{' '}
        <Link href={privacyPolicy} target="_blank">
          privacy policy
        </Link>
        .
      </Trans>
    );
  };

  const handleErrors = (errors: FormikErrors<ChangePasswordFormFields>) => {
    if (errors.acceptedTos) {
      showSnackbarV2({
        message: 'You need to accept the terms and privacy policy to continue.',
        i18nKey: 'login:changePassword.terms.snackbar.acceptTermsContinue',
        severity: 'error',
      });
    }
  };

  const initialValues: ChangePasswordFormFields = {
    newPassword: '',
    confirmNewPassword: '',
    acceptedTos: false,
    acceptedSms: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
      validate={handleValidate}
      validationSchema={validationSchema}
    >
      {({
        values,
        isSubmitting,
        errors: formErrors,
        touched,
        handleChange,
        handleBlur,
      }) => {
        const showRulesError = touched.newPassword && !!rulesErrorList?.length;
        const disabled = v2
          ? isSubmitting
          : !values.newPassword || !values.confirmNewPassword || isSubmitting;

        const error = {
          newPassword: v2
            ? touched.newPassword && formErrors.newPassword
            : newPasswordErrorMessage,
          confirmNewPassword: v2
            ? touched.confirmNewPassword && formErrors.confirmNewPassword
            : errors.confirmNewPasswordError,
          acceptedTos: showTosToggle ? formErrors.acceptedTos : null,
        };

        const newPasswordHint = v2 ? error.newPassword?.toString() : hint;

        return (
          <Form style={{ width: '100%' }}>
            <Stack spacing={2}>
              <PasswordTextField
                v2={v2}
                name="newPassword"
                title={titleNewPassword}
                i18nKeyTitle="login:changePassword.input.password"
                placeholder={placeholderNewPassword}
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                hint={newPasswordHint}
                passwordHint={passwordHint}
                errorMessage={newPasswordErrorMessage}
                error={!!error.newPassword}
                rulesErrorList={rulesErrorList}
                showRulesError={showRulesError}
              />
              <PasswordTextField
                v2={v2}
                name="confirmNewPassword"
                title={titleConfirmNewPassword}
                i18nKeyTitle="login:changePassword.input.confirmPassword"
                placeholder={placeholderConfirmNewPassword}
                value={values.confirmNewPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!error.confirmNewPassword}
                helperText={error.confirmNewPassword}
                errorMessage={errors.confirmNewPasswordError}
              />
            </Stack>
            <Box mt={2}>
              {showSmsToggle && (
                <FormControlLabel
                  sx={{ alignItems: 'flex-start' }}
                  control={<Checkbox color="secondary" />}
                  name="acceptedSms"
                  checked={values.acceptedSms}
                  onChange={handleChange}
                  label={
                    <Typography
                      i18nKey="login:changePassword.agreeSms"
                      variant="inherit"
                    >
                      I agree to receive SMS messages from Faeth. Message and
                      data rates may apply.
                    </Typography>
                  }
                />
              )}
              {showTosToggle && (
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  name="acceptedTos"
                  checked={values.acceptedTos}
                  onChange={handleChange}
                  label={getCheckBoxLabel()}
                />
              )}
            </Box>
            <Box display="flex" marginY={3}>
              {v2 && (
                <Button
                  size={buttonSize}
                  variant="text"
                  color={buttonColor}
                  onClick={() => navigate('/', { replace: true })}
                  sx={{ flex: 1, mr: 2 }}
                  data-testid="CHANGE_PASSWORD_BACK_BUTTON"
                  i18nKey="common:button.back"
                >
                  Back
                </Button>
              )}
              <Button
                type="submit"
                size={buttonSize}
                variant="contained"
                color={buttonColor}
                disabled={disabled}
                sx={{ flex: 2, height: '42px' }}
                onClick={() => handleErrors(formErrors)}
                data-testid="SET_PASSWORD_BUTTON"
                i18nKey="login:changePassword.setPassword"
              >
                {buttonLabel}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
