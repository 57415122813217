import React from 'react';
import { useNavigate } from 'react-router';

import { BasePage } from '../../../components';

import { AddOrEditCoachSiteTrial } from './AddOrEditCoachSiteTrial';

const AddCoachSiteTrial = () => {
  const navigate = useNavigate();

  return (
    <BasePage>
      <BasePage.BackButton />
      <BasePage.Wrapper>
        <BasePage.Title>Assign Site/Trial to Coach</BasePage.Title>
        <AddOrEditCoachSiteTrial
          onCancel={() => navigate(-1)}
          onSuccess={(coachId) => navigate(`/coach/${coachId}`)}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default AddCoachSiteTrial;
