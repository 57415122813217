import React, { FC, useEffect, useState } from 'react';
import { confirmResetPassword } from '@aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextFieldV2,
  Typography,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { FormikErrors } from 'formik';
import { getTranslatedErrorMessage } from '@fdha/common-utils';

import {
  getHintMessage,
  PasswordRule,
  PasswordRuleUtils,
} from '../utils/passwordRuleUtils';

import ChangePasswordForm from './ChangePasswordForm';

export interface ChangePasswordFormFields {
  newPassword: string;
  confirmNewPassword: string;
  acceptedSms?: boolean;
  acceptedTos?: boolean;
}

interface FieldErrors {
  code: string;
  newPassword: string;
  confirmNewPassword: string;
  passwordTips: string;
}

export interface ResetPasswordProps {
  v2?: boolean;
  email?: string;
}

export const ResetPassword: FC<ResetPasswordProps> = ({ v2, email }) => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const incorrectPasswordMessage = getTranslatedErrorMessage(
    'incorrectPasswordFormat',
    'web'
  );
  const passwordsDontMatchMessage = getTranslatedErrorMessage(
    'passwordsDontMatch',
    'web'
  );
  const invalidCodeMessage = getTranslatedErrorMessage('invalidCode', 'web');
  const incorrectCodeMessage = getTranslatedErrorMessage(
    'incorrectNumberOfCodeDigits',
    'web'
  );

  const params = new URLSearchParams(location.search);
  const code = params.get('code') || '';
  const username = params.get('username') || email || '';

  const initialFieldErrors = {
    code: '',
    newPassword: '',
    confirmNewPassword: '',
    passwordTips: '',
  };

  const [fieldErrors, setFieldErrors] =
    useState<FieldErrors>(initialFieldErrors);
  const [hint, setPasswordHint] = useState('');
  const [passwordHint, setPasswordHintV2] = useState<
    PasswordRule[] | undefined
  >(undefined);
  const [validationCode, setValidationCode] = useState(code);

  const dialogType = {
    confirmed: {
      title: 'New password saved!',
      confirmButtonLabel: 'LOG IN',
      i18nKey: 'login:forgotPassword.dialog.confirmed',
      i18nKeyConfirmButton: 'login:dialog.confirmButtonLogin',
    },
    error: {
      title: 'Sorry, something went wrong. Please try again.',
      confirmButtonLabel: 'OK',
      i18nKey: 'login:forgotPassword.dialog.error',
    },
    errorExpiredCode: {
      title:
        'Sorry, this code has expired. Please generate a new code to reset your password.',
      confirmButtonLabel: 'OK',
      i18nKey: 'login:forgotPassword.dialog.errorExpiredCode',
    },
    errorLimitExceeded: {
      title:
        'Sorry, the limit of attempts was exceeded. Please try again later.',
      confirmButtonLabel: 'OK',
      i18nKey: 'login:forgotPassword.dialog.errorLimitExceeded',
    },
  };

  useEffect(() => {
    let isMounted = true;

    try {
      (async () => {
        if (v2) {
          const passwordHint =
            await PasswordRuleUtils.getPasswordHintMessageV2();
          if (isMounted) {
            setPasswordHintV2(passwordHint);
          }
        } else {
          const hint = await getHintMessage();
          if (isMounted) {
            setPasswordHint(hint);
          }
        }
      })();

      return () => {
        isMounted = false;
      };
    } catch (error) {
      console.log('error useEffect: ', error);
    }
  }, [v2]);

  const showDialog = (type: { title: string; confirmButtonLabel: string }) => {
    openDialog({
      title: type.title,
      content: null,
      confirmButtonLabel: type.confirmButtonLabel,
      cancelButtonLabel: '',
      handleConfirm: async () => {
        closeDialog();
        navigate('/', { replace: true });
      },
    });
  };

  const handleSubmit = async (
    values: ChangePasswordFormFields,
    setErrors: (errors: FormikErrors<ChangePasswordFormFields>) => void
  ) => {
    setFieldErrors(initialFieldErrors);
    setPasswordHint('');

    const newPasswordValue = values.newPassword.trim();
    const confirmNewPasswordValue = values.confirmNewPassword.trim();

    const rulesErrorList =
      PasswordRuleUtils.getPasswordRulesListError(newPasswordValue);

    if (rulesErrorList) {
      setFieldErrors({
        ...initialFieldErrors,
        passwordTips: rulesErrorList,
      });
      return;
    }

    if (newPasswordValue !== confirmNewPasswordValue) {
      setFieldErrors({
        ...initialFieldErrors,
        confirmNewPassword: passwordsDontMatchMessage,
      });
      return;
    }

    try {
      await confirmResetPassword({
        username,
        newPassword: values.newPassword,
        confirmationCode: validationCode,
      });
      if (v2) {
        showSnackbarV2({
          message: 'Password Reset',
          i18nKey: 'login:snackbar.passwordReset',
          severity: 'success',
        });
        navigate('/', { replace: true });
      } else {
        showDialog(dialogType.confirmed);
      }
    } catch (error: any) {
      if (error.code === 'InvalidPasswordException') {
        v2
          ? setErrors({ confirmNewPassword: incorrectPasswordMessage })
          : setFieldErrors({
              ...initialFieldErrors,
              newPassword: incorrectPasswordMessage,
            });
      } else if (v2 && error.code === 'CodeMismatchException') {
        setFieldErrors({
          ...initialFieldErrors,
          code: invalidCodeMessage,
        });
      } else if (v2 && error.code === 'ExpiredCodeException') {
        showSnackbarV2({
          message: dialogType.errorExpiredCode.title,
          severity: 'error',
          i18nKey: dialogType.errorExpiredCode.i18nKey,
        });
      } else if (v2 && error.code === 'LimitExceededException') {
        showSnackbarV2({
          message: dialogType.errorLimitExceeded.title,
          severity: 'error',
          i18nKey: dialogType.errorLimitExceeded.i18nKey,
        });
      } else {
        console.error(error);
        v2
          ? showSnackbarV2({
              message: dialogType.error.title,
              severity: 'error',
              i18nKey: dialogType.error.i18nKey,
            })
          : showDialog({
              title: dialogType.error.title,
              confirmButtonLabel: dialogType.error.confirmButtonLabel,
            });
      }
    }
  };

  return (
    <>
      {v2 && (
        <>
          <TextFieldV2
            value={validationCode}
            title="Validation code"
            i18nKeyTitle="login:resetPassword.input.validationCode"
            placeholder="000000"
            onChange={(value) => setValidationCode(value.target.value)}
            error={!!fieldErrors.code}
            helperText={fieldErrors.code}
            inputProps={{ maxLength: 6 }}
            onBlur={(event) => {
              if (event.target.value.length < 6) {
                setFieldErrors({
                  ...fieldErrors,
                  code: incorrectCodeMessage,
                });
              } else {
                setFieldErrors({
                  ...fieldErrors,
                  code: '',
                });
              }
            }}
          />
          <Typography
            data-testid="ENTER_YOUR_NEW_PASSWORD_TITLE"
            variant="h4"
            sx={{ marginTop: 3, marginBottom: 3 }}
            i18nKey="login:resetPassword.newPassword"
          >
            Enter your new password
          </Typography>
        </>
      )}
      <ChangePasswordForm
        v2={v2}
        hint={hint}
        passwordHint={passwordHint}
        errors={{
          newPasswordError: fieldErrors.passwordTips || fieldErrors.newPassword,
          confirmNewPasswordError: fieldErrors.confirmNewPassword,
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
