import { LinkProps as MuiLinkProps, Link as MuiLink } from '@mui/material';
import { useTranslation } from '@fdha/common-hooks';

import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';

export type LinkProps = {
  i18nKey?: string;
  showSkeleton?: boolean;
  component?: React.ElementType;
} & MuiLinkProps;

export const Link: React.FC<LinkProps> = (props) => {
  const { translate } = useTranslation();

  const { children, showSkeleton = false, component, ...muiProps } = props;

  const fullWidth = showSkeleton && children == null;

  return (
    <SkeletonWrapper isLoading={showSkeleton} fullWidth={fullWidth}>
      <MuiLink component={component || 'a'} {...muiProps}>
        {typeof props.i18nKey === 'string' && typeof children === 'string'
          ? translate(props.i18nKey, children)
          : children}
      </MuiLink>
    </SkeletonWrapper>
  );
};
