import React, { FC } from 'react';
import { List, ListItem, Paper, useTheme } from '@mui/material';

import { Icon } from '../Icon/Icon';
import PoweredByGoogle from '../../images/powered-by-google.png';
import { Typography } from '../Typography/Typography';

interface AddressMenuProps {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onSelectAddress: (address: string) => void;
}

export const AddressMenu: FC<AddressMenuProps> = ({
  placePredictions,
  onSelectAddress,
}) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 'unset',
      }}
    >
      <List>
        {placePredictions.map((item, index) => {
          const structured = item.structured_formatting;
          const matchingText = structured.main_text_matched_substrings[0];
          const boldText = structured.main_text.substring(
            matchingText.offset,
            matchingText.length
          );
          const regularText = structured.main_text.substring(
            matchingText.length
          );
          return (
            <ListItem
              key={index}
              onMouseDown={(event) => {
                onSelectAddress(item.description);
                event.stopPropagation();
                event.preventDefault();
              }}
              sx={{
                cursor: 'pointer',
                gap: 1,
                borderBottom:
                  placePredictions.length - 1 !== index
                    ? '1px solid'
                    : undefined,
                borderColor: theme.palette.divider,
              }}
            >
              <Icon name="pin" fill={theme.palette.text.hint} size="large" />
              <Typography variant="body2">
                <b>{boldText}</b>
                {regularText}
              </Typography>
              <Typography variant="caption" color={theme.palette.text.hint}>
                {structured.secondary_text}
              </Typography>
            </ListItem>
          );
        })}
      </List>
      <img
        src={PoweredByGoogle}
        alt="Powered by Google"
        width="131px"
        height="18px"
        style={{
          alignSelf: 'flex-end',
          marginRight: '16px',
          marginBottom: '8px',
        }}
      />
    </Paper>
  );
};
