import React from 'react';
import { useTheme } from '@mui/material';

import TextField, { TextFieldProps } from '../TextField/TextField';
import { Typography } from '../Typography/Typography';

export type DecimalTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  v2?: boolean;
  unit?: string;
  numberOfDecimals: number;
  maxLength: number;
  handleChange: (value: string) => void;
};

export const DecimalTextField = (props: DecimalTextFieldProps) => {
  const theme = useTheme();

  const {
    unit,
    numberOfDecimals = 1,
    maxLength = 3,
    handleChange,
    ...muiProps
  } = props;

  const maskDecimal = (value: string, oldValue?: string) => {
    const digits = value.match(/\d/g) || ['0'];

    while (digits.length <= numberOfDecimals) {
      digits.unshift('0');
    }

    if (numberOfDecimals > 0) {
      digits.splice(digits.length - numberOfDecimals, 0, '.');
    }

    const newDigits = Number(digits.join('')).toFixed(numberOfDecimals);
    const removeValue = !newDigits.match(/[^0.]/g);

    if (removeValue) {
      return '';
    }

    if (newDigits.length > maxLength) {
      return oldValue || '';
    }

    return newDigits.trim();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = maskDecimal(event.target.value, props.value as string);
    handleChange(newValue);
  };

  return (
    <TextField
      {...muiProps}
      onChange={onChange}
      endContent={
        unit && (
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            data-testid="TEXT_FIELD_UNIT"
          >
            {unit}
          </Typography>
        )
      }
    />
  );
};
