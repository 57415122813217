import {
  Box,
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  useTheme,
} from '@mui/material';
import React from 'react';

import { Typography } from '../Typography/Typography';

export interface CircularProgressProps extends MuiCircularProgressProps {
  v2?: boolean;
}

function CircularProgress(props: CircularProgressProps) {
  const theme = useTheme();

  const { v2, ...muiProps } = props;
  const hasValue = muiProps.value !== undefined && muiProps.value !== null;

  /************ V2 updates *********************/

  const labelColor = v2
    ? theme.palette.text.secondary
    : theme.palette.success.main;
  const withBorder = v2 && hasValue;

  /*********************************************/

  const renderWithBorder = () => {
    return (
      <>
        <MuiCircularProgress
          variant="determinate"
          sx={{ color: theme.palette.grey[200] }}
          {...muiProps}
          value={100}
        />
        <MuiCircularProgress
          variant="determinate"
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
          }}
          {...muiProps}
        />
      </>
    );
  };

  const renderWithoutBorder = () => {
    return <MuiCircularProgress variant="determinate" {...props} />;
  };

  return (
    <Box display="inline-flex" position="relative">
      {withBorder ? renderWithBorder() : renderWithoutBorder()}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.value !== undefined && (
          <Typography
            variant="caption"
            lineHeight="normal"
            component="div"
            color={labelColor}
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

export default CircularProgress;
