import { Breakpoint } from '@mui/material';
import React, { useState, useContext } from 'react';

import Dialog from '../components/Dialog/Dialog';

type VisibleDialogContent = DialogContent & {
  visible: boolean;
};

export type DialogContent = {
  v2?: boolean;
  title?: string;
  i18nKeyTitle?: string;
  i18nTitleParams?: { [key: string]: string };
  subtitle?: string;
  i18nKeySubtitle?: string;
  i18nSubtitleParams?: { [key: string]: string };
  content: React.ReactNode;
  i18nKeyContent?: string;
  confirmButtonLabel?: string;
  i18nKeyConfirmButton?: string;
  cancelButtonLabel?: string;
  i18nKeyCancelButton?: string;
  maxWidth?: Breakpoint;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  hidePadding?: boolean;
  hideTopPadding?: boolean;
  headerButtons?: React.ReactNode;
  hideScroll?: boolean;
  handleConfirm?: () => Promise<void>;
  handleCancel?: () => void;
  handleDismiss?: () => void;
  handleBack?: () => void;
};

export type UpdateDialogContent = Omit<DialogContent, 'content'>;

interface ContextProps {
  dialog: VisibleDialogContent;
  openDialog: (dialog: DialogContent) => void;
  openDialogV2: (dialog: DialogContent) => void;
  closeDialog: () => void;
  updateDialogProps: (dialog: UpdateDialogContent) => void;
}

const initialState: VisibleDialogContent = {
  v2: false,
  title: '',
  i18nKeyTitle: '',
  i18nTitleParams: {},
  subtitle: '',
  i18nKeySubtitle: '',
  i18nSubtitleParams: {},
  visible: false,
  content: null,
  i18nKeyContent: '',
  confirmButtonLabel: '',
  i18nKeyConfirmButton: '',
  cancelButtonLabel: '',
  i18nKeyCancelButton: '',
  maxWidth: 'sm',
  showCloseButton: false,
  showBackButton: false,
  headerButtons: null,
  hidePadding: false,
  hideTopPadding: false,
  hideScroll: false,
};

const Context = React.createContext<ContextProps>({
  dialog: initialState,
  openDialog: () => {},
  openDialogV2: () => {},
  closeDialog: () => {},
  updateDialogProps: () => {},
});

export const DialogProvider = ({ ...props }) => {
  const [dialog, setDialog] = useState<VisibleDialogContent>(initialState);

  const handleOpenDialog = (newDialogContent: DialogContent) => {
    setDialog({
      ...newDialogContent,
      visible: true,
    });
  };

  const handleOpenDialogV2 = (newDialogContent: DialogContent) => {
    handleOpenDialog({
      ...newDialogContent,
      v2: true,
    });
  };

  const handleCloseDialog = () => {
    setDialog(initialState);
  };

  const handleUpdateDialogProps = (newDialogContent: UpdateDialogContent) => {
    setDialog({ ...dialog, ...newDialogContent, visible: true });
  };

  const DialogContextProvider = Context.Provider;
  return (
    <DialogContextProvider
      value={{
        dialog,
        openDialog: handleOpenDialog,
        openDialogV2: handleOpenDialogV2,
        closeDialog: handleCloseDialog,
        updateDialogProps: handleUpdateDialogProps,
      }}
    >
      {props.children}
      <Dialog />
    </DialogContextProvider>
  );
};

export const useDialog = () => {
  const { dialog, openDialog, openDialogV2, closeDialog, updateDialogProps } =
    useContext(Context);
  return {
    dialog,
    openDialog,
    openDialogV2,
    closeDialog,
    updateDialogProps,
  };
};
