import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps as MuiMenuProps,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import Icon from 'react-eva-icons';

import { Typography } from '../Typography/Typography';

export interface MenuItem {
  label: string;
  i18nKeyLabel?: string;
  icon?: string;
  iconEnd?: string;
  testId?: string;
  disabled?: boolean;
  handleClick: () => void;
}

interface MenuProps extends MuiMenuProps {
  handleClose: () => void;
  items: MenuItem[];
  closeOnClick?: boolean;
}

export const Menu: React.FC<MenuProps> = ({
  anchorEl,
  open,
  handleClose,
  items,
  closeOnClick = true,
}) => {
  const handleOptionSelect = (itemCallback: () => void) => {
    itemCallback();

    if (closeOnClick) {
      handleClose();
    }
  };

  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{ padding: 1 }}
      data-testid="MENU_ITEMS"
    >
      {items.map((item, index) => (
        <MuiMenuItem
          onClick={() => handleOptionSelect(item.handleClick)}
          data-testid={item.testId}
          key={index}
          disabled={item.disabled}
        >
          {item.icon && (
            <ListItemIcon sx={{ lineHeight: 0 }}>
              <Icon name={item.icon} size="large" fill={grey[900]} />
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography i18nKey={item.i18nKeyLabel}>{item.label}</Typography>
          </ListItemText>
          {item.iconEnd && (
            <ListItemIcon sx={{ lineHeight: 0, paddingLeft: 10 }}>
              <Icon name={item.iconEnd} size="large" fill={grey[900]} />
            </ListItemIcon>
          )}
        </MuiMenuItem>
      ))}
    </MuiMenu>
  );
};
