import { useTheme } from '@mui/material';
import React from 'react';

import { Typography } from '../Typography/Typography';

export interface InputTitleProps {
  title: string;
  hideMargin?: boolean;
  required?: boolean;
  v2: boolean;
  i18nKey?: string;
}

export const InputTitle: React.FC<InputTitleProps> = ({
  title,
  hideMargin,
  required,
  v2,
  i18nKey,
}) => {
  const theme = useTheme();
  /************ V2 updates *********************/

  const titleMargin = hideMargin ? 0 : v2 ? 1 : '6px';
  const titleFontWeight = v2 ? 'bold' : 'medium';
  const titleVariant = v2 ? 'body1' : 'subtitle2';
  const titleSuffix = required && v2 ? '*' : '';

  /*********************************************/
  return (
    <Typography
      variant={titleVariant}
      fontWeight={titleFontWeight}
      marginBottom={titleMargin}
      display="flex"
      flexDirection="row"
    >
      <Typography
        i18nKey={i18nKey}
        i18nParams={{ suffix: titleSuffix }}
        variant="inherit"
      >
        {`${title}${titleSuffix}`}
      </Typography>
      {required && !v2 ? (
        <Typography fontWeight="bold" color={theme.palette.error.main}>
          *
        </Typography>
      ) : (
        required === false && (
          <Typography color={theme.palette.text.hint} fontStyle="italic">
            &nbsp;(optional)
          </Typography>
        )
      )}
    </Typography>
  );
};
