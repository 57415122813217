import * as Yup from 'yup';

import { CommunityUser, PostSchema, UserType } from '../components';

export enum CommunityRole {
  Moderator = 'moderator',
  User = 'user',
}

export interface Comment {
  id: string;
  isEdited: boolean;
  isRemoved: boolean;
  postId: string;
  text: string;
  time: string;
  isPersisted: boolean;
  removedAt?: string;
  removedBy?: {
    id: string;
    name: string;
    isBanned: boolean;
    role: CommunityRole;
  } | null;
  user: CommunityUser;
}

export const handleClickProfile = (
  type?: UserType | null,
  userId?: string | null,
  isMine?: boolean,
  onClickProfile?: (url: string) => void
) => {
  if (onClickProfile) {
    let url = '';

    if (isMine) {
      url = '/profile';
    } else {
      switch (type) {
        case UserType.Coach:
          url = `/coach/${userId}`;
          break;
        case UserType.Caretaker:
        case UserType.ClinicalTrialPatient:
        case UserType.Patient:
          url = `/patient/${userId}`;
          break;
      }
    }

    onClickProfile(url);
  }
};

export const timeAgoOverrideFn =
  (communityItem: Comment | PostSchema, isRemoved: boolean) =>
  (timeAgo: string): string => {
    const result =
      communityItem.isPersisted || isRemoved
        ? communityItem.isEdited
          ? `${timeAgo} | edited`
          : timeAgo
        : communityItem.isEdited
        ? 'Editing...'
        : 'Publishing...';

    return result;
  };

export interface CommentSchema {
  text: string;
}

export const commentInitialValues: CommentSchema = {
  text: '',
};

export const commentValidationSchema = Yup.object().shape({
  text: Yup.string()
    .trim()
    .required('Please add some text')
    .max(280, 'You have exceeded the character limit.'),
});
export interface WritePostSchema {
  text: string;
  picture: string;
}

export const postValidationSchema = Yup.object().shape(
  {
    text: Yup.string()
      .max(280, 'You have exceeded the character limit.')
      .when(['picture'], {
        is: (picture: string) => !picture,
        then: Yup.string().trim().required('required'),
      }),
    picture: Yup.string().when(['text'], {
      is: (text: string) => !text,
      then: Yup.string().trim().required('required'),
    }),
  },
  [['text', 'picture']]
);
