import { SelectChangeEvent } from '@mui/material';
import React, { FC } from 'react';

import { RangePeriod } from '../DateRangePicker/DateRangePicker';
import { Select, SelectOption } from '../Select/Select';

const datePeriodOptions: SelectOption[] = [
  {
    label: 'Day',
    value: RangePeriod.DAY,
    i18nKey: 'activities:datePeriod.day',
  },
  {
    label: 'Week',
    value: RangePeriod.WEEK,
    i18nKey: 'activities:datePeriod.week',
  },
  {
    label: 'Month',
    value: RangePeriod.MONTH,
    i18nKey: 'activities:datePeriod.month',
  },
];

export interface DatePeriodSelectProps {
  v2?: boolean;
  rangePeriod?: RangePeriod;
  onChange: (option: SelectChangeEvent<unknown>) => void;
}

export const DatePeriodSelect: FC<DatePeriodSelectProps> = ({
  v2,
  rangePeriod = RangePeriod.DAY,
  onChange,
}) => {
  return (
    <Select
      v2={v2}
      sx={{ minWidth: v2 ? '85px' : '140px' }}
      options={datePeriodOptions}
      value={rangePeriod}
      onChange={onChange}
    />
  );
};
