import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SupportedLanguage, Trial } from '@fdha/graphql-api-admin';
import { Grid, Paper, useTheme } from '@mui/material';
import {
  Autocomplete,
  Button,
  Icon,
  MaskedTextField,
  RadioGroup,
  RadioOption,
  Select,
  Option,
} from '@fdha/web-ui-library';
import { getFormikError } from '@fdha/common-utils';

import { STATUS_OPTIONS, getSiteIDMaxLength } from '../../utils';

import { SiteSchema } from './AddOrEditSite';

const OnboardingCallOptions: RadioOption[] = [
  {
    label: 'Enabled',
    value: 'true',
  },
  { label: 'Disabled', value: 'false' },
];

interface SiteTrialsFormProps {
  languages?: SupportedLanguage[] | null;
  trials?: Trial[] | null;
  onAddTrial: () => void;
  onRemoveTrial: (index: number) => void;
}

export const SiteTrialsForm: React.FC<SiteTrialsFormProps> = ({
  trials,
  languages,
  onRemoveTrial,
  onAddTrial,
}) => {
  const theme = useTheme();

  const { handleBlur, values, errors, setFieldValue, touched } =
    useFormikContext<SiteSchema>();

  const languagesOptions = useMemo(
    () =>
      (languages ?? []).map((language) => {
        if (!language.nameEnglish || !language.code)
          throw new Error('Language is undefined');

        return {
          label: language.nameEnglish,
          value: language.code,
        };
      }),
    [languages]
  );

  const trialsOptions = useMemo(
    () =>
      trials?.map((trial) => ({
        label: trial.protocol_number || '',
        id: trial.id,
      })) ?? [],
    [trials]
  );

  const getError = (name: string) => {
    return getFormikError<SiteSchema>(name, errors, touched);
  };

  const maybeClearSiteId = (
    index: number,
    value: Option | null,
    currentMaxSiteIdLength: number
  ) => {
    const newMaxSiteIdLength = getSiteIDMaxLength(value, trials || null);
    if (newMaxSiteIdLength !== currentMaxSiteIdLength) {
      setFieldValue(`trials[${index}].siteId`, '');
    }
  };

  return (
    <>
      {values.trials.map((trialData, index) => {
        const isNewTrial = !trialData.id;
        const maxSiteIdLength = getSiteIDMaxLength(
          trialData.trial,
          trials || null
        );

        return (
          <Paper sx={{ p: 4, mb: 4 }} key={index}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  title="Trial"
                  placeholder="Select a trial"
                  options={trialsOptions}
                  value={trialData.trial}
                  onChange={(_event, value) => {
                    setFieldValue(`trials[${index}].trial`, value);
                    maybeClearSiteId(index, value, maxSiteIdLength);
                  }}
                  onBlur={handleBlur}
                  error={!!getError(`trials[${index}].trial`)}
                  helperText={getError(`trials[${index}].trial`)}
                  disabled={!isNewTrial}
                />
              </Grid>
              <Grid item xs={6}>
                <MaskedTextField
                  mask="number"
                  title="Site trial ID"
                  placeholder={'0'.repeat(maxSiteIdLength)}
                  maxLength={maxSiteIdLength}
                  value={trialData.siteId}
                  error={!!getError(`trials[${index}].siteId`)}
                  helperText={getError(`trials[${index}].siteId`)}
                  onChange={(event) =>
                    setFieldValue(`trials[${index}].siteId`, event.target.value)
                  }
                  onBlur={handleBlur}
                  disabled={!isNewTrial}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  multiple
                  selectAll
                  selectType="checkbox"
                  title="Supported languages"
                  placeholder="Select language"
                  name="languages"
                  error={!!getError(`trials[${index}].languages`)}
                  helperText={getError(`trials[${index}].languages`)}
                  onChange={(event) => {
                    setFieldValue(
                      `trials[${index}].languages`,
                      event.target.value
                    );
                  }}
                  options={languagesOptions}
                  value={trialData.languages}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  title="Status"
                  name="status"
                  disabled={isNewTrial}
                  options={STATUS_OPTIONS}
                  value={trialData.status}
                  onChange={(event) =>
                    setFieldValue(`trials[${index}].status`, event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  type="default"
                  name="onboardingCallNeeded"
                  title="Onboarding scheduling"
                  value={trialData.onboardingCallNeeded}
                  options={OnboardingCallOptions}
                  onChange={(value) =>
                    setFieldValue(
                      `trials[${index}].onboardingCallNeeded`,
                      value
                    )
                  }
                />
              </Grid>
              {isNewTrial && (
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button
                    variant="text"
                    onClick={() => onRemoveTrial(index)}
                    startIcon={
                      <Icon
                        name="minus-circle-outline"
                        size="medium"
                        fill={theme.palette.primary.main}
                      />
                    }
                  >
                    Remove trial
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        );
      })}
      <Button
        variant="text"
        onClick={onAddTrial}
        startIcon={
          <Icon
            name="plus-outline"
            size="medium"
            fill={theme.palette.primary.main}
          />
        }
      >
        Add new trial
      </Button>
    </>
  );
};
