export const cancerTypes = [
  {
    label: 'Adenoid Cystic Carcinoma',
    value: 'adenoidCysticCarcinoma',
  },
  {
    label: 'Adrenal Gland Cancer',
    value: 'adrenalGlandCancer',
  },
  {
    label: 'Amyloidosis',
    value: 'amyloidosis',
  },
  {
    label: 'Anal Cancer',
    value: 'analCancer',
  },
  {
    label: 'Ataxia-Telangiectasia',
    value: 'ataxiaTelangiectasia',
  },
  {
    label: 'Atypical Mole Syndrome',
    value: 'atypicalMoleSyndrome',
  },
  {
    label: 'Basal Cell Carcinoma',
    value: 'basalCellCarcinoma',
  },
  {
    label: 'Bile Duct Cancer',
    value: 'bileDuctCancer',
  },
  {
    label: 'Birt Hogg Dube Syndrome',
    value: 'birtHoggDubeSyndrome',
  },
  {
    label: 'Bladder Cancer',
    value: 'bladderCancer',
  },
  {
    label: 'Bone Cancer',
    value: 'boneCancer',
  },
  {
    label: 'Brain Tumor',
    value: 'brainTumor',
  },
  {
    label: 'Breast Cancer',
    value: 'breastCancer',
  },
  {
    label: 'Breast Cancer in Men',
    value: 'breastCancerinMen',
  },
  {
    label: 'Carcinoid Tumor',
    value: 'carcinoidTumor',
  },
  {
    label: 'Cervical Cancer',
    value: 'cervicalCancer',
  },
  {
    label: 'Colorectal Cancer',
    value: 'colorectalCancer',
  },
  {
    label: 'Ductal Carcinoma',
    value: 'ductalCarcinoma',
  },
  {
    label: 'Endometrial Cancer',
    value: 'endometrialCancer',
  },
  {
    label: 'Esophageal Cancer',
    value: 'esophagealCancer',
  },
  {
    label: 'Gastric Cancer',
    value: 'gastricCancer',
  },
  {
    label: 'Gastrontestinal Stromal Tumor - GIST',
    value: 'gastrontestinalStromalTumor GIST',
  },
  {
    label: 'HER2-Positive Breast Cancer',
    value: 'HER2PositiveBreastCancer',
  },
  {
    label: 'Islet Cell Tumor',
    value: 'isletCellTumor',
  },
  {
    label: 'Juvenile Polyposis Syndrome',
    value: 'juvenilePolyposisSyndrome',
  },
  {
    label: 'Kidney Cancer',
    value: 'kidneyCancer',
  },
  {
    label: 'Laryngeal Cancer',
    value: 'laryngealCancer',
  },
  {
    label: 'Leukemia - Acute Lymphoblastic Leukemia',
    value: 'leukemiaAcuteLymphoblastic Leukemia',
  },
  {
    label: 'Leukemia - Acute Lymphocytic (ALL)',
    value: 'leukemiaAcuteLymphocytic ALL',
  },
  {
    label: 'Leukemia - Acute Myeloid AML',
    value: 'leukemiaAcuteMyeloid AML',
  },
  {
    label: 'Leukemia - Adult',
    value: 'leukemiaAdult',
  },
  {
    label: 'Leukemia - Childhood',
    value: 'leukemiaChildhood',
  },
  {
    label: 'Leukemia - Chronic Lymphocytic - CLL',
    value: 'leukemiaChronicLymphocytic - CLL',
  },
  {
    label: 'Leukemia - Chronic Myeloid - CML',
    value: 'leukemiaChronicMyeloid - CML',
  },
  {
    label: 'Liver Cancer',
    value: 'liverCancer',
  },
  {
    label: 'Lobular Carcinoma',
    value: 'lobularCarcinoma',
  },
  {
    label: 'Lung Cancer',
    value: 'lungCancer',
  },
  {
    label: 'Lung Cancer - Small Cell',
    value: 'lungCancerSmall Cell',
  },
  {
    label: "Lymphoma - Hodgkin's",
    value: 'lymphomaHodgkins',
  },
  {
    label: "Lymphoma - Non-Hodgkin's",
    value: 'lymphomaNon-Hodgkins',
  },
  {
    label: 'Malignant Glioma',
    value: 'malignantGlioma',
  },
  {
    label: 'Melanoma',
    value: 'melanoma',
  },
  {
    label: 'Meningioma',
    value: 'meningioma',
  },
  {
    label: 'Multiple Myeloma',
    value: 'multipleMyeloma',
  },
  {
    label: 'Myelodysplastic Syndrome (MDS)',
    value: 'myelodysplasticSyndromeMDS',
  },
  {
    label: 'Nasopharyngeal Cancer',
    value: 'nasopharyngealCancer',
  },
  {
    label: 'Neuroendocrine Tumor',
    value: 'neuroendocrineTumor',
  },
  {
    label: 'Oral Cancer',
    value: 'oralCancer',
  },
  {
    label: 'Osteosarcoma',
    value: 'osteosarcoma',
  },
  {
    label: 'Ovarian Cancer',
    value: 'ovarianCancer',
  },
  {
    label: 'Pancreatic Cancer',
    value: 'pancreaticCancer',
  },
  {
    label: 'Pancreatic Neuroendocrine Tumors',
    value: 'pancreaticNeuroendocrineTumors',
  },
  {
    label: 'Parathyroid Cancer',
    value: 'parathyroidCancer',
  },
  {
    label: 'Penile Cancer',
    value: 'penileCancer',
  },
  {
    label: 'Peritoneal Cancer',
    value: 'peritonealCancer',
  },
  {
    label: 'Peutz-Jeghers Syndrome',
    value: 'peutzJeghersSyndrome',
  },
  {
    label: 'Pituitary Gland Tumor',
    value: 'pituitaryGlandTumor',
  },
  {
    label: 'Polycythemia Vera',
    value: 'polycythemiaVera',
  },
  {
    label: 'Prostate Cancer',
    value: 'prostateCancer',
  },
  {
    label: 'Renal Cell Carcinoma',
    value: 'renalCellCarcinoma',
  },
  {
    label: 'Retinoblastoma',
    value: 'retinoblastoma',
  },
  {
    label: 'Salivary Gland Cancer',
    value: 'salivaryGlandCancer',
  },
  {
    label: 'Sarcoma',
    value: 'sarcoma',
  },
  {
    label: 'Sarcoma - Kaposi',
    value: 'sarcomaKaposi',
  },
  {
    label: 'Skin Cancer',
    value: 'skinCancer',
  },
  {
    label: 'Small Intestine Cancer',
    value: 'smallIntestineCancer',
  },
  {
    label: 'Stomach Cancer',
    value: 'stomachCancer',
  },
  {
    label: 'Testicular Cancer',
    value: 'testicularCancer',
  },
  {
    label: 'Thymoma',
    value: 'thymoma',
  },
  {
    label: 'Thyroid Cancer',
    value: 'thyroidCancer',
  },
  {
    label: 'Uterine (Endometrial) Cancer',
    value: 'uterineEndometrialCancer',
  },
  {
    label: 'Vaginal Cancer',
    value: 'vaginalCancer',
  },
  {
    label: "Wilms' Tumor",
    value: 'wilmsTumor',
  },
];

export const cancerTypesWithId = cancerTypes.map((type) => {
  return { label: type.label, id: type.value };
});

export const getCancerType = (id?: string | null) => {
  return cancerTypesWithId.find((cancer) => cancer.id === id);
};

export const getCancersTypesWithI18n = () => {
  return cancerTypesWithId.map((cancer) => {
    return {
      ...cancer,
      i18nKey: `common:cancerTypes.${cancer.id}`,
    };
  });
};
