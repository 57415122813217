import { getToken } from '@fdha/common-utils';

const fetchWithAuthentication = (url: string, token: string) => {
  const headers = { Authorization: 'Bearer ' + token };
  return fetch(url, { headers });
};

const getBase64Image = async (response: Response) => {
  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

export const displayProtectedImage = async (
  imageUrl: string | null | undefined
) => {
  try {
    const token = await getToken();
    if (imageUrl && token) {
      const response = await fetchWithAuthentication(imageUrl, token);
      const base64Image = await getBase64Image(response);
      return base64Image;
    }
  } catch (error) {
    console.error(error);
  }
};
