const MAX_SIZE = 1040;
const MAX_COLUMNS = 12;

export const HORIZONTAL_PADDING = 32;

export type ContentSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export const contentSizes: Record<ContentSize, number> = {
  xsmall: MAX_SIZE * (4 / MAX_COLUMNS) + 2 * HORIZONTAL_PADDING, // 346,66 px
  small: MAX_SIZE * (6 / MAX_COLUMNS) + 2 * HORIZONTAL_PADDING, // 520 px
  medium: MAX_SIZE * (8 / MAX_COLUMNS) + 2 * HORIZONTAL_PADDING, //693,33 px
  large: MAX_SIZE * (10 / MAX_COLUMNS) + 2 * HORIZONTAL_PADDING, // 866,66 px
  xlarge: MAX_SIZE + 2 * HORIZONTAL_PADDING, // 1040 px
};
