import React, { useState, useContext, useCallback } from 'react';

import LoadingBar from '../components/LoadingBar/LoadingBar';

type VisibleLoadingProps = {
  visible: boolean;
  v2?: boolean;
};

const initialState: VisibleLoadingProps = {
  visible: false,
};

interface ContextProps {
  loadingContent: VisibleLoadingProps;
  showLoading: () => void;
  showLoadingV2: () => void;
  hideLoading: () => void;
}

const Context = React.createContext<ContextProps>({
  loadingContent: initialState,
  showLoading: () => {},
  showLoadingV2: () => {},
  hideLoading: () => {},
});

export const LoadingBarProvider = ({ ...props }) => {
  const [loadingContent, setLoadingContent] =
    useState<VisibleLoadingProps>(initialState);

  const handleShowLoading = useCallback(() => {
    setLoadingContent({
      ...initialState,
      visible: true,
    });
  }, []);

  const handleShowLoadingV2 = useCallback(() => {
    setLoadingContent({
      ...initialState,
      visible: true,
      v2: true,
    });
  }, []);

  const handleHideLoading = useCallback(() => {
    setLoadingContent(initialState);
  }, []);

  const LoadingBarContextProvider = Context.Provider;
  return (
    <LoadingBarContextProvider
      value={{
        loadingContent,
        showLoading: handleShowLoading,
        showLoadingV2: handleShowLoadingV2,
        hideLoading: handleHideLoading,
      }}
    >
      {props.children}
      <LoadingBar />
    </LoadingBarContextProvider>
  );
};

export const useLoadingBar = () => {
  const { loadingContent, showLoading, hideLoading, showLoadingV2 } =
    useContext(Context);
  return {
    loadingContent,
    showLoading,
    showLoadingV2,
    hideLoading,
  };
};
