import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import fdhaConfig from '@fdha/aws-config-common';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import TextField from '../TextField/TextField';
import { AddressValidationStatus, validateAddress } from '../../utils';

import { AddressMenu } from './AddressMenu';
import { AddressWarning } from './AddressWarning';

export interface AddressInputFields {
  formatted: string;
  country: string;
  region: string;
  streetAddress: string;
  postalCode: string;
  locality: string;
}

export interface AddressFieldsProps {
  v2?: boolean;
  showSkeleton?: boolean;
  address: AddressInputFields;
  complement: string;
  addressError?: string;
  complementError?: string;
  isRequired?: boolean;
  addressValidationStatus?: AddressValidationStatus;
  onChangeAddress: (value: AddressInputFields) => void;
  onChangeComplement: (value: string) => void;
  onBlurAddress?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => void;
  onBlurComplement?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => void;
  onValidate?: (value: AddressValidationStatus | undefined) => void;
}

export const AddressFields: FC<AddressFieldsProps> = ({
  v2,
  showSkeleton = false,
  isRequired,
  address,
  complement,
  addressError,
  complementError,
  addressValidationStatus,
  onChangeAddress,
  onChangeComplement,
  onBlurAddress,
  onBlurComplement,
  onValidate,
}) => {
  const [addressStatus, setAddressStatus] = useState<
    AddressValidationStatus | undefined
  >(addressValidationStatus);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleBlurAddress = async (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => {
    setMenuOpen(false);
    await handleAddressValidation(address.formatted);
    onBlurAddress && onBlurAddress(event);
  };

  const handleBlurComplement = async (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => {
    address && (await handleAddressValidation(address.formatted));
    onBlurComplement && onBlurComplement(event);
  };

  const handleAddressValidation = async (value: string) => {
    const result = await validateAddress(value);
    if (result?.status === AddressValidationStatus.Invalid) {
      setAddressStatus(AddressValidationStatus.Invalid);
      onValidate && onValidate(result.status);
    } else if (
      result?.status === AddressValidationStatus.MissingComplement &&
      !complement
    ) {
      setAddressStatus(AddressValidationStatus.MissingComplement);
      onValidate && onValidate(result.status);
    } else {
      setAddressStatus(AddressValidationStatus.Ok);
      onValidate && onValidate(AddressValidationStatus.Ok);
    }
    result?.fields && onChangeAddress(result?.fields);
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: fdhaConfig.secretsManager.mapsApiKey,
      options: {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        input: address.formatted,
      },
      language: 'en',
    });

  const showMenu =
    menuOpen && !!placePredictions.length && !isPlacePredictionsLoading;

  return (
    <Grid container spacing={2}>
      <Grid item lg={8} xs={12}>
        <TextField
          v2={v2}
          title="Address"
          name="address"
          value={address.formatted}
          onChange={(evt) => {
            getPlacePredictions({ input: evt.target.value });
            onChangeAddress({
              ...address,
              formatted: evt.target.value,
            });
            setMenuOpen(true);
          }}
          onBlur={handleBlurAddress}
          placeholder="0000 Main Street, City, NY 00000, USA"
          error={!!addressError}
          helperText={addressError}
          showSkeleton={showSkeleton}
          autoComplete="off"
          required={isRequired}
        />
        {showMenu && (
          <AddressMenu
            placePredictions={placePredictions}
            onSelectAddress={(formatted) => {
              onChangeAddress({
                ...address,
                formatted,
              });
              setMenuOpen(false);
              handleAddressValidation(formatted);
            }}
          />
        )}
      </Grid>
      <Grid item lg={4} xs={12}>
        <TextField
          v2={v2}
          title="Apt, Suite, etc"
          required={false}
          name="complement"
          value={complement}
          onChange={(event) => onChangeComplement(event.currentTarget.value)}
          onBlur={handleBlurComplement}
          placeholder="Apt. 000"
          error={!!complementError}
          helperText={complementError}
          showSkeleton={showSkeleton}
        />
      </Grid>
      <AddressWarning status={addressStatus} />
    </Grid>
  );
};
