import React from 'react';
import {
  Dialog,
  LinearProgress,
  linearProgressClasses,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';

import { useLoadingBar } from '../../hooks/useLoadingBar';
import { Typography } from '../Typography/Typography';

const LoadingBar = () => {
  const theme = useTheme();

  const { loadingContent } = useLoadingBar();
  const isV2 = !!loadingContent.v2;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 29,
    borderRadius: 500,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.background.paper,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 500,
      backgroundColor: isV2
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    },
  }));

  return (
    <Dialog
      open={loadingContent.visible}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none',
        },
      }}
      fullWidth
    >
      <BorderLinearProgress />
      <Typography
        i18nKey="common:loading"
        i18nParams={{ suffix: '...' }}
        variant="body1"
        color={theme.palette.common.white}
        style={{ alignSelf: 'center' }}
      >
        Loading...
      </Typography>
    </Dialog>
  );
};

export default LoadingBar;
