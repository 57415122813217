import React, { useEffect, useRef, useState } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  Link,
  Outlet,
} from 'react-router-dom';
import {
  Box,
  Typography,
  useTheme,
  Switch,
  Stack,
  Card,
  Tab,
} from '@mui/material';
import { resetPassword } from '@aws-amplify/auth';
import {
  useSnackbar,
  useDialog,
  convertMaskToE164,
  Loader,
} from '@fdha/web-ui-library';
import Icon from 'react-eva-icons';
import {
  useGetCoachUserQuery,
  GetCoachUserDocument,
  useDeactivateAccountMutation,
  useGetProfileQuery,
  useUpdateCoachUserMutation,
  GetProfileDocument,
  useGetCommunityUserQuery,
  UserType,
} from '@fdha/graphql-api-admin';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { useAddRemoveGroups, useGetUserType } from '../../hooks';
import {
  BasePage,
  CoachAvailability,
  CoachCard,
  CommunityProfileCard,
  DescriptionCard,
  ManageAccountButton,
  SelectMultipleGroups,
  CalendarConfigCard,
} from '../../components/';
import { CalendarConfigSchema } from '../../components/CalendarConfigCard/CalendarConfigCard';
import { getCalendarConfigPayload } from '../../utils';

interface StateProps {
  backRoute: string;
}

const Coach = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { isAdmin } = useGetUserType();
  const location = useLocation();
  const params = useParams();

  const state = location.state as StateProps;
  const coachId = params.coachId || '';

  const tabs = [
    {
      label: 'Site Trials',
      value: 'siteTrials',
      href: `coach/${coachId}`,
      to: '',
    },
  ];

  const tab = tabs.find((tab) => location.pathname.includes(tab.href))?.value;

  const selectedTab = tab || 'siteTrials';

  const showCoachPage = !(
    location.pathname.includes('assign-site-trial') ||
    location.pathname.includes('edit-site-trial')
  );

  const backRouteRef = useRef(state?.backRoute ?? '/settings/coaches');

  const { data, loading } = useGetCoachUserQuery({
    variables: { id: coachId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: meData } = useGetProfileQuery();

  const [deactivateAccount] = useDeactivateAccountMutation({
    variables: { id: coachId },
    refetchQueries: [GetCoachUserDocument],
  });
  const { addRemoveGroups, removeGroups } = useAddRemoveGroups();
  const { openDialog, closeDialog } = useDialog();

  const [updateCoachUser] = useUpdateCoachUserMutation();

  const { data: communityData } = useGetCommunityUserQuery({
    variables: { id: coachId },
  });

  const communityUser = communityData?.getCommunityUser;

  const isCurrentCoach = coachId === meData?.me.id;
  const isCsr = data?.coachUser?.type === UserType.CustomerService;
  const isUserAdmin = meData?.me.is_admin;

  const dataCalendar = data?.coachUser?.calendarConfiguration;

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const [coachInfo, setCoachInfo] = useState({
    new_coachings_available: data?.coachUser?.new_coachings_available,
    is_admin: data?.coachUser?.is_admin,
    coachData: {
      name: data?.coachUser?.name,
      email: data?.coachUser?.email,
      created_at: data?.coachUser?.created_at,
      phone_number: data?.coachUser?.phone_number,
      picture: data?.coachUser?.picture,
    },
  });

  useEffect(() => {
    setSelectedGroups(data?.coachUser?.groups || []);

    setCoachInfo({
      new_coachings_available: data?.coachUser?.new_coachings_available,
      is_admin: data?.coachUser?.is_admin,
      coachData: {
        name: data?.coachUser?.name,
        email: data?.coachUser?.email,
        created_at: data?.coachUser?.created_at,
        phone_number: data?.coachUser?.phone_number,
        picture: data?.coachUser?.picture,
      },
    });
  }, [data]);

  const handleResetPassword = () => {
    try {
      resetPassword({ username: data?.coachUser?.email || '' });
      snackbar.showSnackbar({
        message: 'Reset password link sent to this user',
        severity: 'info',
      });
    } catch (e: any) {
      snackbar.showSnackbar({
        message: e.message,
        severity: 'warning',
      });
    }
  };

  const handleDeactivateAccClick = () => {
    openDialog({
      title: 'Are you sure you want to deactivate this account?',
      content: `${data?.coachUser?.name}’s account will no longer exist. This action can’t be undone.`,
      confirmButtonLabel: 'Deactivate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          await deactivateAccount();

          snackbar.showSnackbar({
            message: 'User deactivated with success',
            severity: 'success',
          });

          navigate(backRouteRef.current, { replace: true });
        } catch (e) {
          snackbar.showSnackbar({
            message: 'Failed to deactivate account',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const handleEditProfile = async (profile: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => {
    const formattedPhoneNumber = convertMaskToE164(profile.phone_number);
    setCoachInfo({
      ...coachInfo,
      coachData: {
        ...coachInfo.coachData,
        name: profile.name,
        email: profile.email,
        phone_number: formattedPhoneNumber,
      },
    });
    await updateCoachUser({
      variables: {
        id: coachId,
        userProps: {
          email: profile.email,
          phone_number: formattedPhoneNumber,
          name: profile.name,
          description: data?.coachUser?.description,
        },
      },
      refetchQueries: [GetCoachUserDocument],
    });
  };

  const canDeactivateAccount = () => {
    return isUserAdmin && !data?.coachUser?.is_admin;
  };

  const changeCoachUser = async (
    prop: string,
    newValue: boolean,
    errorMessage: string
  ) => {
    const refetchQueries = isCurrentCoach
      ? [GetCoachUserDocument, GetProfileDocument]
      : [GetCoachUserDocument];
    try {
      setCoachInfo({
        ...coachInfo,
        [prop]: newValue,
      });
      await updateCoachUser({
        variables: {
          id: coachId,
          userProps: {
            [prop]: newValue,
          },
        },
        refetchQueries: refetchQueries,
      });
    } catch (e) {
      setCoachInfo({
        ...coachInfo,
        [prop]: !newValue,
      });
      snackbar.showSnackbar({
        message: `Failed to update user ${errorMessage}`,
        severity: 'error',
      });
    }
  };

  const onSubmitCalendarConfig = async (values: CalendarConfigSchema) => {
    try {
      await updateCoachUser({
        variables: {
          id: coachId,
          userProps: {
            calendarConfiguration: getCalendarConfigPayload(values),
          },
        },
      });
      snackbar.showSnackbar({
        message: 'Changes Saved!',
        severity: 'success',
      });
    } catch (e) {
      snackbar.showSnackbar({
        message: 'Sorry, something went wrong. Please try again.',
        severity: 'error',
      });
    }
  };

  return (
    <BasePage data-testid="COACH_CONTAINER">
      {loading ? (
        <>
          <BasePage.BackButton to={backRouteRef.current} />
          <Loader />
        </>
      ) : (
        <>
          {showCoachPage ? (
            <>
              <BasePage.BackButton to={backRouteRef.current} />
              {data?.coachUser ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="stretch"
                  flex="1 0 auto"
                  flexWrap="nowrap"
                >
                  <Stack flex={1} spacing={2}>
                    <CoachCard
                      user={coachInfo.coachData}
                      handleUpdateData={handleEditProfile}
                      editable={isUserAdmin}
                    />
                    {isAdmin && (
                      <>
                        {!isCurrentCoach && !isCsr && (
                          <Card
                            data-testid="IS_ADMIN_TOGGLE"
                            sx={{
                              paddingY: 1,
                              paddingX: 3,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="body1"
                              color={theme.palette.secondary.contrastText}
                            >
                              Is Admin?
                            </Typography>
                            <Switch
                              checked={coachInfo.is_admin}
                              color="secondary"
                              onChange={(_, checked) =>
                                changeCoachUser('is_admin', checked, 'type')
                              }
                              data-testid={
                                coachInfo.is_admin
                                  ? 'CHECKED_IS_ADMIN_TOGGLE'
                                  : 'NOT_CHECKED_IS_ADMIN_TOGGLE'
                              }
                            />
                          </Card>
                        )}
                        {!isCsr && (
                          <CoachAvailability
                            isAvailable={coachInfo.new_coachings_available}
                            handleChange={changeCoachUser}
                          />
                        )}
                        <Box sx={{ mb: 3 }}>
                          <SelectMultipleGroups
                            initialSelectedGroups={data.coachUser.groups}
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            onCloseSelector={(
                              groupIdsToAdd,
                              groupIdsToRemove
                            ) =>
                              addRemoveGroups(
                                coachId,
                                groupIdsToAdd,
                                groupIdsToRemove,
                                [GetCoachUserDocument]
                              )
                            }
                            onRemoveChips={(groupIdsToRemove) =>
                              removeGroups(coachId, groupIdsToRemove, [
                                GetCoachUserDocument,
                              ])
                            }
                            showPlaceholderOnly
                            showChips
                            fullWidth
                          />
                        </Box>
                      </>
                    )}
                    <CalendarConfigCard
                      data={dataCalendar}
                      onSubmit={(values) => onSubmitCalendarConfig(values)}
                    />
                    <DescriptionCard user={data?.coachUser} editable={false} />
                    {communityUser && !isCurrentCoach && (
                      <CommunityProfileCard profile={communityUser} />
                    )}
                    <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
                      Manage account
                    </Typography>
                    <ManageAccountButton
                      data-testid="COACH_RESET_PASSWORD"
                      onClick={handleResetPassword}
                    >
                      <Typography
                        variant="h6"
                        color={theme.palette.secondary.contrastText}
                      >
                        Reset password
                      </Typography>
                    </ManageAccountButton>
                    {canDeactivateAccount() && (
                      <ManageAccountButton
                        onClick={handleDeactivateAccClick}
                        data-testid="DEACTIVATE_ACCOUNT_BUTTON"
                        color="error"
                        sx={{ mt: 2 }}
                      >
                        <Box display="flex" alignItems="center">
                          <Icon
                            name="alert-circle-outline"
                            fill={theme.palette.error.textDark}
                            size="large"
                          />
                          <Typography variant="h6" sx={{ ml: 1 }}>
                            Deactivate account
                          </Typography>
                        </Box>
                      </ManageAccountButton>
                    )}
                  </Stack>
                  <TabContext value={selectedTab}>
                    <Box flex={2} ml={2}>
                      <Box borderBottom={1} borderColor="divider" marginX={3.5}>
                        <TabList orientation="horizontal" value={selectedTab}>
                          {tabs.map((tab, index) => (
                            <Tab
                              key={index}
                              label={tab.label}
                              value={tab.value}
                              component={Link}
                              to={tab.to}
                            />
                          ))}
                        </TabList>
                      </Box>
                      <TabPanel value={selectedTab}>
                        <Outlet />
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              ) : (
                <Typography>{`No coach found with ID ${coachId}`}</Typography>
              )}
            </>
          ) : (
            <Outlet />
          )}
        </>
      )}
    </BasePage>
  );
};

export default Coach;
