import { useState, useEffect } from 'react';
import { getCurrentUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { HubCallback } from '@aws-amplify/core';
import { useDialog, useSnackbar } from '@fdha/web-ui-library';
import { useNavigate } from 'react-router-dom';

export enum AuthStatus {
  LOADING = 'LOADING',
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
}

export function useAuthStatus() {
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.LOADING);
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    // Loads initial auth state for the user
    getCurrentUser()
      .then(() => setAuthStatus(AuthStatus.SIGNED_IN))
      .catch(() => setAuthStatus(AuthStatus.SIGNED_OUT));

    const authListener: HubCallback = (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          setAuthStatus(AuthStatus.SIGNED_IN);
          break;
        case 'signedOut':
          setAuthStatus(AuthStatus.SIGNED_OUT);
          break;
        case 'tokenRefresh_failure':
          navigate('/');
          closeDialog();
          showSnackbar({
            severity: 'warning',
            message: 'Session expired. Please log in again.',
            closeOnClickOutside: true,
            closeAutomatically: false,
          });
          setAuthStatus(AuthStatus.SIGNED_OUT);
          break;
      }
    };

    // Listens for updates to auth state
    const removeListener = Hub.listen('auth', authListener);
    return () => removeListener();
  }, [showSnackbar, navigate, closeDialog]);

  return authStatus;
}
