import React from 'react';

import { KeyOfNestedLeaves } from '../../types/keyOfNestedLeaves';

import {
  TableField,
  StatelessTable,
  StatelessTableProps,
  TableSortOrder,
} from './StatelessTable';

export type TableProps<D extends object> = Omit<
  StatelessTableProps<D>,
  'sortField' | 'sortOrder' | 'onSortChange'
> & {
  initialOrderBy?: TableField<D>;
  onSortChange?: (sortField: TableField<D>, sortOrder: TableSortOrder) => void;
};

export function Table<D extends object>({
  headCells,
  initialOrderBy = headCells[0]?.id,
  ...tableProps
}: TableProps<D>) {
  const [order, setOrder] = React.useState<TableSortOrder>(TableSortOrder.ASC);
  const [orderBy, setOrderBy] =
    React.useState<KeyOfNestedLeaves<D>>(initialOrderBy);

  const handleSortChange = (
    sortField: TableField<D>,
    sortOrder: TableSortOrder
  ) => {
    setOrderBy(sortField);
    setOrder(sortOrder);
  };

  return (
    <StatelessTable<D>
      headCells={headCells}
      sortField={orderBy}
      sortOrder={order}
      onSortChange={handleSortChange}
      {...tableProps}
    />
  );
}
