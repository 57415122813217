import {
  AutomationItemStatus,
  AutomationItemType,
  AutomationRequirementType,
  DateQuestionInitialState,
  SubjectStatus,
  WeekDay,
} from '@fdha/graphql-api-admin';

import { SubjectStatusOptions } from './sites';

export const automationItemLabelByValue: Record<AutomationItemType, string> = {
  [AutomationItemType.Sachets]: 'Sachet request',
  [AutomationItemType.FrozenFood]: 'Quick Ship request',
  [AutomationItemType.WelcomeBinder]: 'Welcome Binder request',
  [AutomationItemType.WelcomeKit]: 'Welcome Kit request',
};

export const automationItemStatusLabelByValue: Record<
  AutomationItemStatus,
  string
> = {
  [AutomationItemStatus.Checking]: 'Checking...',
  [AutomationItemStatus.Incomplete]: 'Incomplete ',
  [AutomationItemStatus.Invalid]: 'Invalid',
  [AutomationItemStatus.Scheduled]: 'Scheduled',
  [AutomationItemStatus.Triggered]: 'Triggered',
  [AutomationItemStatus.Error]: 'Error',
};

export const automationRequirementLabelByValue: Partial<
  Record<AutomationRequirementType, string>
> = {
  [AutomationRequirementType.DaysOnDiet]: 'Days on diet',
  [AutomationRequirementType.DietPlan]: 'Diet plan',
  [AutomationRequirementType.TrialStatus]: 'Trial status',
  [AutomationRequirementType.MedicalFoodStartDate]: 'Medical food start date',
  [AutomationRequirementType.Sachets]: 'Sachets',
  [AutomationRequirementType.SnacksAndSides]: 'Snacks and sides per day',
};

export const dateQuestionInitialStateLabelByValue: Record<
  DateQuestionInitialState,
  string
> = {
  [DateQuestionInitialState.PreloadedWithCurrentDate]: 'User current date',
  [DateQuestionInitialState.NoPreloadedDate]: 'No preloaded date',
};

export const weekdayLabelByValue: Record<WeekDay, string> = {
  [WeekDay.Mon]: 'Monday',
  [WeekDay.Tue]: 'Tuesday',
  [WeekDay.Wed]: 'Wednesday',
  [WeekDay.Thu]: 'Thursday',
  [WeekDay.Fri]: 'Friday',
  [WeekDay.Sat]: 'Saturday',
  [WeekDay.Sun]: 'Sunday',
};

export const subjectStatusLabelByValue: Record<SubjectStatusOptions, string> = {
  [SubjectStatus.Discontinued]: 'Discontinued',
  [SubjectStatus.Ongoing]: 'Ongoing',
  [SubjectStatus.Screening]: 'Screening',
  [SubjectStatus.StudyCompleted]: 'Study completed',
  [SubjectStatus.ScreenFailed]: 'Screen failed',
  [SubjectStatus.CoachApproved]: 'Coach approved',
};
