import { useTheme } from '@mui/material';
import React, { FC } from 'react';

import { Typography } from '../Typography/Typography';

interface CustomTextProps {
  text: string;
  ['data-testid']?: string;
}

export const CustomText: FC<CustomTextProps> = (props) => {
  const theme = useTheme();

  return (
    <Typography
      data-testid={props['data-testid']}
      variant="caption"
      color={theme.palette.text.secondary}
      gridColumn={2}
      gridRow={3}
      paddingX={3}
      paddingBottom={3}
    >
      {props.text}
    </Typography>
  );
};
